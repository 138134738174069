import React from 'react';
import './../../../VendorStyles/VendorRegistrationBG.css';
// import { NavLink } from 'react-router-dom';

function KYCVerificationRejected() {
    return (
        <div className='kyc-update-message-main-container'>

            <div className='success-svg-and-msg'>
                <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg" className='kyc-rejected-logo-kyc'>
                    <circle cx="44" cy="44" r="44" fill="#E13535" fillOpacity="0.09"/>
                    <path d="M43.9987 7.33398C23.7954 7.33398 7.33203 23.7973 7.33203 44.0006C7.33203 64.204 23.7954 80.6673 43.9987 80.6673C64.202 80.6673 80.6654 64.204 80.6654 44.0006C80.6654 23.7973 64.202 7.33398 43.9987 7.33398Z" fill="#E13535"/>
                    <path d="M46.6861 44.0009L58.1079 32.5785C58.8512 31.8355 58.8512 30.6342 58.1079 29.8912C57.3649 29.1482 56.1636 29.1482 55.4207 29.8912L43.9985 41.3136L32.5767 29.8912C31.8334 29.1482 30.6325 29.1482 29.8895 29.8912C29.1462 30.6342 29.1462 31.8355 29.8895 32.5785L41.3113 44.0009L29.8895 55.4233C29.1462 56.1662 29.1462 57.3675 29.8895 58.1105C30.2598 58.4811 30.7466 58.6673 31.2331 58.6673C31.7196 58.6673 32.2061 58.4811 32.5767 58.1105L43.9985 46.6881L55.4207 58.1105C55.7913 58.4811 56.2778 58.6673 56.7643 58.6673C57.2508 58.6673 57.7373 58.4811 58.1079 58.1105C58.8512 57.3675 58.8512 56.1662 58.1079 55.4233L46.6861 44.0009Z" fill="white"/>
                </svg>




                <div className='kyc-success-msg'>

                    <h3 className='kyc-submit-msg'>Your KYC has been rejected</h3>
                    {/* <h3 className='kyc-submit-msg-2'>submitted,Thank You! </h3> */}
                </div>


                <h4 className='kyc-verify-msg'> please contact our support team at{' '}
                    <a href="mailto:support@example.com" style={{ color: '#1976d2', textDecoration: 'none' }}>
                    support@example.com
                    </a>{' '}
                or reach out to the admin for further assistance.</h4>


                {/* <NavLink to='/Home'><button className='view-reels-btn-kyc' >Go to main page</button></NavLink> */}
                
            </div>

            

        </div>
    );
}

export default KYCVerificationRejected;
