

import '../../VendorStyles/Delivered.css';
import React, { useState,useEffect } from 'react';
import axios from 'axios';
function Delivered(){

    const vendor_Base_URL = process.env.REACT_APP_VENDOR_API_BASE_URL;
    
    const [orders, setOrders] = useState([]);
    console.log(orders);

    useEffect(() => {

        // deliveredData();
        // Fetch data from the API
        axios.get(`${vendor_Base_URL}/order/vendor/1?pageSize=10&pageNumber=1`)
            .then(response => {
                const formattedOrders = response.data.allOrdersDetails
                    .filter(order => order.Status_id === 6) 
                    .map(order => {
                        const createdAt = new Date(order.Created_at);
                        const time = createdAt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
                        const date = `${createdAt.getDate()} ${createdAt.toLocaleString('en-US', { month: 'short' })}`;
                        const status = order.Status_id === 4 ? 'Ready to Pickup' : 'Delivered';
                        return {
                            id: order.ID,
                            time,
                            date,
                            status
                        };
                    });
                setOrders(formattedOrders);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);
     
    return(<div className="delivered-main"> 
        {orders.map(order => (
            <div key={order.id} className="delivered-single-order">
                <div className='delivered-orderid'><p className='delivered-order-details1'>Order ID:<span className='delivered-order-id'>{order.id}</span></p></div>
                <div className='delivered-date-and-time'><p className='delivered-order-details2'>{order.time}</p>
                    <p className='delivered-order-details3'>{order.date}</p></div>
                    
                <button className='status-btn'>{order.status}</button>            </div>
        ))}
    </div>
    );
}

export default Delivered;
 