import React, { useState, useRef, useEffect } from 'react';
import '../../../VendorStyles/VendorTopMenuBar.css';


 
import profileIcon from './../../../assets/profile image.png';


import { Link } from 'react-router-dom';
import Notification from '../../User/Notification/Notification';
import gal from '../../../assets/gallery.png';

function VendorTopMenuBar() {
    
    const overlayRef = useRef(null);
    const [inputValue, setInputValue] = useState('');

    const [NotificationOverlay, setNotificationOverlay] = useState(false);

    

    const [profileImage, setProfileImage] = useState(null); // Stores the file for uploading
    console.log(profileImage);
    const [profileImagePreview, setProfileImagePreview] = useState(); // Preview image

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (overlayRef.current && !overlayRef.current.contains(event.target)) {
                setInputValue('');
                setNotificationOverlay(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const closenotificationoverlay = () => {
        setNotificationOverlay(false);
    };


    


    const handleGalleryClick = (e) => {
        const file = e.target.files[0]; // Get the selected file
        if (file) {
            // Set the file object for uploading later
            setProfileImage(file);
    
            // For preview purposes, convert the image to base64
            const reader = new FileReader();
            reader.onload = () => {
                // Update the UI with the image preview
                setProfileImagePreview(reader.result); // New state for image preview (optional)
            };
            reader.readAsDataURL(file);
        }
    };


   
    return (
        <>
            <div className="vendor-topbar-container" >
                <div className='vendor-restaurant-logo-container' >
                    <img src={ profileImagePreview || 'https://via.placeholder.com/150'} className='vendor-restaurant-logo'/>
                    <div className='gallary-container-vendor'>
                        <input type="file" id="fileInput" accept="image/*" style={{ display: 'none' }}  onChange={handleGalleryClick} />
                        <label htmlFor="fileInput">
                            <img src={gal} alt='gallery-icon' className='gallary-img-vendor' />
                        </label>
                    </div>
                </div>
                <div className="vendor-turn-on-location-container">
                    <h6 className="vendor-turn-on-location">
                        Restaurant Name
                    </h6>
                    <p className="vendor-turn-on-location-description">
                        {/* Passionate food enthusiast capturing culinary adventures one bite at a time. */}
                    </p>
                    
                </div>

                {/* search container  */}

                <div className="vendor-search-container">
                    <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="vendor-search-icon"
                        
                    >
                        <path
                            d="M21 21L16.2779 16.2695M18.8947 9.94737C18.8947 12.3204 17.9521 14.5962 16.2741 16.2741C14.5962 17.9521 12.3204 18.8947 9.94737 18.8947C7.57438 18.8947 5.29858 17.9521 3.62062 16.2741C1.94267 14.5962 1 12.3204 1 9.94737C1 7.57438 1.94267 5.29858 3.62062 3.62062C5.29858 1.94267 7.57438 1 9.94737 1C12.3204 1 14.5962 1.94267 16.2741 3.62062C17.9521 5.29858 18.8947 7.57438 18.8947 9.94737Z"
                            stroke="#464B59"
                            strokeWidth="2"
                            strokeLinecap="round"
                        />
                    </svg>

                    <input
                        type="text"
                        className="vendor-input-field"
                        value={inputValue}
                        placeholder='Search orders'
                        // onClick={toggleOverlay}
                    />

                    

                    <span className="vendor-verticle-line">|</span>

                    <svg
                        width="19"
                        height="30"
                        viewBox="0 0 19 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="vendor-mic-icon"
                    >
                        <path
                            d="M9.5 20.0938C6.29307 20.0903 3.69466 17.391 3.69071 14.0602V6.01492C3.70087 2.68237 6.31 -0.0105225 9.51863 3.09136e-05C12.7126 0.0105844 15.2997 2.69703 15.3099 6.01492V14.0608C15.3065 17.3915 12.7075 20.0909 9.50056 20.0944L9.5 20.0938ZM13.5029 6.01492C13.4944 3.71895 11.6959 1.86448 9.48476 1.87327C7.28602 1.88206 5.50502 3.73127 5.49712 6.01492V14.0608C5.50559 16.3567 7.30409 18.2112 9.51524 18.2024C11.714 18.1936 13.495 16.3444 13.5029 14.0608V6.01492ZM8.5968 23.757V28.1238H4.80278C4.30376 28.1238 3.89958 28.5436 3.89958 29.0619C3.89958 29.5802 4.30376 30 4.80278 30H14.1972C14.6962 30 15.1004 29.5802 15.1004 29.0619C15.1004 28.5436 14.6962 28.1238 14.1972 28.1238H10.4032V23.757C15.2709 23.2593 18.9859 19.0156 19 13.9353C19 13.417 18.5958 12.9972 18.0968 12.9972C17.5978 12.9972 17.1936 13.417 17.1936 13.9353C17.1936 18.3414 13.7422 21.926 9.5 21.926C5.25777 21.926 1.80641 18.3414 1.80641 13.9353C1.80641 13.417 1.40222 12.9972 0.903204 12.9972C0.404184 12.9972 0 13.417 0 13.9353C0.0141125 19.0156 3.7291 23.2593 8.5968 23.757Z"
                            fill="#464B59"
                        />
                    </svg>
                </div>

                <div className='vendor-profile-and-notification'>
                    <img
                        className="vendor-profile-icon"
                        src={profileIcon}
                        alt="profile"
                        
                        

                    />
                    <div className='vendor-topbar-top-icons'>
                        <Link to='/profilesettings'><svg width="48" height="41" viewBox="0 0 48 41" fill="none" xmlns="http://www.w3.org/2000/svg" className='vendor-topbar-menu-icon' >
                            <path d="M38.6667 29.0416H9.33333C8.59695 29.0416 8 29.5515 8 30.1805C8 30.8095 8.59695 31.3194 9.33333 31.3194H38.6667C39.403 31.3194 40 30.8095 40 30.1805C40 29.5515 39.403 29.0416 38.6667 29.0416Z" fill="white"/>
                            <path d="M38.6667 19.3607H9.33333C8.59695 19.3607 8 19.8706 8 20.4996C8 21.1286 8.59695 21.6385 9.33333 21.6385H38.6667C39.403 21.6385 40 21.1286 40 20.4996C40 19.8706 39.403 19.3607 38.6667 19.3607Z" fill="white"/>
                            <path d="M38.6667 9.68091H9.33333C8.59695 9.68091 8 10.1908 8 10.8198C8 11.4488 8.59695 11.9587 9.33333 11.9587H38.6667C39.403 11.9587 40 11.4488 40 10.8198C40 10.1908 39.403 9.68091 38.6667 9.68091Z" fill="white"/>
                        </svg>
                        </Link>


                        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" className="vendor-notification-icon"
                            onClick={() => setNotificationOverlay(!NotificationOverlay)}>
                            <path d="M15.8316 37.9986C12.776 37.9986 10.2897 35.513 10.2897 32.4567C10.2897 32.0196 10.6445 31.6648 11.0816 31.6648C11.5188 31.6648 11.8735 32.0196 11.8735 32.4567C11.8735 34.6387 13.6503 36.4148 15.8316 36.4148C18.0129 36.4148 19.7897 34.638 19.7897 32.4567C19.7897 32.0196 20.1444 31.6648 20.5816 31.6648C21.0187 31.6648 21.3735 32.0196 21.3735 32.4567C21.3735 35.5123 18.8879 37.9986 15.8316 37.9986Z" fill="white"/>
                            <path d="M29.2917 33.2519H2.37498C1.06577 33.2519 0 32.1861 0 30.8769C0 30.1815 0.302068 29.5246 0.831244 29.0719C0.863158 29.0452 0.89804 29.0199 0.932923 28.9992C3.35986 26.8921 4.74996 23.8551 4.74996 20.6489V15.8358C4.74996 10.6235 8.45048 6.06203 13.55 4.9918C13.9708 4.90497 14.3968 5.17512 14.4874 5.60484C14.5779 6.03234 14.3041 6.45167 13.8744 6.54222C9.50438 7.45881 6.33304 11.3679 6.33304 15.8366V20.6496C6.33304 24.3576 4.7084 27.8681 1.87475 30.2779C1.851 30.2987 1.82725 30.3158 1.80202 30.3336C1.661 30.4806 1.58382 30.6758 1.58382 30.8784C1.58382 31.3155 1.93858 31.6703 2.37572 31.6703H29.2924C29.7296 31.6703 30.0843 31.3155 30.0843 30.8784C30.0843 30.6743 30.0049 30.4791 29.8661 30.3321C29.8424 30.3165 29.8186 30.2987 29.7964 30.2802C28.3209 29.037 27.1668 27.4807 26.3668 25.6534C26.1909 25.2526 26.3734 24.7858 26.7735 24.6099C27.1772 24.437 27.6411 24.6181 27.817 25.0166C28.5057 26.5856 29.4876 27.9252 30.7382 28.9999C30.7731 29.0207 30.8065 29.0444 30.8362 29.0712C31.3654 29.5254 31.6674 30.1829 31.6674 30.8776C31.6674 32.1868 30.6016 33.2526 29.2924 33.2526L29.2917 33.2519Z" fill="white"/>
                            <path d="M27.7066 20.5829C22.0319 20.5829 17.4148 15.9658 17.4148 10.2911C17.4148 4.61637 22.0319 0 27.7066 0C33.3814 0 37.9985 4.61711 37.9985 10.2918C37.9985 15.9666 33.3814 20.5837 27.7066 20.5837V20.5829Z" fill="#E13535"/>
                            <path d="M30.0817 15.0405H25.3317C24.8946 15.0405 24.5398 14.6857 24.5398 14.2486V13.0611C24.5398 11.0965 26.1377 9.49859 28.1023 9.49859C28.7576 9.49859 29.2898 8.96645 29.2898 8.3111C29.2898 7.65576 28.7576 7.12361 28.1023 7.12361H25.3317C24.8946 7.12361 24.5398 6.76885 24.5398 6.3317C24.5398 5.89456 24.8946 5.53979 25.3317 5.53979H28.1023C29.6304 5.53979 30.8728 6.78295 30.8728 8.31036C30.8728 9.83777 29.6297 11.0809 28.1023 11.0809C27.0113 11.0809 26.1229 11.9693 26.1229 13.0603V13.4559H30.0809C30.5181 13.4559 30.8728 13.8107 30.8728 14.2478C30.8728 14.685 30.5181 15.0397 30.0809 15.0397L30.0817 15.0405Z" fill="white"/>
                        </svg>
                    </div>
                    {NotificationOverlay && (
                        <>
                            <div className="vendor-arrow-to-point-notification"></div>
                            <div className="vendor-notification-overlay" ref={overlayRef}>
                                <Notification closeOverlay={closenotificationoverlay} />
                            </div>
                        </>
                    )}

                </div>
            </div>
        </>
    );
}

export default VendorTopMenuBar;
