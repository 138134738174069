import React, { useState, useEffect } from 'react';

import '../../../Styles/Payment/PaymentForm.css';

import { useTranslation } from 'react-i18next';

import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js';
// import { PayPalButtons } from '@paypal/react-paypal-js';

import axios from 'axios'; // Import Axios for making HTTP requests
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// Custom styling for the card inputs

const CARD_ELEMENT_OPTIONS = {
    showIcon: true,
    style: {
        base: {
            fontSize: '16px',
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Source Code Pro, monospace',
            '::placeholder': {
                color: '#aab7c4',
            },
        },

        invalid: {
            color: '#9e2146',
        },
    },
};

const PaymentForm = ({ total }) => {
    const base_URL = process.env.REACT_APP_API_BASE_URL;

    const vendor_Base_URL = process.env.REACT_APP_VENDOR_API_BASE_URL;

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [cartData, setCartData] = useState([]);
    console.log(cartData);

    const fetchDataFromCart = async () => {
    // Fetch item data from the API using the itemID
        const itemResponse = await axios.get(`${base_URL}/cartdetails/cartsp/1`);

        setCartData(itemResponse.data.cartDetails[0].Cart_Information);
    };

    useEffect(() => {
        fetchDataFromCart();
    }, []);

    const [paymentMethod, setPaymentMethod] = useState('');

    const stripe = useStripe();
    const elements = useElements();
    const [clientSecret, setClientSecret] = useState('');
    console.log(clientSecret);
    const [cardHolderName, setCardHolderName] = useState('');

    const [isProcessing, setIsProcessing] = useState(false);

    // Call the backend to create a PaymentIntent and get the clientSecret
    useEffect(() => {
    // Function to fetch the clientSecret from the backend
        const createPaymentIntent = async () => {
            try {
                const { data } = await axios.post(
                    'https://customers-api.omyfood.io/api/payment/create-payment-intent',
                    {
                        amount: total * 100, // Send the amount to charge, here 5000 cents = $50
                        currency: 'usd', // Specify the currency
                        description: 'payment created',
                    }
                );

                setClientSecret(data.clientSecret); // Set the clientSecret in state
                setIsProcessing(false); // Disable loading once clientSecret is received
            } catch (error) {
                console.error('Error creating PaymentIntent', error);
            }
        };

        createPaymentIntent(); // Call the function when the component mounts
    }, []); // Empty dependency array means this only runs once when the component mounts

    // useEffect(() => {
    //     const query = new URLSearchParams(window.location.search);
    //     const token = query.get('token');

    //     if (token) {
    //         // Capture the payment
    //         axios.get(`http://localhost:5000/capture-payment?token=${token}`)
    //             .then(response => {
    //                 console.log(response.data);
    //             })
    //             .catch(error => {
    //                 console.error('Error capturing PayPal payment:', error);
    //                 toast.error('An error occurred while capturing the PayPal payment.');
    //             });
    //     }
    // }, []);

    const handlePayment = async () => {
        if (paymentMethod === 'card') {
            // Handle card payment

            if (!stripe || !elements) {
                return; // Stripe.js hasn't loaded yet
            }

            setIsProcessing(true);

            try {
                // Confirm payment with card details and cardholder's name
                const { error, paymentIntent } = await stripe.confirmCardPayment(
                    clientSecret,
                    {
                        payment_method: {
                            card: elements.getElement(CardNumberElement), // Card details via CardNumberElement
                            billing_details: {
                                name: cardHolderName, // Send cardholder's name
                            },
                        },
                    }
                );

                if (error) {
                    console.log(error);
                    // setError(error.message);
                    setIsProcessing(false);
                } else if (paymentIntent.status === 'succeeded') {
                    console.log(paymentIntent);

                    try {
                        // Function to dynamically get the payment type ID
                        const getPaymentTypeId = () => {
                            if (paymentMethod === 'cash') return 1;
                            if (paymentMethod === 'card') return 2;
                            if (paymentMethod === 'other') return 3;
                            return null; // Handle cases where no selection is made
                        };

                        // Order data, you may get this from the form or app state
                        const orderData = {
                            Order_Info: 'Payment success and order placed',
                            Order_type_id: 1,
                            Payment_type_id: getPaymentTypeId(),
                            Total_price: 26.47,
                            Comments: 'Please deliver by 6 PM',
                            Status_id: 2,
                            Created_by: 3,
                        };

                        // Mapping through the cart items and structuring itemsData
                        const itemsData = cartData?.map((item) => ({
                            price: item.Total_Price,
                            Quantity: item.Item_Quantity,
                            Description: 'Large veggie pizza with extra olives',
                            Instructions: 'No bell peppers',
                        }));

                        // Prepare the payload
                        const dataToSend = {
                            orderData,
                            itemsData,
                        };

                        // Sending the POST request with axios
                        await axios
                            .post(`${vendor_Base_URL}/Order/vendor/1/customer/1`, dataToSend)
                            .then((response) => {
                                console.log('Order placed successfully:', response.data);
                                toast.success('Order placed successfully');
                            });
                    } catch (error) {
                        console.error('There was an error placing the order:', error);
                        toast.error('failed to place the order');
                    }

                    try {
                        const paymentDetails = {
                            Customer_id: 1,
                            Vendor_id: 1,
                            Order_id: 763674734,
                            Invoice_id: 20201030876,
                            Amount: 100,
                            Tax: 2,
                            Convence_fee: 3,
                            Total_amount: total,
                            Status_id: 1,
                            Created_by: 1,
                        };

                        // Sending the POST request with axios
                        await axios
                            .post(`${base_URL}/payment/savePaymentDb`, paymentDetails)
                            .then((response) => {
                                console.log('payment info saved successfully:', response.data);
                                // toast.success('Payment Info saved successfully');
                            });
                    } catch (error) {
                        console.error('Payment Info saved successfully:', error);
                        // toast.error('Payment Info Error');
                    }
                    setCartData([]);
                    toast.success('payment success');
                    navigate('/PaymentSuccess');
                    // setSuccess(true);
                    setIsProcessing(false);
                }
            } catch (error) {
                // setError('Payment failed. Please try again.');
                toast.error('Payment Failed');
                console.log(error);
                setIsProcessing(false);
                navigate('/PaymentFail');
            }
        } else if (paymentMethod === 'paypal') {
            // Handle PayPal payment (trigger PayPal Buttons)
            // try {
            //     const response = await axios.post('http://localhost:5000/create-payment', { amount });
            //     const { approvalUrl } = response.data;
            //     // Redirect the user to the PayPal approval URL
            //     window.location.href = approvalUrl;
            // } catch (error) {
            //     console.error('Error creating PayPal order:', error);
            //     alert('An error occurred while creating the PayPal order.');
            // }
        } else if (paymentMethod === 'cash') {
            try {
                // Function to dynamically get the payment type ID
                const getPaymentTypeId = () => {
                    if (paymentMethod === 'cash') return 1;
                    if (paymentMethod === 'card') return 2;
                    if (paymentMethod === 'other') return 3;
                    return null; // Handle cases where no selection is made
                };

                // Order data, you may get this from the form or app state
                const orderData = {
                    Order_Info: 'Payment success and order placed',
                    Order_type_id: 0,
                    Payment_type_id: getPaymentTypeId(),
                    Total_price: 26.47,
                    Comments: 'Please deliver by 6 PM',
                    Status_id: 2,
                    Created_by: 3,
                };

                // Mapping through the cart items and structuring itemsData
                const itemsData =
          cartData?.map((item) => ({
              price: item.Total_Price,
              Quantity: item.Item_Quantity,
              Description: 'Large veggie pizza with extra olives',
              Instructions: 'No bell peppers',
          })) || [];

                // Prepare the payload
                const dataToSend = {
                    orderData,
                    itemsData,
                };

                // Sending the POST request with axios
                await axios
                    .post(`${vendor_Base_URL}/Order/vendor/1/customer/1`, dataToSend)
                    .then((response) => {
                        console.log('Order placed successfully:', response.data);
                        toast.success('Order placed successfully');
                    });
            } catch (error) {
                console.error('There was an error placing the order:', error);
                toast.error('failed to place the order');
            }

            try {
                const paymentDetails = {
                    Customer_id: 1,
                    Vendor_id: 1,
                    Order_id: 763674734,
                    Invoice_id: 20201030876,
                    Amount: 100,
                    Tax: 2,
                    Convence_fee: 3,
                    Total_amount: total,
                    Status_id: 1,
                    Created_by: 1,
                };

                // Sending the POST request with axios
                await axios
                    .post(`${base_URL}/payment/savePaymentDb`, paymentDetails)
                    .then((response) => {
                        console.log('payment info saved successfully:', response.data);
                        // toast.success('Payment Info saved successfully');
                    });
            } catch (error) {
                console.error('Payment Info saved successfully:', error);
                // toast.error('Payment Info Error');
            }
            setCartData([]);
            // toast.success('payment success');
            navigate('/PaymentSuccess');
            // setSuccess(true);
            setIsProcessing(false);
            // Handle cash payment
            // navigate('/PaymentSuccess');
        }
    };

    const handlePaymentMethodChange = (method) => {
        setPaymentMethod(method);
    };

    return (
        <div className="payment-mode-container">
            <h4 className="payment-mode-text">{t('payment.preferred-mode')}</h4>

            <div
                className={
                    paymentMethod === 'paypal'
                        ? 'payment-container selected'
                        : 'payment-container'
                }
                onClick={() => handlePaymentMethodChange('paypal')}
            >
                <div className="svg-and-label-text">
                    <svg
                        width="17"
                        height="19.42"
                        viewBox="0 0 17 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="payment-svg-icon"
                    >
                        <path
                            d="M13.6165 1.8395C12.7327 0.811136 11.1895 0.289062 9.03322 0.289062H3.62793C2.94317 0.289062 2.3701 0.78564 2.26325 1.47041L0.0122672 16.0399C-0.0350837 16.3544 0.0559758 16.6725 0.262377 16.9129C0.465136 17.1508 0.761382 17.2868 1.0722 17.2868H4.92947L5.82186 11.5051C5.84007 11.4238 5.90684 11.3655 5.98212 11.3655H7.56777C11.4214 11.3655 13.7319 9.50791 14.43 5.84611L14.4919 5.49523C14.7226 3.91323 14.4616 2.82051 13.6165 1.8395ZM13.2924 5.30583L13.2377 5.61543C12.6562 8.66652 10.8022 10.1502 7.56898 10.1502H5.98333C5.35806 10.1502 4.82506 10.5666 4.65751 11.1616L4.6478 11.1591L4.6223 11.3255L4.62109 11.3303L3.88897 16.0727H1.23611L3.46281 1.65617C3.47617 1.56754 3.54537 1.50319 3.62793 1.50319H9.032C10.8192 1.50319 12.0527 1.88199 12.6974 2.63111C13.3045 3.33652 13.4696 4.09049 13.2924 5.30583Z"
                            fill="#020BEE"
                        />
                        <path
                            d="M16.047 4.25905C15.5686 3.71026 14.8935 3.3011 14.04 3.04492L13.6916 4.20805C14.3217 4.39746 14.8061 4.68278 15.1291 5.0543C15.7301 5.75364 15.9 6.53067 15.7204 7.72659C15.7204 7.72659 15.6876 7.92206 15.6609 8.04226C15.0854 11.0921 13.2326 12.5746 9.99333 12.5746H8.41496C7.73505 12.5746 7.16077 13.07 7.05029 13.7571L6.31695 18.4995H3.66045L3.92756 16.7706L2.728 16.5861L2.43418 18.485C2.39533 18.8006 2.49246 19.1163 2.70008 19.3518C2.90405 19.5825 3.19423 19.7136 3.49776 19.7136H7.35746L8.24863 13.9466C8.26198 13.8676 8.32269 13.7887 8.41496 13.7887H9.99333C13.8555 13.7887 16.1635 11.9311 16.8483 8.28994C16.8762 8.16974 16.9175 7.92813 16.9187 7.91721C17.153 6.36191 16.8847 5.2352 16.047 4.25905Z"
                            fill="#020BEE"
                        />
                    </svg>

                    <label htmlFor="paypal" className="payment-label-text">
                        {t('payment.paypal')}
                    </label>
                </div>
                <input
                    className="payment-radio-btn"
                    id="paypal"
                    type="radio"
                    value="paypal"
                    checked={paymentMethod === 'paypal'}
                    onChange={() => handlePaymentMethodChange('paypal')}
                />
            </div>
            <div
                className={
                    paymentMethod === 'card'
                        ? 'payment-container selected'
                        : 'payment-container'
                }
                onClick={() => handlePaymentMethodChange('card')}
            >
                <div className="svg-and-label-text">
                    <svg
                        width="19"
                        height="16"
                        viewBox="0 0 64 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="payment-svg-icon"
                    >
                        <path
                            d="M55.5651 0H8.46317C4.04736 0 0.472656 3.46182 0.472656 7.73818V37.0618C0.472656 41.3382 4.04736 44.8 8.46317 44.8H55.5651C59.981 44.8 63.5557 41.3382 63.5557 37.0618V7.73818C63.5557 3.46182 59.981 0 55.5651 0ZM59.3501 37.0618C59.3501 39.0778 57.6469 40.7273 55.5651 40.7273H8.46317C6.38143 40.7273 4.67819 39.0778 4.67819 37.0618V7.73818C4.67819 5.72218 6.38143 4.07273 8.46317 4.07273H55.5651C57.6469 4.07273 59.3501 5.72218 59.3501 7.73818V37.0618Z"
                            fill="#02A750"
                        />
                        <path
                            d="M19.3966 32.5801H10.9856C9.82443 32.5801 8.88281 33.492 8.88281 34.6164C8.88281 35.7409 9.82443 36.6528 10.9856 36.6528H19.3966C20.5578 36.6528 21.4994 35.7409 21.4994 34.6164C21.4994 33.492 20.5578 32.5801 19.3966 32.5801Z"
                            fill="#02A750"
                        />
                        <path
                            d="M53.0409 10.1855H10.9856C9.82443 10.1855 8.88281 11.0974 8.88281 12.2219C8.88281 13.3464 9.82443 14.2583 10.9856 14.2583H53.0409C54.2021 14.2583 55.1437 13.3464 55.1437 12.2219C55.1437 11.0974 54.2021 10.1855 53.0409 10.1855Z"
                            fill="#02A750"
                        />
                    </svg>

                    <label htmlFor="card" className="payment-label-text">
                        {t('payment.card')}
                    </label>
                </div>
                <input
                    className="payment-radio-btn"
                    id="card"
                    type="radio"
                    value="card"
                    checked={paymentMethod === 'card'}
                    onChange={() => handlePaymentMethodChange('card')}
                />
            </div>

            <div
                className={
                    paymentMethod === 'cash'
                        ? 'payment-container selected'
                        : 'payment-container'
                }
                onClick={() => handlePaymentMethodChange('cash')}
            >
                <div className="svg-and-label-text">
                    <svg
                        width="21"
                        height="12"
                        viewBox="0 0 21 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="payment-svg-icon"
                    >
                        <path
                            d="M20.3 0.75H5.95C5.7567 0.75 5.6 0.9067 5.6 1.1C5.6 1.2933 5.7567 1.45 5.95 1.45H8.06575C6.51248 2.43838 5.58002 4.15904 5.6 6C5.58002 7.84096 6.51248 9.56162 8.06575 10.55H0.7V1.45H5.95C6.1433 1.45 6.3 1.2933 6.3 1.1C6.3 0.9067 6.1433 0.75 5.95 0.75H0.7C0.313401 0.75 0 1.0634 0 1.45V10.55C0 10.9366 0.313401 11.25 0.7 11.25H20.3C20.6866 11.25 21 10.9366 21 10.55V1.45C21 1.0634 20.6866 0.75 20.3 0.75ZM6.3 6C6.3 3.4912 8.18405 1.45 10.5 1.45C12.816 1.45 14.7 3.4912 14.7 6C14.7 8.5088 12.816 10.55 10.5 10.55C8.18405 10.55 6.3 8.5088 6.3 6ZM20.3 10.55H12.9343C14.4875 9.56162 15.42 7.84096 15.4 6C15.42 4.15904 14.4875 2.43838 12.9343 1.45H20.3V10.55Z"
                            fill="#FF9D01"
                        />
                        <path
                            d="M11.55 4.77461C11.55 4.96791 11.7067 5.12461 11.9 5.12461C12.0933 5.12461 12.25 4.96791 12.25 4.77461C12.2162 3.99005 11.6276 3.34123 10.85 3.23146V3.19961C10.85 3.00631 10.6933 2.84961 10.5 2.84961C10.3067 2.84961 10.15 3.00631 10.15 3.19961V3.23146C9.37242 3.34123 8.78379 3.99005 8.75 4.77461C8.78379 5.55917 9.37242 6.20799 10.15 6.31776V8.04571C9.76051 7.95735 9.47562 7.62318 9.45 7.22461C9.45 7.03131 9.2933 6.87461 9.1 6.87461C8.9067 6.87461 8.75 7.03131 8.75 7.22461C8.78379 8.00917 9.37242 8.65799 10.15 8.76776V8.79961C10.15 8.99291 10.3067 9.14961 10.5 9.14961C10.6933 9.14961 10.85 8.99291 10.85 8.79961V8.76776C11.6276 8.65799 12.2162 8.00917 12.25 7.22461C12.2162 6.44005 11.6276 5.79123 10.85 5.68146V3.95351C11.2395 4.04187 11.5244 4.37604 11.55 4.77461ZM9.45 4.77461C9.47562 4.37604 9.76051 4.04187 10.15 3.95351V5.59571C9.76051 5.50735 9.47562 5.17318 9.45 4.77461ZM11.55 7.22461C11.5244 7.62318 11.2395 7.95735 10.85 8.04571V6.40351C11.2395 6.49187 11.5244 6.82604 11.55 7.22461Z"
                            fill="#FF9D01"
                        />
                        <path
                            d="M17.85 9.85H19.25C19.4433 9.85 19.6 9.6933 19.6 9.5V8.1C19.6 7.9067 19.4433 7.75 19.25 7.75C19.0567 7.75 18.9 7.9067 18.9 8.1V9.15H17.85C17.6567 9.15 17.5 9.3067 17.5 9.5C17.5 9.6933 17.6567 9.85 17.85 9.85Z"
                            fill="#FF9D01"
                        />
                        <path
                            d="M1.75039 7.75C1.55709 7.75 1.40039 7.9067 1.40039 8.1V9.5C1.40039 9.6933 1.55709 9.85 1.75039 9.85H3.15039C3.34369 9.85 3.50039 9.6933 3.50039 9.5C3.50039 9.3067 3.34369 9.15 3.15039 9.15H2.10039V8.1C2.10039 7.9067 1.94369 7.75 1.75039 7.75Z"
                            fill="#FF9D01"
                        />
                        <path
                            d="M3.15039 2.15039H1.75039C1.55709 2.15039 1.40039 2.30709 1.40039 2.50039V3.90039C1.40039 4.09369 1.55709 4.25039 1.75039 4.25039C1.94369 4.25039 2.10039 4.09369 2.10039 3.90039V2.85039H3.15039C3.34369 2.85039 3.50039 2.69369 3.50039 2.50039C3.50039 2.30709 3.34369 2.15039 3.15039 2.15039Z"
                            fill="#FF9D01"
                        />
                        <path
                            d="M17.85 2.85039H18.9V3.90039C18.9 4.09369 19.0567 4.25039 19.25 4.25039C19.4433 4.25039 19.6 4.09369 19.6 3.90039V2.50039C19.6 2.30709 19.4433 2.15039 19.25 2.15039H17.85C17.6567 2.15039 17.5 2.30709 17.5 2.50039C17.5 2.69369 17.6567 2.85039 17.85 2.85039Z"
                            fill="#FF9D01"
                        />
                    </svg>

                    <label htmlFor="cash" className="payment-label-text">
                        {t('payment.cash')}
                    </label>
                </div>
                <input
                    className="payment-radio-btn"
                    id="cash"
                    type="radio"
                    value="cash"
                    checked={paymentMethod === 'cash'}
                    onChange={() => handlePaymentMethodChange('cash')}
                />
            </div>

            {paymentMethod === 'card' && (
                <div>
                    <h3>Enter Card Details</h3>

                    <div className="card-label-input">
                        <label className="card-label-name">
                            {t('payment.name-on-card')}
                        </label>
                        <input
                            className="card-input-field"
                            type="text"
                            name="holderName"
                            placeholder="Card Holder Name"
                            value={cardHolderName}
                            onChange={(e) => setCardHolderName(e.target.value)}
                        />
                    </div>

                    {/* Card Number */}
                    <div className="card-label-input">
                        <label className="card-label-name">Card Number</label>
                        <CardNumberElement
                            className="Input-Card"
                            options={CARD_ELEMENT_OPTIONS}
                        />
                    </div>

                    <div className="expiry-and-cvv-container">
                        {/* Expiry Date */}
                        <div className="card-label-input">
                            <label className="card-label-name">Expiry Date</label>
                            <CardExpiryElement
                                className="Input"
                                options={CARD_ELEMENT_OPTIONS}
                            />
                        </div>

                        {/* CVC */}

                        <div className="card-label-input">
                            <label className="card-label-name">CVC</label>
                            <CardCvcElement
                                className="Input"
                                options={CARD_ELEMENT_OPTIONS}
                            />
                        </div>
                    </div>
                </div>
            )}

            <button
                className="make-payment-btn"
                onClick={() => {
                    handlePayment();
                }}
                disabled={!stripe || isProcessing}
            >
                {isProcessing ? 'Processing...' : 'Make Payment'}
            </button>
        </div>
    );
};

export default PaymentForm;
