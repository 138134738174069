import { useState } from 'react';
import '../Login/Login.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../Components/Loader/Loader';


function ForgetPassword({setReset}) {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const base_URL = process.env.REACT_APP_API_BASE_URL;

    const emailVerification = (value)=> {
        const newErrors = { ...errors };
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

        if (!value.trim()) {
            newErrors.email = 'Please enter your email.';
        } else if (!emailRegex.test(value)) {
            newErrors.email = 'Invalid email address.';
        } else {
            delete newErrors.email;
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        emailVerification(value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setReset('reset');
        const isValid = emailVerification(email);

        const now = new Date();
        const item = {
            value: email,
            expiry: now.getTime() + 15 * 60 * 1000, // Convert minutes to milliseconds
        };
   
        if (isValid) {
            try {
                setIsLoading(true);
                const res = await axios.post(`${base_URL}/auth/forgotpassword`, {email: email});
                setIsLoading(false);
                toast.success(res?.data?.message);
                localStorage.setItem('forgetPasswordEmail', item.value);
                if(res?.statusText == 'OK'){
                    navigate('/verify-otp');
                } else {
                    return;
                }
            } catch (error) {
                setIsLoading(false);
                toast.error(error?.response?.data?.error);
                console.error('Error from Signup',error);
            }
        }
    };

    return (
        <div className='login-page'>
            {isLoading && <Loader/>}
            <div className='background'></div>
            <div className='login-container'>
                <h2 className='heading'>Forgot Password</h2>
                <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <label className='label-text' htmlFor='email'>Email</label>
                        <input 
                            type='email' 
                            id='email' 
                            name='email'  
                            value={email}
                            placeholder='Enter your email' 
                            onChange={(e) => handleInputChange(e)}
                            className={errors.email ? 'input-error' : ''}
                        />
                        {errors.email && <span className="error-message">{errors.email}</span>}
                    </div>
                    <button type='submit' className='login-btn'>
                        Get Verification Code
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ForgetPassword;