import React, { useEffect, useRef, useState } from 'react';
import OhMyFoodLogo from '../../../assets/svg/OhMyFoodLogo';
import OhMyFoodText from '../../../assets/svg/OhMyFoodText';
import './MenuHeader.css';

const MenuHeader = ({restaurantData}) => {
    const overlayRef = useRef(null);
    const [inputValue, setInputValue] = useState('');
        
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (overlayRef.current && !overlayRef.current.contains(event.target)) {
                setInputValue('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="menu-header">
            
            <div className="vendor-topbar-container" >
                <div className="omf-container">
                    <OhMyFoodLogo />
                    <OhMyFoodText />
                </div>
                <div className="vendor-turn-on-location-container">
                    <h6 className="vendor-turn-on-location">
                        {restaurantData?.vendor?.Name}
                    </h6>
                    <div className="vendor-turn-on-location-description">
                        <p>{restaurantData?.vendor?.Address}</p>
                        <p>{restaurantData?.vendor?.Phone_number}</p>
                        <p>{restaurantData?.vendor?.Website_url}</p>
                        <p>{restaurantData?.vendor?.averageRating}</p>
                        {/* Passionate food enthusiast capturing culinary adventures one bite at a time. */}
                    </div>
                    
                </div>

                {/* search container  */}

                <div className="vendor-search-container">
                    <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="vendor-search-icon"
                        
                    >
                        <path
                            d="M21 21L16.2779 16.2695M18.8947 9.94737C18.8947 12.3204 17.9521 14.5962 16.2741 16.2741C14.5962 17.9521 12.3204 18.8947 9.94737 18.8947C7.57438 18.8947 5.29858 17.9521 3.62062 16.2741C1.94267 14.5962 1 12.3204 1 9.94737C1 7.57438 1.94267 5.29858 3.62062 3.62062C5.29858 1.94267 7.57438 1 9.94737 1C12.3204 1 14.5962 1.94267 16.2741 3.62062C17.9521 5.29858 18.8947 7.57438 18.8947 9.94737Z"
                            stroke="#464B59"
                            strokeWidth="2"
                            strokeLinecap="round"
                        />
                    </svg>

                    <input
                        type="text"
                        className="vendor-input-field"
                        value={inputValue}
                        placeholder='Search orders'
                        // onClick={toggleOverlay}
                    />

                    

                    <span className="vendor-verticle-line">|</span>

                    <svg
                        width="19"
                        height="30"
                        viewBox="0 0 19 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="vendor-mic-icon"
                    >
                        <path
                            d="M9.5 20.0938C6.29307 20.0903 3.69466 17.391 3.69071 14.0602V6.01492C3.70087 2.68237 6.31 -0.0105225 9.51863 3.09136e-05C12.7126 0.0105844 15.2997 2.69703 15.3099 6.01492V14.0608C15.3065 17.3915 12.7075 20.0909 9.50056 20.0944L9.5 20.0938ZM13.5029 6.01492C13.4944 3.71895 11.6959 1.86448 9.48476 1.87327C7.28602 1.88206 5.50502 3.73127 5.49712 6.01492V14.0608C5.50559 16.3567 7.30409 18.2112 9.51524 18.2024C11.714 18.1936 13.495 16.3444 13.5029 14.0608V6.01492ZM8.5968 23.757V28.1238H4.80278C4.30376 28.1238 3.89958 28.5436 3.89958 29.0619C3.89958 29.5802 4.30376 30 4.80278 30H14.1972C14.6962 30 15.1004 29.5802 15.1004 29.0619C15.1004 28.5436 14.6962 28.1238 14.1972 28.1238H10.4032V23.757C15.2709 23.2593 18.9859 19.0156 19 13.9353C19 13.417 18.5958 12.9972 18.0968 12.9972C17.5978 12.9972 17.1936 13.417 17.1936 13.9353C17.1936 18.3414 13.7422 21.926 9.5 21.926C5.25777 21.926 1.80641 18.3414 1.80641 13.9353C1.80641 13.417 1.40222 12.9972 0.903204 12.9972C0.404184 12.9972 0 13.417 0 13.9353C0.0141125 19.0156 3.7291 23.2593 8.5968 23.757Z"
                            fill="#464B59"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default MenuHeader;
