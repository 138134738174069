import React, {lazy} from 'react';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import VendorOrders from './Views/Vendor/VendorOrders';
const VendorDashboard = lazy(()=>import('./Views/Vendor/VendorDashboard'));
const Vendorsettings = lazy(()=>import('./Views/Vendor/Vendorsettings'));
const VendorRegistrationBG = lazy(()=>import('./Views/Vendor/VendorRegistration/VendorRegistrationBG'));
const VendorCouponsOffers = lazy(()=>import('./Views/Vendor/VendorCouponsOffers'));
// const  Switchaccount = lazy(()=>import('./Views/User/profilepage/Switchaccount'));
// const  SwitchAccountBtns = lazy(()=>import('./Views/User/profilepage/SwitchAccountBtns'));
const  PageNotFound = lazy(()=>import('./Views/User/PageNotFound/PageNotFound'));

function Vendorrouter() {

   
    return (
        <div>
            

            <BrowserRouter>
                <Routes>
                    <Route path='/vendor' element={<VendorRegistrationBG></VendorRegistrationBG>}></Route>
                    <Route path='/Home' element={<VendorDashboard></VendorDashboard>}></Route>
                    <Route path='/Menu' element={<VendorDashboard></VendorDashboard>}></Route>
                    <Route path='/VendorOrders' element={<VendorOrders ></VendorOrders>}></Route>
                    <Route path='/Vendorsettings' element={<Vendorsettings></Vendorsettings>}></Route>
                    {/* <Route path='/switch-account' element={<Switchaccount/>} /> */}
                    {/* <Route path='SwithAccountBtns' element={<SwitchAccountBtns/>} /> */}
                    <Route path='/VendorCouponsOffers' element={<VendorCouponsOffers></VendorCouponsOffers>}></Route>
                    <Route path='*' element={<PageNotFound/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default Vendorrouter;