import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import './../../../Styles/Cart/SubTotal.css';
import { Link } from 'react-router-dom';
import CookingInstructionModal from './CookingInstModal';
import DeliveryInstModal from './DeliveryInstModal';


function SubTotal({ totalPrice }) {
   

    const { t } = useTranslation();

    const [openCookingInstructions, setOpenCookingInstructions] = useState(false);
    const handleOpenCookingInst = () => setOpenCookingInstructions(true);
    const handleCloseCookingInst = () => setOpenCookingInstructions(false);

    const [openDeliveryInstructions, setOpenDeliveryInstructions] =
    useState(false);
    const handleOpenDeliveryInst = () => setOpenDeliveryInstructions(true);
    const handleCloseDeliveryInst = () => setOpenDeliveryInstructions(false);

    return (
        <div className="sub-total">
            <div className="sub-total-area">
                <h3>{t('cart.total-title')}</h3>

                <h3>${totalPrice}</h3>
            </div>
            <div className="instruction-btns">
                <button
                    className="cooking-inst-btn"
                    onClick={() => handleOpenCookingInst()}
                >
                    {t('cart.cooking-instructions')}
                </button>
                {openCookingInstructions && (
                    <CookingInstructionModal
                        open={openCookingInstructions}
                        handleClose={handleCloseCookingInst}
                    />
                )}
                <button
                    className="delivery-inst-btn"
                    onClick={() => handleOpenDeliveryInst()}
                >
                    {t('cart.delivery-instructions')}
                </button>
                {openDeliveryInstructions && (
                    <DeliveryInstModal
                        open={openDeliveryInstructions}
                        handleClose={handleCloseDeliveryInst}
                    />
                )}
            </div>
            <Link to="/Payment">
                <button className="place-order-btn">
                    {t('cart.place-order-button')}
                </button>
            </Link>
        </div>
    );
}

export default SubTotal;
