import React, { useState } from 'react';
import './MenuItem.css';
import {
    Box,
    Button,
    Modal,
    Select,
    MenuItem as MuiMenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import filtericon from '../../../assets/Filtericon.png';
import { IoMdClose } from 'react-icons/io';

const MenuItem = ({ restaurantData }) => {
    
    const [moreDetails, showMoreDetails] = useState(false);

    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState('all');
    const [selectedCategory, setSelectedCategory] = useState('all'); 

    const handleMoreClick = (itemId) => {
        showMoreDetails((prevDetails) => ({
            ...prevDetails,
            [itemId]: !prevDetails[itemId],
        }));
    };

    const [tempCategory, setTempCategory] = useState('all');
    const [tempFilter, setTempFilter] = useState('all');

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleCategoryChange = (event) => {
        setTempCategory(event.target.value);
    };

    const handleFilterChange = (event) => {
        setTempFilter(event.target.value);
    };

    const applyFilters = () => {
        setSelectedCategory(tempCategory);
        setFilter(tempFilter);
        setOpen(false); 
    };


    return (
        <div className='menu-main-container'>
            <div className='vendor-filter-container'>
                <Button
                    className='vendor-filter'
                    onClick={handleOpen}
                    startIcon={
                        <img
                            src={filtericon}
                            width='20px'
                            height='18px'
                            alt='filter icon'
                        />
                    }
                >
          Filter
                </Button>
            </div>
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 300,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Button
                        onClick={handleClose}
                        sx={{ position: 'absolute', top: -50, right: -50, color: 'white' }}
                    >
                        <IoMdClose size={30} color='white' />
                    </Button>
                    <h3>Filter Options</h3>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id='filter-label'>Veg / Non-Veg</InputLabel>
                        <Select
                            labelId='filter-label'
                            value={tempFilter} 
                            onChange={handleFilterChange}
                            label='Veg / Non-Veg'
                        >
                            <MuiMenuItem value='all'>All</MuiMenuItem>
                            <MuiMenuItem value={true}>Veg</MuiMenuItem>
                            <MuiMenuItem value={false}>Non-Veg</MuiMenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id='category-label'>Category</InputLabel>
                        <Select
                            labelId='category-label'
                            value={tempCategory}
                            onChange={handleCategoryChange}
                            label='Category'
                        >
                            <MuiMenuItem value='all'>All Categories</MuiMenuItem>
                            {restaurantData?.vendor?.vendorMenus.map((menu) => (
                                <MuiMenuItem key={menu.ID} value={menu.Menu_name}>
                                    {menu.Menu_name}
                                </MuiMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        fullWidth
                        variant='contained'
                        sx={{ mt: 2 }}
                        onClick={applyFilters}
                    >
            Apply
                    </Button>
                </Box>
            </Modal>
            {restaurantData?.vendor?.vendorMenus
                .filter(
                    (menu) =>
                        selectedCategory === 'all' || menu.Menu_name === selectedCategory
                )
                .map((menuname) => (
                    <div key={menuname.ID} className='menu-bottom-container'>
                        <h3 className='menu-starter-text'>
                            {menuname.Menu_name.toUpperCase()}
                        </h3>
                        <div className='menu-starter-container'>
                            {menuname?.menu_items
                                .filter(
                                    (item) => filter === 'all' || item.Vegetarian === filter
                                )
                                .map((item) => (
                                    <div key={item.id} className='menu-item'>
                                        <img
                                            src={item.Avatar_key}
                                            alt={item.Item_name}
                                            className='menu-starters-image'
                                        />
                                        <div className='menu-description-container'>
                                            <div className='heading-container'>
                                                <svg
                                                    width='15'
                                                    height='15'
                                                    viewBox='0 0 15 15'
                                                    fill='none'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <rect
                                                        x='0.75'
                                                        y='0.75'
                                                        width='13.5'
                                                        height='13.5'
                                                        stroke='#E13535'
                                                        strokeWidth='1.5'
                                                    />
                                                    <rect
                                                        x='3'
                                                        y='3'
                                                        width='9'
                                                        height='9'
                                                        rx='4.5'
                                                        fill='#E13535'
                                                    />
                                                </svg>
                                                <h3 className='menu-item-head-text'>
                                                    {item.Item_name}
                                                </h3>
                                            </div>
                                            <p className='menu-description'>{item.Description}</p>

                                            <h3
                                                className='menu-more-heading'
                                                onClick={() => handleMoreClick(item.id)}
                                            >
                                                {moreDetails[item.id] ? 'Less' : 'More'}
                                            </h3>
                                            {moreDetails[item.id] && item.Ingredients != '' && (
                                                <p
                                                    className='menu-description'
                                                >
                          Ingredients: {item.Ingridents}
                                                </p>
                                            )}
                                            <div className='menu-item-bottom-container'>
                                                <h6 className='menu-item-price-text'>{item.Price}$</h6>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default MenuItem;
