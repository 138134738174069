import React  from 'react';
import {BrowserRouter,Routes,Route } from 'react-router-dom';
import VendorOrders from './Views/Vendor/VendorOrders';

// const VendorDashboard = lazy(()=>import('./Views/Vendor/VendorDashboard'));
import VendorDashboard from './Views/Vendor/VendorDashboard';

// const Vendorsettings = lazy(()=>import('./Views/Vendor/Vendorsettings'));
import Vendorsettings from './Views/Vendor/Vendorsettings';

// const VendorRegistrationBG = lazy(()=>import('./Views/Vendor/VendorRegistration/VendorRegistrationBG'));
import VendorRegistrationBG from './Views/Vendor/VendorRegistration/VendorRegistrationBG';


// const VendorCouponsOffers = lazy(()=>import('./Views/Vendor/VendorCouponsOffers'));
import VendorCouponsOffers from './Views/Vendor/VendorCouponsOffers';
// const  Switchaccount = lazy(()=>import('./Views/User/profilepage/Switchaccount'));


// const  SwitchAccountBtns = lazy(()=>import('./Views/User/profilepage/SwitchAccountBtns'));
// import SwitchAccountBtns from './Views/User/profilepage/SwitchAccountBtns';

// import KYCUpdateMessage from './Views/Vendor/VendorRegistration/KYCUpdateMessage';

// const  PageNotFound = lazy(()=>import('./Views/User/PageNotFound/PageNotFound'));
import PageNotFound from './Views/User/PageNotFound/PageNotFound';


// const  SwitchAccountBtns = lazy(()=>import('./Views/User/profilepage/SwitchAccountBtns'));
// const  PageNotFound = lazy(()=>import('./Views/User/PageNotFound/PageNotFound'));


function Vendorrouter() {

   

   
    return (
        <div>
            

            <BrowserRouter>
                <Routes>
                    <Route path='/vendor' element={<VendorRegistrationBG></VendorRegistrationBG>}></Route>
                    <Route path='/Home' element={<VendorDashboard></VendorDashboard>}></Route>
                    <Route path='/Menu' element={<VendorDashboard></VendorDashboard>}></Route>
                    <Route path='/VendorOrders' element={<VendorOrders ></VendorOrders>}></Route>
                    <Route path='/Vendorsettings' element={<Vendorsettings></Vendorsettings>}></Route>
                    {/* <Route path='/switch-account' element={<Switchaccount/>} /> */}
                    {/* <Route path='SwithAccountBtns' element={<SwitchAccountBtns/>} /> */}
                    <Route path='/VendorCouponsOffers' element={<VendorCouponsOffers></VendorCouponsOffers>}></Route>
                    <Route path='*' element={<PageNotFound/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default Vendorrouter;