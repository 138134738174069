import React, { lazy, useState, useEffect } from 'react';
import axios from 'axios';
import './Tabs.css';

const Food = lazy(() => import('./Food'));
const Restaurant = lazy(() => import('./Restaurant'));
const Post = lazy(() => import('./Post'));
const Blogger = lazy(() => import('./Blogger'));
const Offer = lazy(() => import('./Offer'));

import foodicon from '../../../assets/foodicon.png';
import restauranticon from '../../../assets/restaurenticon.png';
import reelspostsicon from '../../../assets/reelspostsicon.png';
import bloggersicon from '../../../assets/bloggers.png';
import offericon from '../../../assets/offersicon.png';
import SearchHome from './SearchHomePage';
import { useTranslation } from 'react-i18next';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';

function Tabs({ tabName, setSearchQuery, setTabName, searchQuery }) {
    const base_URL = process.env.REACT_APP_API_BASE_URL;

    const [activeTab, setActiveTab] = useState('SearchHome');
    const [restaurantResults, setRestaurantResults] = useState([]);
    const [foodResults, setFoodResults] = useState([]);
    const [offerResults, setOfferResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // console.log(results);

    // Dummy latitude and longitude values, replace these with actual location data
    const latitude = '17.4072147';
    const longitude = '78.492518';
    const pageNo = 1;
    const pageSize = 10;

    const { t } = useTranslation();

    // Debounce state for delayed search query
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);

    // Debounce effect to set debounced search query after 1 second
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchQuery(searchQuery);
        }, 1000); // 1 second debounce

        return () => {
            clearTimeout(handler); // Clear timeout if searchQuery changes before 1 second
        };
    }, [searchQuery]);

    // Function to fetch API data using axios
    const fetchSearchResults = async (type) => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.get(
                `${base_URL}/search?searchText=${debouncedSearchQuery}&location=${latitude},${longitude}&pageNo=${pageNo}&pageSize=${pageSize}&type=${type}`
            );
            setRestaurantResults(response.data.data[0].Restaraunt_Information); // Assuming the API returns an 'items' array
            setFoodResults(response.data.data[0].Offer_Information);
            setOfferResults(response.data.data[0].Offer_Information);
        } catch (error) {
            setError(
                error.response
                    ? error.response.data.message
                    : 'Failed to fetch search results'
            );
        } finally {
            setLoading(false);
        }
    };

    // Trigger API call when a tab is selected
    useEffect(() => {
        if (activeTab) {
            fetchSearchResults(activeTab);
        }
    }, [activeTab, debouncedSearchQuery]);

    const renderTabContent = () => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error: {error}</p>;

        console.log(activeTab);

        switch (activeTab) {
        case 'SearchHome':
            return <SearchHome />;
        case 'restaurant_food':
            return <Food searchTerm={debouncedSearchQuery} results={foodResults} />;
        case 'restaurant':
            return (
                <Restaurant
                    searchTerm={debouncedSearchQuery}
                    results={restaurantResults}
                />
            );
        case 'reels':
            return <Post />;
        case 'bloggers':
            return <Blogger searchTerm={debouncedSearchQuery} />;
        case 'offer':
            return (
                <Offer searchTerm={debouncedSearchQuery} results={offerResults} />
            );
        default:
            return null;
        }
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setTabName(tab ? tab.charAt(0).toUpperCase() + tab.slice(1) : '');
    };
    return (
        <div className="tabs-total-main-container">
           

            <SideMenuBar />

            <TopMenuBar
                tabName={tabName}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
            />

            <div className="tabs-main-container">
                <div className="icon-and-tabs-container">
                    <div
                        className="icon-and-text-container-search"
                        style={{
                            backgroundColor:
                activeTab === 'restaurant_food' ? '#EEEDEB' : '#FFFFFF',
                        }}
                    >
                        <img
                            className="food-icon-search-overlay-search"
                            src={foodicon}
                            alt="food"
                        />
                        <div
                            style={{ color: activeTab === 'restaurant_food' ? 'black' : '' }}
                            className="text-search-overlay-tab"
                            onClick={() => handleTabClick('restaurant_food')}
                        >
                            {t('searchscreen.food-btn')}
                        </div>
                    </div>

                    <div
                        className="icon-and-text-container-search"
                        style={{
                            backgroundColor:
                activeTab === 'restaurant' ? '#EEEDEB' : '#FFFFFF',
                        }}
                    >
                        <img
                            className="food-icon-search-overlay-search"
                            src={restauranticon}
                            alt="restaurant"
                        />
                        <div
                            style={{ color: activeTab === 'restaurant' ? '' : '' }}
                            className="text-search-overlay-tab"
                            onClick={() => handleTabClick('restaurant')}
                        >
                            {t('searchscreen.restaurants-btn')}
                        </div>
                    </div>

                    <div
                        className="icon-and-text-container-search"
                        style={{
                            backgroundColor: activeTab === 'reels' ? '#EEEDEB' : '#FFFFFF',
                        }}
                    >
                        <img
                            className="food-icon-search-overlay-search"
                            src={reelspostsicon}
                            alt="restaurant"
                        />
                        <div
                            style={{ color: activeTab === 'reels' ? '' : '' }}
                            className="text-search-overlay-tab"
                            onClick={() => handleTabClick('reels')}
                        >
                            {t('searchscreen.reels-and-posts--btn')}
                        </div>
                    </div>

                    <div
                        className="icon-and-text-container-search"
                        style={{
                            backgroundColor: activeTab === 'bloggers' ? '#EEEDEB' : '#FFFFFF',
                        }}
                    >
                        <img
                            className="food-icon-search-overlay-search"
                            src={bloggersicon}
                            alt="restaurant"
                        />
                        <div
                            style={{ color: activeTab === 'bloggers' ? '' : '' }}
                            className="text-search-overlay-tab"
                            onClick={() => handleTabClick('bloggers')}
                        >
                            {t('searchscreen.bloggers-btn')}
                        </div>
                    </div>

                    <div
                        className="icon-and-text-container-search"
                        style={{
                            backgroundColor: activeTab === 'offer' ? '#EEEDEB' : '#FFFFFF',
                        }}
                    >
                        <img
                            className="food-icon-search-overlay-search"
                            src={offericon}
                            alt="restaurant"
                        />
                        <div
                            style={{ color: activeTab === 'offer' ? '' : '' }}
                            className="text-search-overlay-tab"
                            onClick={() => handleTabClick('offer')}
                        >
                            {t('searchscreen.offers-btn')}
                        </div>
                    </div>
                </div>
                <div className="tab-content-search-overlay-fake">
                    {renderTabContent()}
                </div>
            </div>
           

            <RightSideBar />
        </div>
    );
}

export default Tabs;
