import  { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const KeycloakTokenManager = () => {
    const { keycloak } = useKeycloak();

    useEffect(() => {
        if (keycloak && keycloak.authenticated) {
            // Save tokens and session ID
            localStorage.setItem('token', keycloak.token);
            localStorage.setItem('refreshToken', keycloak.refreshToken);
            localStorage.setItem('sessionId', keycloak.tokenParsed?.sid);

            console.log('Token:', keycloak.token);
            console.log('Refresh Token:', keycloak.refreshToken);
            console.log('Session ID:', keycloak.tokenParsed?.sid);

            // Set up a token refresh
            const refreshInterval = setInterval(() => {
                keycloak
                    .updateToken(30) // Refresh if token will expire in 30 seconds
                    .then((refreshed) => {
                        if (refreshed) {
                            localStorage.setItem('token', keycloak.token);
                            localStorage.setItem('refreshToken', keycloak.refreshToken);
                            console.log('Token refreshed:', keycloak.token);
                        }
                    })
                    .catch((error) => {
                        console.error('Failed to refresh token:', error);
                    });
            }, 60000);

            return () => clearInterval(refreshInterval); // Clear interval on unmount
        }
    }, [keycloak]);

    return null;
};

export default KeycloakTokenManager;
