import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';
import img3 from '../../../assets/onlinestatus.png';

import './Profilescreen.css';
// import PostModal from './PostModal';
// import ReelsModal from './ReelsModal';
import SavedModal from './SavedModal';
import { useTranslation } from 'react-i18next';

import './Profilescreen.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';

const Profilescreen = () => {
    const base_URL = process.env.REACT_APP_API_BASE_URL;

    // const user_Base_URL = process.env.REACT_APP_USER_API_BASE_URL;

    const { t } = useTranslation();
    const [contentType, setContentType] = useState('posts');
    // const [Posts, setPosts] = useState(null);
    // const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    // console.log(selectedImageIndex);
    const navigate = useNavigate();

    const [Reels, setReels] = useState(null);
    console.log(Reels);

    const [Saved, setSaved] = useState(null);
    const [savePost, setSavePost] = useState([]);
    const [userPosts, setUserPost] = useState([]);
    console.log('saved post', Saved);
    const containerRef = useRef(null);

    // const [showDetails, setShowDetails] = useState(null);

    // const handleMouseEnter = (postId) => {
    //     setShowDetails(postId);
    // };

    // const handleMouseLeave = () => {
    //     setShowDetails(null);
    // };

    // const [openPostModal, setOpenPostModal] = useState(false);
    // const handleOpenPostModal = () => setOpenPostModal(true);
    // const handleClosePostModal = () => setOpenPostModal(false);

    // const [openReelModal, setOpenReelModal] = useState(false);
    // const handleOpenReelModal = () => setOpenReelModal(true);
    // const handleCloseReelModal = () => setOpenReelModal(false);

    const [openSavedModal, setOpenSavedModal] = useState(false);
    const handleOpenSavedModal = () => setOpenSavedModal(true);
    const handleCloseSavedModal = () => setOpenSavedModal(false);

    // const [images, setImages] = useState([]);
    // console.log('images', images);
    // const [videos, setVideos] = useState([]);
    // console.log(videos);

    const [profileImagePreview, setProfileImagePreview] = useState();
    const token = localStorage.getItem('authToken');
    const decodedToken = jwtDecode(token);

    const handleContentTypeChange = async (type) => {
        setContentType(type);
        try {
            const res = await axios.get(
                `${base_URL}/post/savedposts/${decodedToken?.id}`
            );
            console.log('res saved', res);

            if (res?.statusText === 'OK') {
                const data = res?.data?.savedPosts?.[0];
                setSavePost(data?.Saved_Post_Information);
                toast.success(res?.data?.message);
            }
        } catch (error) {
            console.log('error', error);

            toast.error(error?.response?.data?.error);
            console.error(
                error.response?.data.error || 'Unable to connect to the server.'
            );
        }
    };

    const [userData, setUserData] = useState([]);
    // console.log(userData);

    useEffect(() => {
    // Fetch the user data from the API
        axios
            .get(`${base_URL}/customers/user/${decodedToken?.id}`)
            .then((response) => {
                console.log('response', response);
                const data = response?.data?.posts?.[0];
                setUserPost(data?.Post_Information);
                setUserData(data);
                setProfileImagePreview(data?.User_Information?.User_ProfilePicture);
            })
            .catch((error) => console.error('Error fetching user data:', error));
    }, []);

    // Function to classify attachments as images or videos
    // const classifyAttachments = (attachments) => {
    //     const imageAttachments = [];
    //     const videoAttachments = [];

    //     attachments.forEach((attachment) => {
    //         const url = attachment.Avatar_key;

    //         // Check if the URL ends with common image or video extensions
    //         if (url.match(/\.(jpeg|jpg|gif|png)$/)) {
    //             imageAttachments.push(attachment);
    //         } else if (url.match(/\.(mp4|mov|avi)$/)) {
    //             videoAttachments.push(attachment);
    //         }
    //     });

    //     // Update the state with classified attachments
    //     setImages((prevImages) => [...prevImages, ...imageAttachments]);
    //     setVideos((prevVideos) => [...prevVideos, ...videoAttachments]);
    // };

    // Fetch API data and classify attachments
    // const fetchPosts = async () => {
    //     try {
    //         const response = await axios.get(`${user_Base_URL}/account/3`); // Replace with your API endpoint
    //         const fetchedPosts = response.data.user.result[0].Posts;
    //         console.log(fetchedPosts);

    //         // Loop through each post's Attachments and classify them
    //         fetchedPosts.forEach((post) => {
    //             if (post.Attachments && post.Attachments.length > 0) {
    //                 classifyAttachments(post.Attachments);
    //             }
    //         });
    //     } catch (error) {
    //         console.error('Error fetching posts:', error);
    //     }
    // };

    // useEffect(() => {
    //     fetchPosts();
    // }, []);

    // const handleSelectedImageIndex = (id) => {
    //     setSelectedImageIndex(id);
    // };

    const handleSavedClick = (saved) => {
        setSaved(saved);
        setReels(saved); // remove it ( just written error purpose )
    };

    const handleNavigate = () => {
        navigate('/profilesettings');
    };
    // const [refreshCount, setRefreshCount] = useState(0);

    // Function to delete post by ID
    // const handleDeletePost = (postId) => {
    //     console.log(postId);
    //     const filteredPosts = images.filter((post) => post.Post_id !== postId);
    //     console.log(filteredPosts);
    //     setPosts(filteredPosts);
    //     setOpenPostModal(false); // Close modal after deletion
    // };

    return (
        <div className='profile-dashboard'>
            <div className='profile-side-bar'>
                <SideMenuBar></SideMenuBar>
            </div>
            <div className='profile-home'>
                <div className='profile-Topbar-cont'>
                    <TopMenuBar></TopMenuBar>
                </div>
                <div className='profile-page-cont'>
                    <div className='profile-page' ref={containerRef}>
                        <div className='profilepage-main-cont'>
                            <div className='Topofpage-Main-container'>
                                <div className='Top1-layout'>
                                    <Link to='/'>
                                        <svg
                                            width='30'
                                            height='30'
                                            viewBox='0 0 16 27'
                                            fill='none'
                                            className='profile-vector-icon'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <path
                                                d='M13.5881 27L-2.36041e-06 13.5L13.5881 2.37582e-06L16 2.39625L4.82378 13.5L16 24.6038L13.5881 27Z'
                                                fill='#2C3142'
                                            />
                                        </svg>
                                    </Link>
                                    <div className='Edit-button-layout'>
                                        {/* <Link  to='/profilesettings'> */}
                                        <svg
                                            width='48'
                                            height='41'
                                            viewBox='0 0 48 41'
                                            fill='none'
                                            className='menuicon'
                                            xmlns='http://www.w3.org/2000/svg'
                                            onClick={() => handleNavigate()}
                                        >
                                            <path
                                                d='M38.6667 29.041H9.33333C8.59695 29.041 8 29.5509 8 30.1799C8 30.8089 8.59695 31.3188 9.33333 31.3188H38.6667C39.403 31.3188 40 30.8089 40 30.1799C40 29.5509 39.403 29.041 38.6667 29.041Z'
                                                fill='white'
                                            />
                                            <path
                                                d='M38.6667 19.3613H9.33333C8.59695 19.3613 8 19.8712 8 20.5002C8 21.1292 8.59695 21.6391 9.33333 21.6391H38.6667C39.403 21.6391 40 21.1292 40 20.5002C40 19.8712 39.403 19.3613 38.6667 19.3613Z'
                                                fill='white'
                                            />
                                            <path
                                                d='M38.6667 9.68164H9.33333C8.59695 9.68164 8 10.1915 8 10.8205C8 11.4495 8.59695 11.9594 9.33333 11.9594H38.6667C39.403 11.9594 40 11.4495 40 10.8205C40 10.1915 39.403 9.68164 38.6667 9.68164Z'
                                                fill='white'
                                            />
                                        </svg>
                                        {/* </Link> */}
                                    </div>
                                </div>
                                <div className='profile-container-2'>
                                    <div className='profile-status-layout'>
                                        <img
                                            src={profileImagePreview}
                                            alt='profile-img'
                                            className='profile-photo'
                                        />
                                        <img
                                            src={img3}
                                            alt='status-symbol'
                                            className='profile-status-icon'
                                        />
                                    </div>
                                    <div className='Top-right-layout'>
                                        <div className='profile-name-layout'>
                                            <pre className='profile-name-text'>
                                                {userData?.User_Information?.User_FirstName}{' '}
                                                {userData?.User_Information?.User_LastName}
                                            </pre>
                                        </div>
                                        <div className='profile-description-layout'>
                                            <p className='profile-desc-line1'>
                                                {userData?.User_Information?.User_Description}
                                            </p>
                                        </div>
                                        <div className='profile-info-layout'>
                                            <span className='followers-count-layout'>
                                                <p className='data-count-text'>1</p>
                                            </span>
                                            <span className='following-count-layout'>
                                                <p className='data-count-text'>1</p>
                                            </span>
                                            <span className='posts-count-layout'>
                                                <p className='data-count-text'>
                                                    {userData?.RecordCount}
                                                </p>
                                            </span>
                                            <span className='likes-count-layout'>
                                                <p className='data-count-text'>0</p>
                                            </span>
                                            <span className='followers-head-layout'>
                                                <p className='data-head-text'>
                                                    {t('profilescreen.followers-heading')}
                                                </p>
                                            </span>
                                            <span className='following-head-layout'>
                                                <p className='data-head-text'>
                                                    {t('profilescreen.following-heading')}
                                                </p>
                                            </span>
                                            <span className='posts-head-layout'>
                                                <p className='data-head-text'>
                                                    {t('profilescreen.posts-heading')}
                                                </p>
                                            </span>
                                            <span className='likes-head-layout'>
                                                <p className='data-head-text'>
                                                    {t('profilescreen.likes-heading')}
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='Buttons-layout'>
                                    <Button
                                        className={`Posts-button ${
                                            contentType === 'posts' ? 'activeBtn' : 'inactive'
                                        }`}
                                        variant='contained'
                                        onClick={() => handleContentTypeChange('posts')}
                                    >
                                        {t('profilescreen.posts-button')}
                                    </Button>
                                    {/* <Button
                                        className={`Reels-button ${
                                            contentType === 'reels' ? 'activeBtn' : 'inactive'
                                        }`}
                                        variant="contained"
                                        onClick={() => handleContentTypeChange('reels')}
                                    >
                                        {t('profilescreen.reels-button')}
                                    </Button> */}
                                    <Button
                                        className={`saved-button ${
                                            contentType === 'saved' ? 'activeBtn' : 'inactive'
                                        }`}
                                        variant='contained'
                                        onClick={() => handleContentTypeChange('saved')}
                                    >
                                        {t('profilescreen.saved-button')}
                                    </Button>
                                </div>
                            </div>
                            <div className='Bottompage-container'>
                                {contentType === 'posts' && (
                                    <div className='posts-container'>
                                        {userPosts?.length > 0 ? (
                                            userPosts.map((item) => (
                                                <>
                                                    {console.log('item', item)}
                                                    <div
                                                        className={`posts ${
                                                            Saved === item.id ? 'selected' : ''
                                                        }`}
                                                        key={item.id}
                                                        onClick={() => {
                                                            handleSavedClick(userPosts);
                                                            handleOpenSavedModal();
                                                        }}
                                                    >
                                                        {JSON.parse(item.Attachment_Keys)[0].match(
                                                            /\.(jpeg|jpg|gif|png)$/
                                                        ) ? (
                                                                <img
                                                                    src={JSON.parse(item.Attachment_Keys)[0]}
                                                                    className='saved-content'
                                                                    alt={`saved-${item.Post_ID}`}
                                                                />
                                                            ) : (
                                                                <video className='saved-content'>
                                                                    <source
                                                                        src={JSON.parse(item.Attachment_Keys)[0]}
                                                                        type='video/mp4'
                                                                    />
                                                                </video>
                                                            )}
                                                    </div>
                                                </>
                                            ))
                                        ) : (
                                            <div className='no-reels-and-images-message'>
                                            No Posts Available.
                                            </div>
                                        )}

                                        {openSavedModal && (
                                            <SavedModal
                                                open={openSavedModal}
                                                handleClose={handleCloseSavedModal}
                                                Saved={Saved}
                                            />
                                        )}
                                    </div>
                                )}
                                {contentType === 'saved' &&
                                <div className='saved-container'>
                                    { savePost.length > 0 ? (
                                        savePost.map((item) => (
                                            <>
                                                {console.log('item', savePost.length)}
                                                <div
                                                    className={`saved ${
                                                        Saved === item.id ? 'selected' : ''
                                                    }`}
                                                    key={item.id}
                                                    onClick={() => {
                                                        handleSavedClick(savePost);
                                                        handleOpenSavedModal();
                                                    }}
                                                >
                                                    {JSON.parse(item.Attachment_Keys)[0].match(
                                                        /\.(jpeg|jpg|gif|png)$/
                                                    ) ? (
                                                            <img
                                                                src={JSON.parse(item.Attachment_Keys)[0]}
                                                                className='saved-content'
                                                                alt={`saved-${item.Post_ID}`}
                                                            />
                                                        ) : (
                                                            <video className='saved-content'>
                                                                <source
                                                                    src={JSON.parse(item.Attachment_Keys)[0]}
                                                                    type='video/mp4'
                                                                />
                                                            </video>
                                                        )}
                                                </div>
                                            </>
                                        ))
                                    ) : (
                                        <div className='no-reels-and-images-message'>
                      No Posts Available.
                                        </div>
                                    )}

                                    {openSavedModal && (
                                        <SavedModal
                                            open={openSavedModal}
                                            handleClose={handleCloseSavedModal}
                                            Saved={Saved}
                                        />
                                    )}
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='profile-right-side-bar'>
                        <RightSideBar></RightSideBar>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profilescreen;
