import '../../VendorStyles/Preparing.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Preparing() {
    const vendor_Base_URL = process.env.REACT_APP_VENDOR_API_BASE_URL;

    const [orders, setOrders] = useState([]);
    console.log(orders);

    useEffect(() => {
    // Fetch data from the API
        axios
            .get(`${vendor_Base_URL}/order/vendor/1?pageSize=10&pageNumber=1`)

            .then((response) => {
                console.log(response);
                const formattedOrders = response.data.allOrdersDetails
                    .filter((order) => order.Status_id === 2)
                    .map((order) => {
                        const createdAt = new Date(order.Created_at);
                        const time = createdAt.toLocaleTimeString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                        });
                        const date = `${createdAt.getDate()} ${createdAt.toLocaleString(
                            'en-US',
                            { month: 'short' }
                        )}`;
                        return {
                            id: order.ID,
                            time,
                            date,
                            customerId: order.Customer_id,
                        };
                    });
                setOrders(formattedOrders);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, []);

    const handlePushToPickup = (order) => {
        axios
            .put(`${vendor_Base_URL}/Order/Vendor/1/order/${order.id}/status`, {
                status_id: 5,
            })
            .then(() => {
                // Update the local state to reflect the change
                setOrders(orders.filter((o) => o.id !== order.id));
            })
            .catch((error) => console.error('Error updating order status:', error));
    };

    return (
        <div className="preparing-main">
            {orders.map((order) => (
                <div key={order.id} className="preparing-single-order">
                    <div className="preparing-orderid">
                        <p className="preparing-order-details1">
              Order ID:<span className="preparing-order-id">{order.id}</span>
                        </p>
                    </div>
                    <div className="preparing-date-and-time">
                        <p className="preparing-order-details2">{order.time}</p>
                        <p className="preparing-order-details3">{order.date}</p>
                    </div>
                    <button
                        className="push-to-pickup-btn"
                        onClick={() => handlePushToPickup(order)}
                    >
                        <p className="push-to-pickup-text">Push to Pickup</p>
                    </button>
                </div>
            ))}
        </div>
    );
}

export default Preparing;
