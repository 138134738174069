import React, { useEffect, useState } from 'react';
import '../Styles/Reels/ReelsLeft.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ActionTypes } from '../Redux/Contants/action-types';

import starPic from './../assets/Star 3.png';

import axios from 'axios';

function ReelsLeft(props) {
    const base_URL = process.env.REACT_APP_API_BASE_URL;
    

    const {
        profilePic,
        userName,
        restaurantName,
        AccountID,
        // starPic,
        rating,
        distance,
        description,
        restaurant,
       
        // description2,
        // vendorID
    } = props;


    const navigate = useNavigate();
    const Dispatch = useDispatch();

    const [isFollowing, setIsFollowing] = useState(false);

    const fetchFollowData = async () => {
        const response = await axios.get(
            `${base_URL}/follow/account/check/${AccountID}?Follow_by_id=6`
        );
        const followingOrNot = response.data.isFollowing;
        setIsFollowing(followingOrNot);
        // console.log(followingOrNot);
    };

    useEffect(() => {
        fetchFollowData();
    }, []);

    const handleFollow = async () => {
        const Data = {
            Follow_by_id: 6,
            Created_by: Date.now(),
        };

        // let followResponse;
        if (!isFollowing) {
            // Follow logic
            await axios.post(`${base_URL}/follow/account/${AccountID}`, Data);

            setIsFollowing(true);
            fetchFollowData();
        } else {
            // Unfollow logic
            await axios.delete(`${base_URL}/follow/account/${AccountID}/6`);

            setIsFollowing(false);
            fetchFollowData();
        }
    };

    const handleRestaurant = (restaurantName) => {
    // Find the post that matches the restaurant name
        const filteredPosts = restaurant?.filter((post) =>
            post.Restaurant_Info.some(
                (restaurant) => restaurant.Vendor_Name === restaurantName
            )
        );

        // If we found a post that matches the restaurant name
        if (filteredPosts?.length > 0) {
            // Retrieve the menu items from the first matching post (or loop if needed)
            const menuItems = filteredPosts[0].Menu_items_Info;

            // setFilteredItems(menuItems);

            const restaurantInfo = filteredPosts[0].Restaurant_Info;

            // Store the menu items in the state

            Dispatch({
                type: ActionTypes.SENDING_DATA_TO_RESTAURANT_PAGE,
                payload: restaurantInfo,
            });

            Dispatch({
                type: ActionTypes.SENDING_DATA_TO_RESTAURANT_ITEMS,
                payload: menuItems,
            });

            navigate('/RestaurantPage');

            // Log for debugging
        } else {
            console.log('No matching restaurant found.');
        }
    };

    return (
        <div className="left-container">
            <div className="reels-left">
                <div className="pic-username">
                    <Link to="/profilescreen">
                        <img className="profile" src={profilePic} alt="userphoto" />
                    </Link>
                    <div className="user-name-and-follow-container">
                        <h3 className="user-name">@{userName}</h3>
                        <button className="follow-btn" onClick={() => handleFollow()}>
                            {isFollowing ? 'Following' : 'Follow'}
                        </button>
                    </div>
                </div>

                <div>
                    <button
                        className="restaurant-name-reels"
                        onClick={() => handleRestaurant(restaurantName)}
                    >
                        {restaurantName}
                    </button>

                    <div className="rating-container">
                        <img className="start-img" src={starPic} alt="star" />
                        <p className="rating">{rating}</p>
                        <p className="distance">{distance} Distance</p>
                    </div>
                </div>

                <div className="desc-cont">
                    <p className="restaurant-description1">{description}</p>
                </div>
            </div>
            <div className="shadow-overlay"></div> {/* Add shadow to video */}
        </div>
    );
}

export default ReelsLeft;
