import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { useTranslation } from 'react-i18next';
import Modal from '@mui/material/Modal';
import './../../../Styles/Inbox/RateOrderModel.css';
import { toast } from 'react-toastify';

function RateOrderModel({ handleClose, open, rateOrder }) {
    const { t } = useTranslation();

    const [value1, setValue1] = useState(0);
    const [value2, setValue2] = useState(0);
    const [value3, setValue3] = useState(0);

    console.log(rateOrder);

    const handleSubmit = () => {
        toast.error('error in rating');
    };
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    '& > .MuiBackdrop-root': {
                        backgroundColor: 'rgba(0,0,0,0.1)',
                    },
                }}
            >
                <Box className="rate-order-model-style">
                    <div className="title-cross-svg-container">
                        <h1 className="title">{t('inbox.rate-your-order-title')}</h1>
                        <div className="cross-svg">
                            <svg
                                width="23"
                                height="18"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cross-icon"
                                onClick={handleClose}
                            >
                                <path
                                    d="M14.7904 12.5002L24.5249 2.76519C25.1584 2.13197 25.1584 1.10814 24.5249 0.474916C23.8917 -0.158305 22.8679 -0.158305 22.2346 0.474916L12.4999 10.2099L2.76535 0.474916C2.13185 -0.158305 1.10834 -0.158305 0.475128 0.474916C-0.158376 1.10814 -0.158376 2.13197 0.475128 2.76519L10.2096 12.5002L0.475128 22.2352C-0.158376 22.8684 -0.158376 23.8922 0.475128 24.5255C0.790694 24.8413 1.20562 25 1.62024 25C2.03487 25 2.44949 24.8413 2.76535 24.5255L12.4999 14.7905L22.2346 24.5255C22.5505 24.8413 22.9651 25 23.3798 25C23.7944 25 24.209 24.8413 24.5249 24.5255C25.1584 23.8922 25.1584 22.8684 24.5249 22.2352L14.7904 12.5002Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>

                    <div>
                        <p className="food-heading">
                            {t('inbox.how-was-your-food-heading')}
                        </p>
                        <div className="food-name-and-rating">
                            {rateOrder.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div className="res-itm-name-and-rating">
                                            {/* <p>{item.restaurantItem1}</p> */}
                                            <p>Chicken Soft noodles (1)</p>

                                            <Box
                                                sx={{
                                                    '& > legend': { mt: 2 },
                                                }}
                                            >
                                                <Rating
                                                    name="simple-controlled"
                                                    value={value1}
                                                    onChange={(event, newValue) => {
                                                        setValue1(newValue);
                                                    }}
                                                />
                                            </Box>
                                        </div>

                                        <div className="res-itm-name-and-rating">
                                            {/* <p>{item.restaurantItem2}</p> */}
                                            <p>Chicken noodles (2)</p>
                                            <Box
                                                sx={{
                                                    '& > legend': { mt: 2 },
                                                }}
                                            >
                                                <Rating
                                                    name="simple-controlled"
                                                    value={value2}
                                                    onChange={(event, newValue) => {
                                                        setValue2(newValue);
                                                    }}
                                                />
                                            </Box>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <p className="delivery-experience">
                            {t('inbox.how-was-the-delivery-exp')}
                        </p>
                        <Box
                            sx={{
                                '& > legend': { mt: 2 },
                            }}
                        >
                            <Rating
                                name="simple-controlled"
                                value={value3}
                                onChange={(event, newValue) => {
                                    setValue3(newValue);
                                }}
                            />
                        </Box>

                        <p className="write-a-review">{t('inbox.write-a-review')}</p>
                        <textarea
                            type="text"
                            className="review-input"
                            placeholder={t('inbox.write-a-review-placeholder')}
                        />

                        <button className="submit-btn" onClick={handleSubmit}>
                            {t('inbox.submit-btn')}
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default RateOrderModel;
