import React from 'react';
import '../../../VendorStyles/VendorRegistrationBG.css';
import VendorRegistrationSideMenuBar from './VendorRegistrationSideMenuBar';
import VendorRegistrationTopMenuBar from './VendorRegistrationTopMenuBar';
import VendorRegistrationForm from './VendorRegistrationForm';
import VendorRegistrationRightSideMenuBar from './VendorRegistrationRightSideBar';

function VendorRegistrationBG() {
    return (
        <div>

            <VendorRegistrationTopMenuBar></VendorRegistrationTopMenuBar>
            <VendorRegistrationSideMenuBar></VendorRegistrationSideMenuBar>
            <VendorRegistrationForm></VendorRegistrationForm>
            <VendorRegistrationRightSideMenuBar></VendorRegistrationRightSideMenuBar>

        </div>
    );
}

export default VendorRegistrationBG;