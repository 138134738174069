import React from 'react';

const OhMyFoodLogo = () => {
    return (
        <svg
            width="52"
            height="60"
            viewBox="0 0 52 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="omf-svg-logo"
        >
            <path
                d="M4.65625 9.74465C4.65625 9.74465 7.1618 16.4631 16.7717 16.4631C16.7717 16.4631 25.039 17.2243 30.4095 3.86665C30.4095 3.86665 25.4143 10.9816 21.3811 12.6202C21.3811 12.6202 22.5334 8.87246 20.4137 3.10547C20.4137 3.10547 19.8428 11.1771 19.1715 12.8105C19.1715 12.8105 14.5569 11.1771 13.0187 6.1819C13.0187 6.1819 14.0811 12.8105 15.6194 13.7726C15.6194 13.7726 10.8144 14.9302 4.66153 9.74465H4.65625Z"
                fill="#02A750"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.7383 55.56C13.6748 55.56 4.69923 47.1658 4.69923 36.8159C4.69923 26.466 13.6695 18.0719 24.7383 18.0719C35.8072 18.0719 44.7775 26.466 44.7775 36.8159C44.7775 41.3037 43.0912 45.4215 40.2791 48.6459H46.0091C48.2133 45.1836 49.4873 41.1398 49.4873 36.8159C49.4873 24.4996 39.1955 14.4245 26.2237 13.7109C20.943 18.056 16.3548 17.6331 16.3548 17.6331C14.6104 17.6331 13.0352 17.4745 11.6133 17.1997C4.64108 21.2963 0 28.5487 0 36.8159C0 49.5974 11.0794 59.9685 24.7436 59.9685C30.5635 59.9685 35.9129 58.0814 40.1417 54.9309H29.9027C28.2588 55.338 26.5303 55.56 24.7489 55.56H24.7383Z"
                fill="#ED1C24"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.5599 49.7915H36.2868C40.2301 46.7256 42.7462 42.0951 42.7462 36.9148C42.7462 27.6802 34.7644 20.1953 24.9219 20.1953C15.0795 20.1953 7.09766 27.6802 7.09766 36.9148C7.09766 46.1494 14.5932 53.1745 24.0233 53.6132L23.9599 53.6344H49.5652C50.6964 53.6344 51.6108 52.7727 51.6108 51.7155C51.6108 50.6584 50.6964 49.7967 49.5652 49.7967L49.5599 49.7915ZM33.9768 40.7736C32.243 40.3031 29.7956 38.6063 29.7903 36.7774C29.7903 34.8533 32.243 33.2887 34.0614 32.7812C39.3526 31.3117 39.4425 42.2537 33.9768 40.7736ZM28.7331 26.0205C29.748 28.5366 25.3025 36.2858 21.4966 29.7048C20.043 27.1887 20.3337 25.2381 22.8234 24.3554C25.0435 23.573 28.009 24.2232 28.7331 26.0205ZM20.783 45.4517C21.2535 43.7179 22.9503 41.2705 24.7792 41.2652C26.7033 41.2652 28.268 43.7179 28.7754 45.5363C30.2449 50.8275 19.303 50.9174 20.783 45.4517ZM17.0459 40.0653C14.5297 41.5189 12.5792 41.2282 11.6964 38.7385C10.9141 36.5184 11.5643 33.553 13.3615 32.8288C15.8777 31.8139 23.6269 36.2594 17.0459 40.0653Z"
                fill="#ED1C24"
            />
            <path
                d="M32.2099 3.22639C32.4636 2.4705 32.6116 1.68289 32.638 0.884709C32.638 0.800133 32.638 0.710272 32.6063 0.630982C32.5693 0.551692 32.49 0.493547 32.4055 0.504119C32.268 0.530549 32.2416 0.715558 32.2416 0.858279C32.2363 1.37102 32.0936 1.87319 31.9456 2.37007C31.9191 2.45993 31.8451 2.56565 31.7658 2.52336C31.7024 2.49164 31.7077 2.3965 31.7288 2.32778C31.787 2.10048 32.0619 0.92171 32.1042 0.456545C32.1094 0.403685 32.083 0.350826 32.0513 0.308538C32.0143 0.271536 31.9509 0.250392 31.9086 0.282108C31.8821 0.303252 31.8716 0.329682 31.861 0.361397C31.6654 0.905852 31.417 1.60889 31.2267 2.15334C31.2108 2.19034 31.2003 2.22735 31.1685 2.24849C31.1474 2.26435 31.121 2.26963 31.0998 2.26435C31.0998 2.26435 31.0787 2.25378 31.0681 2.25378C31.0575 2.25378 31.047 2.24849 31.0364 2.2432C31.0364 2.2432 30.9994 2.21677 30.9888 2.19034C30.9782 2.15334 30.9888 2.11634 31.0047 2.07934C31.2108 1.53488 31.4698 0.842421 31.6707 0.297966C31.6813 0.271536 31.6918 0.23982 31.6866 0.208104C31.676 0.155245 31.6126 0.128815 31.565 0.134101C31.5121 0.144673 31.4593 0.165817 31.4275 0.21339C31.1632 0.599266 30.6082 1.6776 30.5078 1.88376C30.4814 1.94719 30.4232 2.02648 30.3598 2.01062C30.2699 1.98948 30.2858 1.85733 30.3228 1.77804C30.5236 1.30759 30.7404 0.831849 31.0681 0.430115C31.158 0.31911 31.2584 0.160531 31.1685 0.0548112C31.1157 -0.0086205 31.01 -0.0139065 30.936 0.0230953C30.8619 0.0600971 30.8038 0.128815 30.7509 0.197532C30.2593 0.821277 29.8629 1.51903 29.5722 2.26435C29.4665 2.53393 29.3766 2.82466 29.3924 3.11539C29.4136 3.4114 29.551 3.68099 29.625 3.96114C29.7255 4.36816 29.6726 4.7699 29.551 5.16106C29.5405 5.20335 30.7457 4.73818 31.0892 4.49503C31.3271 4.32588 31.6073 4.20958 31.8081 3.99815C32.0143 3.79199 32.1253 3.50655 32.2204 3.23168L32.2099 3.22639Z"
                fill="#02A750"
            />
            <path
                d="M5.36822 10.9798C5.23607 10.9058 4.68104 10.874 4.51718 10.8318C4.21059 10.7525 3.87757 10.7525 3.57099 10.6626C3.16925 10.541 2.21778 10.2714 1.91119 9.99129C0.018816 8.25749 0.288401 6.45497 1.29274 5.70436C1.56761 5.49821 1.93234 5.51407 2.18078 5.53521C2.35522 5.54579 2.52965 5.58279 2.6988 5.63565C3.10582 5.75722 3.47584 5.97924 3.81943 6.2171C4.25817 6.52898 4.67576 6.89899 4.90306 7.38002C5.2625 8.16234 5.06163 9.09796 5.33122 9.91728C5.38408 10.0864 5.46337 10.2397 5.55323 10.393C5.91267 10.8053 5.66952 11.2441 5.37351 10.9798H5.36822Z"
                fill="#02A750"
            />
        </svg>
    );
};

export default OhMyFoodLogo;
