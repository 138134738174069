



import React from 'react';
import '../../../VendorStyles/vendorregistrationvalidation.css';
import '../../../VendorStyles/VendorRegistrationBG.css';

function EnterOTP({ formData,handleChange,errors }) {
   

    return (
        <div className='enter-otp-main-container'>
            <div className='plan-details-and-charges-cont'>
                <h5 className='plan-details-and-charges-text'>Plan Details and charges</h5>
                <div className='point-container'>
                    <div className='point'></div>
                    <p className='plan-details-and-charges-data-text'>$0 for 7 Days</p>
                </div>
                <div className='point-container'>
                    <div className='point'></div>
                    <p className='plan-details-and-charges-data-text'>10% commission per delivery order</p>
                </div>
            </div>
            <div className='enter-otp-bottom-main-container'>
                <div className='name-container-vendor'>
                    <h5 className='plan-details-and-charges-text'>Bank account Information</h5>
                    <label className='label-text-required' htmlFor='routingNumber'>Routing Number</label>
                    <input
                        className={`full-name-input ${errors.routingNumber ? 'error' : ''}`}
                        type='numeric'
                        id='routingNumber'
                        name='routingNumber'
                        placeholder='XXXXXXXXX'
                        value={formData.routingNumber}
                        onChange={handleChange}
                        // onBlur={handleValidation}
                        maxLength={9}
                        required
                    />
                    {errors.routingNumber && <p className='error-text'>{errors.routingNumber}</p>}
                </div>
                <div className='name-container-vendor'>
                    <label className='label-text-required' htmlFor='accountNumber'>Account Number</label>
                    <input
                        className={`full-name-input ${errors.accountNumber ? 'error' : ''}`}
                        type='numeric'
                        placeholder='XXXX'
                        id='accountNumber'
                        name='accountNumber'
                        value={formData.accountNumber}
                        maxLength={12}
                        onChange={handleChange}
                        // onBlur={handleValidation}
                        required
                    />
                    {errors.accountNumber && <p className='error-text'>{errors.accountNumber}</p>}
                </div>

                <div className='name-container-vendor'>
                    <label className='label-text-required' htmlFor='reEnterAccountNumber'>Re-enter Account Number</label>
                    <input
                        className={`full-name-input ${errors.reEnterAccountNumber ? 'error' : ''}`}
                        type='numeric'
                        placeholder='XXXX'
                        id='reEnterAccountNumber'
                        name='reEnterAccountNumber'
                        value={formData.reEnterAccountNumber}
                        maxLength={12}
                        onChange={handleChange}
                        // onBlur={handleValidation}
                        required
                    />
                    {errors.reEnterAccountNumber && <p className='error-text'>{errors.reEnterAccountNumber}</p>}
                </div>
            </div>
        </div>
    );
}

export default EnterOTP;

