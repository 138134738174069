import '../../VendorStyles/Dashboard.css';
import { useState,useEffect } from 'react';
import img1 from '../../assets/TotalOrders.png';
import img2 from '../../assets/Totaldelivered.png';
import img3 from '../../assets/cancelled.png';
import img4 from '../../assets/Totalrevenue.png';
import filtericon from '../../assets/Filtericon.png';
import { Button } from '@mui/material';
import axios from 'axios';


function Dashboard(){

    const vendor_Base_URL = process.env.REACT_APP_VENDOR_API_BASE_URL;
   
    const [isOpen, setIsOpen] = useState(false);

    const [orderData, setOrderData] = useState({
        totalOrders: 0,
        totalDelivered: 0,
        cancelledOrders: 0,
        totalRevenue: 0
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };


    // API call to fetch data
    useEffect(() => {
        const fetchOrderData = async () => {
            try {
                const response = await axios.get(`${vendor_Base_URL}/order/vendor/1?pageSize=10&pageNumber=1`); // Replace with your API endpoint
                
                const orders = response.data.allOrdersDetails;
                console.log(orders);
              
                
                // Calculate counts based on status_id
                const totalOrders = orders.filter(order => order.Status_id === 1).length;
                const cancelledOrders = orders.filter(order => order.Status_id === 3).length;
                const totalDelivered = orders.filter(order => order.Status_id === 6).length;

                // Calculate total revenue 
                const totalRevenue = orders
                    .filter(order => order.Status_id === 6)
                    .reduce((total, order) => total + parseFloat(order.Total_price),0);// Summing total_price for orders 

                

                setOrderData({
                    totalOrders: totalOrders || 0,
                    totalDelivered: totalDelivered || 0,
                    cancelledOrders: cancelledOrders || 0,
                    totalRevenue: totalRevenue || 0
                });
                setLoading(false);
            } catch (err) {
                setError('Failed to load data');
                setLoading(false);
            }
        };
        fetchOrderData();
    }, []);


    const Orders = [
        { image:img1,
            count:orderData.totalOrders.toString() ,
            type:'Total Orders'        },
        { image:img2,
            count:orderData.totalDelivered.toString() ,
            type:'Total Delivered'        },
        { image:img3,
            count:orderData.cancelledOrders.toString(),
            type:'Cancelled'       },
        { image:img4,
            count: `$${orderData.totalRevenue}`, 
            type:'Total Revenue'        }
    ];


    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return(
        <div className="dashboard-main-container">
            <div className="dashboard-top1-container">
                <div className="dashboard-new-orders-container">
                    <div className="dashboard-new-orders-count-container">
                        <p className="dashboard-new-orders-count">14</p>
                    </div>
                
                    <div className='dashboard-new-orders-and-manage-orders'>
                        <p className="dashboard-new-orders-text">New Orders</p>
                        <button className="dashboard-manage-orders-btn">Manage Orders</button>
                    </div>
                </div>
            
            
                <div className='dashboard-toggle-and-filter'>
                    <div className={`dashboard-toggle-switch ${isOpen ? 'open' : 'close'}`} onClick={handleToggle}>
                        <div className="dashboard-toggle-switch-inner">
                            <div className="dashboard-toggle-switch-circle" style={{ right: isOpen ? 0 : 'auto', left: isOpen ? 'auto' : 0 }}></div>
                        </div>
                        <div className={`dashboard-toggle-state-text ${isOpen ? 'open' : 'close'}`}>
                            {isOpen ? 'Open' : 'Close'}
                        </div>
                    </div>
                    <Button className='dashboard-filter'
                        startIcon={<img src={filtericon} width="20px" height="18px" alt="filter icon" />}
                        sx={{
                            borderRadius: '22px',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            backgroundColor: '#FFFFFF',
                            color: '#50555C',
                            height: '35px',
                            border:'#000',
                            boxShadow: '0px 0px 4px 0px #0000001A',
                            width:'90px', 
                            
                    
                        }}
                    >
                        Filter
                    </Button>
                </div>
            </div>
            <div className='dashboard-bottom-container'>
                {Orders.map((order,index) => (
                    <div key={index} className='dashboard-bottom-single-container'>
                        <img src={order.image} className='dashboard-order-img'></img>
                        <h5 className='dashboard-order-count'>{order.count}</h5>
                        <h5 className='dashboard-order-type'>{order.type}</h5>
                    </div>
                ))}
            </div>

            
        </div>
    );
}

export default Dashboard;