import { HashLoader } from 'react-spinners';
import './Loader.css';

const Loader = () => {
    return (
        <div className="overlay">
            <HashLoader
                color="#c41f1f"
                loading={true}
                className="loader-div "
                size={50}
            />
        </div>
    );
};
export default Loader;