import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './i18n';

import LandingPage from '../src/LandingPage/LandingPage';

import Dashboard from './Views/User/Dashboard/Dashboard';

import Reels from './Views/User/Reels/Reels';

import Cart from './Views/User/Cart/cart';

import Profilescreen from './Views/User/profilepage/Profilescreen';

import Profilesettings from './Views/User/profilepage/Profilesettings';

import PostDetail from './Views/User/profilepage/PostDetail';

import ReelDetail from './Views/User/profilepage/ReelDetail';

import SavedItemDetail from './Views/User/profilepage/SavedItemDetail';

import Inbox from './Views/User/Inbox/Inbox';

import Payment from './Views/User/Payment/Payment';

import PaymentSuccess from './Views/User/Payment/PaymentSuccess';

import PaymentFail from './Views/User/Payment/PaymentFail';

import GoogleMap from './Views/User/Tracking/GoogleMap';

import QRCodeScanner from './Views/User/QR Code Scanner/QRCodeScanner';

import RestaurantPage from './Views/User/Reasaurant page/RestaurantPage';

import FoodDescription from './Views/User/Reasaurant page/FoodDescription';

import Editprofile from './Views/User/profilepage/Editprofile';

import ChangePassword from './Views/User/profilepage/Change Password';

import Changelanguage from './Views/User/profilepage/changelanguage';

import UpdateAddresses from './Views/User/profilepage/Update Addresses';

import Switchaccount from './Views/User/profilepage/Switchaccount';

import Tabs from './Views/User/Search/Tabs';

import Foods from './Views/User/Search/Food';

import Restaurants from './Views/User/Search/Restaurant';

import Post from './Views/User/Search/Post';

import Blogger from './Views/User/Search/Blogger';

import Offer from './Views/User/Search/Offer';

import UserRoleSelection from './auth/UserRoleSelection/UserRoleSelection';

import PageNotFound from './Views/User/PageNotFound/PageNotFound';

import Signup from './auth/Signup/Signup';
import Login from './auth/Login/Login';
import VerifyOTP from './auth/VerifyOTP/VerifyOTP';
import ForgetPassword from './auth/ForgetPassword/ForgetPassword';
import ResetPassword from './auth/ResetPassword/ResetPassword';


import PrivateRoute from './PrivateRoute';
import ViewMenu from './Views/User/ViewMenu/ViewMenu';


function Userrouter() {
    const [tabName, setTabName] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [isReset, setReset] = useState(null);

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LandingPage></LandingPage>}></Route>
                    <Route path='/signup' element={<Signup></Signup>}></Route>
                    <Route path='/login' element={<Login/>}></Route>
                    <Route path='/verify-otp' element={<VerifyOTP setReset={setReset} isReset={isReset}/>}></Route>
                    <Route path='/forget-password' element={<ForgetPassword setReset={setReset} isReset={isReset}/>}></Route>
                    <Route path='/reset-password' element={<ResetPassword/>}></Route>
                    <Route
                        path="/Partner-with-us"
                        element={<UserRoleSelection></UserRoleSelection>}
                    ></Route>

                    <Route path="/Dashboard" element={<Dashboard />}></Route>
                    <Route path="/reels" element={<Reels />}></Route>
                    <Route path='/restaurant/:id' element={<ViewMenu />}></Route>
                    <Route
                        path="/Inbox"
                        element={
                            <PrivateRoute>
                                <Inbox></Inbox>
                            </PrivateRoute>
                        }
                    ></Route>

                    <Route path="/Cart" element={<Cart></Cart>}></Route>
                    <Route path="/Payment" element={<Payment></Payment>}></Route>
                    <Route
                        path="/PaymentSuccess"
                        element={<PaymentSuccess></PaymentSuccess>}
                    ></Route>
                    <Route
                        path="/PaymentFail"
                        element={<PaymentFail></PaymentFail>}
                    ></Route>
                    <Route path="/GoogleMap" element={<GoogleMap></GoogleMap>}></Route>
                    <Route
                        path="/QRCodeScanner"
                        element={<QRCodeScanner></QRCodeScanner>}
                    ></Route>
                    <Route
                        path="/RestaurantPage"
                        element={<RestaurantPage></RestaurantPage>}
                    ></Route>
                    <Route
                        path="/FoodDescription"
                        element={<FoodDescription></FoodDescription>}
                    ></Route>

                    <Route
                        path="/Profilescreen"
                        element={<Profilescreen></Profilescreen>}
                    ></Route>
                    <Route
                        path="/profilesettings"
                        element={<Profilesettings></Profilesettings>}
                    ></Route>

                    <Route path="/post/:postId" element={<PostDetail />} />
                    <Route path="/reel/:reelId" element={<ReelDetail />} />
                    <Route path="/saved/:savedId" element={<SavedItemDetail />} />

                    <Route path="/edit-profile" element={<Editprofile />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/change-language" element={<Changelanguage />} />
                    <Route path="/update-addresses" element={<UpdateAddresses />} />
                    <Route path="/switch-account" element={<Switchaccount />} />

                    <Route
                        path="/Search"
                        element={
                            <Tabs
                                tabName={tabName}
                                setTabName={setTabName}
                                searchQuery={searchQuery}
                                setSearchQuery={setSearchQuery}
                            />
                        }
                    />
                    <Route path="/Foods" element={<Foods />} />
                    <Route path="/Restaurants" element={<Restaurants />} />
                    <Route path="/Post" element={<Post />} />
                    <Route path="/Blogger" element={<Blogger />} />
                    <Route path="/Offer" element={<Offer />} />

                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default Userrouter;
