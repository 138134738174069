import React from 'react';
import { Navigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';


const PrivateRoute = ({ children }) => {
    const { keycloak,initialized } = useKeycloak();


    // Wait for Keycloak initialization
    if (!initialized) {
        return <div style={{display:'flex', justifyContent:'center', alignItems:'center', fontWeight:600 }}>Loading...</div>; // Show a loader while Keycloak initializes
    }


    return keycloak.authenticated ? (
        
        children

       
       
    ) : (
        <Navigate to="/" replace />
    );
};

export default PrivateRoute;
