import React, { useEffect, useState } from 'react';
import '../Styles/Reels/ReelsRight.css';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import starPic from './../assets/Star.png';
import redDot from './../assets/reddot.png';

import img1 from '../assets/Image1.png';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Link, useNavigate } from 'react-router-dom';
import { ActionTypes } from '../Redux/Contants/action-types';

import Share from '../Views/User/Reels/Share';
import Comments from '../Views/User/Reels/Comments';
// import ThreeDots from '../Views/User/Reels/ThreeDots';

import FavoriteIcon from '@mui/icons-material/Favorite';
import { toast } from 'react-toastify';
import axios from 'axios';

import { useCart } from '../CartProvider';
import ConfirmationModal from './ConfirmationModal'; // Import the modal component
import { jwtDecode } from 'jwt-decode';

function ReelsRight(props) {
    const { fetchCartCount } = useCart();

    const base_URL = process.env.REACT_APP_API_BASE_URL;
    // const base_URL = 'http://localhost:5002/api';

    const vendor_Base_URL = process.env.REACT_APP_VENDOR_API_BASE_URL;

    // const vendor_Base_URL = 'http://localhost:5003/api';

    const {
        id,
        vendorID,
        restaurantName,
        // likes,
        isLike,
        setIsLike,
        getLikes,
        likesCount,
        commentsCount,
        // setCommentsCount,
        commentsList,
        setCommentsList,
        getComments,
        // setLike,
        handleMute,
        // shares,
        // comments,
        // likes,
        // order,
        // video,
        isVideoFile,
        url,
        globalMuteIcon,
        toggleMuteIcon,
        restaurant,
    } = props;

    const navigate = useNavigate();

    const { t } = useTranslation();

    const [isModalOpen, setModalOpen] = useState(false);
    const [modalResolve, setModalResolve] = useState(null);

    const showConfirmationPopup = () => {
        return new Promise((resolve) => {
            setModalOpen(true);
            setModalResolve(() => resolve); // Store resolve function for later
        });
    };

    const handleCloseModal = (confirmed) => {
        setModalOpen(false);
        if (modalResolve) modalResolve(confirmed); // Resolve with user choice
        setModalResolve(null);
    };

    const handleConfirmModal = async () => {
        handleCloseModal(true); // Confirm and close modal
    };

    const [data, setData] = useState([]);
    // console.log(data);

    // const [previousVendorId, setPreviousVendorId] = useState(null);
    // console.log(previousVendorId);

    const [cartCount, setCartCount] = useState(0);

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openShare, setOpenShare] = useState(false);
    const handleOpenShare = () => setOpenShare(true);
    const handleCloseShare = () => setOpenShare(false);

    const [showCommentPopup, setShowCommentPopup] = useState(false);
    const handleOpenCommentPopup = () => setShowCommentPopup(true);
    const handleCloseCommentPopup = () => setShowCommentPopup(false);

    // const [showSave, setShowSave] = useState(false);
    // const handleOpenSavePopup = () => setShowSave(true);
    // const handleCloseSavePopup = () => setShowSave(false);

    const [restaurantsDetails, setRestaurantsDetails] = useState([]);

    const [filters, setFilters] = useState([]); // State to store filter tabs

    const [filteredItems, setFilteredItems] = useState([]);

    const [selectedFilter, setSelectedFilter] = useState('1'); // Default filter (e.g., starters)


    const [isFilled, setIsFilled] = useState(false);
    const token = localStorage.getItem('authToken'); 
    const decodedToken = jwtDecode(token);

    const handleIconClick = async () => {
        const saveData = {
            User_id: decodedToken?.id,
            Post_id: id,
            Created_by: 1,
            Updated_by: 1
        };

        const unsaveData = {
            User_id: decodedToken?.id,
            Post_id: id,
        };

        if (isFilled) {
            try {
                
                const res = await axios.delete(`${base_URL}/post/unsavepost`, {
                    data: unsaveData,
                });
                  
                console.log('resapi-unsave',res);
                
                setIsFilled(!isFilled);
                if (res?.statusText === 'OK') {
                    toast.success(res?.data?.message);
                }
            } catch (error) {
                console.log('error',error);
               
                toast.error(error?.response?.data?.error);
                console.error(error.response?.data.error || 'Unable to connect to the server.');
            }
        } else {
            try {
                
                const res = await axios.post(`${base_URL}/post/savepost`, saveData);
                console.log('resapi-save',res);
                
                if (res?.statusText === 'Created') {
                    setIsFilled(!isFilled);
                    toast.success(res?.data?.message);
                }
            } catch (error) {
                console.log('error',error);
               
                toast.error(error?.response?.data?.error);
                console.error(error.response?.data.error || 'Unable to connect to the server.');
            }
            
        }
    };

    useEffect(() => {
        axios
            .get(`${vendor_Base_URL}/vendors/1`) // Replace with your actual API endpoint for filters
            .then((response) => {
                setFilters(response.data);
            })
            .catch((error) => {
                console.error('Error fetching filters:', error);
            });
    }, []);

    // Fetch items based on the selected filter
    useEffect(() => {
        axios
            .get(`${vendor_Base_URL}/vendors/menuitems/1/menu/${selectedFilter}`) // Fetch items based on selected filter
            .then((response) => {
                setFilteredItems(response.data.menuitems);
            })
            .catch((error) => {
                console.error('Error fetching filtered items:', error);
            });
    }, [selectedFilter]); // Fetch new items whenever the selectedFilter changes

    const handleOrderClick = (restaurantName) => {
    // Find the post that matches the restaurant name
        const filteredPost = restaurant?.find((post) =>
            post.Restaurant_Info.find(
                (restaurant) => restaurant.Vendor_Name === restaurantName
            )
        );

        // setPreviousVendorId(vendorID);

        // If we found a post that matches the restaurant name
        if (filteredPost) {
            // Now retrieve the menu items from the matching post
            const menuItems = filteredPost.Menu_items_Info;

            // Store the menu items in the state
            setFilteredItems(menuItems);

            // Retrieve the restaurant info for the specific restaurant
            const restaurantInfo = filteredPost.Restaurant_Info.find(
                (restaurant) => restaurant.Vendor_Name === restaurantName
            );

            // Store the restaurant info in the state
            setRestaurantsDetails([restaurantInfo]);

            // Log for debugging
            console.log(menuItems);
        } else {
            console.log('No matching restaurant found.');
        }

        // Open the order modal or handle further actions
        handleOpen();
    };

    const fetchDataFromCart = async () => {
    // Fetch item data from the API using the itemID
        const itemResponse = await axios.get(`${base_URL}/cartdetails/cartsp/1`);
        // console.log(
        //     itemResponse.data.cartDetails.cartDetails[0].result[0]?.Cart_Information
        // );
        //     const data =
        //   itemResponse.data.cartDetails.cartDetails[0].result[0]?.Cart_Information;

        const data =
        itemResponse.data.cartDetails[0].Cart_Information;

        setData(data);
        // setCartCount(
        //     itemResponse.data.cartDetails.cartDetails[0].result[0]?.Cart_Information
        //         ?.length
        // );

        setCartCount(
            itemResponse.data.cartDetails[0].Cart_Information?.length
        );
    };

    useEffect(() => {
        fetchDataFromCart();
    }, []);

    const addToCart = async (itemID, restaurantID) => {
        console.log(restaurantID);

        console.log(itemID);

        

        try {
            const differentRestaurantItem = data?.some(
                (cartItem) => cartItem.Vendor_Id !== restaurantID
            );

            if (differentRestaurantItem) {
                const confirmed = await showConfirmationPopup(
                    'Your cart contains items from other restaurant. Would you like to reset your cart for adding items from this restaurant?'
                );

                if (!confirmed) return;

                // Clear cart if confirmed
                await axios.delete(`${base_URL}/cartdetails/customer/1`);
                await fetchDataFromCart();
                await fetchCartCount();
            }

            const isItemInCart = data?.find(
                (cartItem) => cartItem.Item_ID === parseInt(itemID)
            );

            console.log(isItemInCart);

            if (isItemInCart) {
                toast.info('Item is already in the cart');
                return;
            }

            const itemIdToSend = {
                Item_id: itemID,
                Quantity: 1,
            };

            await axios.post(
                `${base_URL}/cartdetails/customer/1/vendor/${vendorID}/item/${itemID}`,
                itemIdToSend
            );

            await fetchDataFromCart();
            await fetchCartCount();

            toast.success('Item Added Successfully');

            dispatch({
                type: ActionTypes.SELECTED_VENDOR_ID,
                payload: vendorID,
            });
        } catch (error) {
            toast.error('failed to add item');
        }
    };

    const handleFilterClick = (filter) => {
        setSelectedFilter(filter);
    };

    const handleLike = async () => {
        const LikePost = [
            {
                Post_id: id,
                Account_id: 3,
                Created_at: 23 - 10 - 2023,
                Created_by: 3,
            },
        ];

        if (!isLike) {
            // try {
            await axios.post(
                `${base_URL}/customers/likes/account/3/post/${id}`,
                LikePost
            );
            setIsLike(true); // Set to true to show red button
            console.log('liked post');

            await getLikes();
        } else {
            await axios.delete(`${base_URL}/customers/likes/${id}/3`);

            console.log('like deleted');
            setIsLike(false);

            await getLikes();
        }
    };

    const handleMoreInfo = (data) => {
        dispatch({
            type: ActionTypes.SELECTED_ITEM_DATA,
            payload: data,
        });

        navigate('/FoodDescription');
    };

    // console.log(restaurant);

    return (
        <div>
            <div className="right-container">
                {isVideoFile(url) && (
                    <div>
                        {globalMuteIcon ? (
                            <svg
                                width="100"
                                height="100"
                                viewBox="0 0 100 100"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                size={30}
                                className="mute"
                                onClick={() => {
                                    handleMute();
                                    toggleMuteIcon();
                                }}
                            >
                                <g clipPath="url(#clip0_3730_3217)">
                                    <rect width="100" height="100" fill="white" />
                                    <circle cx="50" cy="50" r="50" fill="#FEEEEE" />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M53.7789 26.0712C52.9424 26.222 52.1289 26.6142 51.4721 27.2773L51.6542 27.1131L34.9337 40.1938H27.8276C24.6322 40.1938 22 42.8512 22 46.0772V53.9218C22 57.1478 24.6322 59.8052 27.8276 59.8052H34.9337L51.6542 72.8859L51.4721 72.7217C52.7857 74.0479 54.7246 74.3004 56.218 73.6753C57.7113 73.0502 58.9084 71.4874 58.9084 69.6109V30.3882C58.9084 28.5128 57.7125 26.9488 56.218 26.3237C55.4713 26.0111 54.6142 25.9204 53.7789 26.0712ZM54.7307 29.9469C54.9177 30.0253 55.0233 30.0928 55.0233 30.3869V69.6096C55.0233 69.905 54.9177 69.9724 54.7307 70.0497C54.5437 70.1281 54.4248 70.1551 54.2184 69.9467C54.1601 69.8879 54.0982 69.8327 54.0326 69.7825L36.7889 56.2923C36.4477 56.0263 36.0288 55.8817 35.5978 55.8829H27.8276C26.7313 55.8829 25.8851 55.0286 25.8851 53.9218V46.0772C25.8851 44.9704 26.7313 44.1161 27.8276 44.1161H35.5978C36.0288 44.1161 36.4477 43.9727 36.7889 43.7067L54.0326 30.2166C54.0982 30.1651 54.1601 30.1111 54.2184 30.0523C54.4248 29.8439 54.5437 29.8709 54.7307 29.9494V29.9469Z"
                                        fill="#E13535"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M65.0711 42.4922C64.5564 42.4922 64.0622 42.6993 63.698 43.067C62.9392 43.8331 62.9392 45.0748 63.698 45.8396L67.8186 49.9997L63.698 54.1597C62.9392 54.9258 62.9392 56.1674 63.698 56.9323C64.4568 57.6983 65.6867 57.6983 66.4443 56.9323L70.5649 52.7722L74.6855 56.9323C75.4444 57.6983 76.6742 57.6983 77.4318 56.9323C78.1906 56.1662 78.1906 54.9246 77.4318 54.1597L73.3112 49.9997L77.4318 45.8396C78.1906 45.0735 78.1906 43.8319 77.4318 43.067C77.0676 42.6993 76.5735 42.4922 76.0587 42.4922C75.5439 42.4922 75.0498 42.6993 74.6855 43.067L70.5649 47.2271L66.4443 43.067C66.0801 42.6993 65.5859 42.4922 65.0711 42.4922Z"
                                        fill="#E13535"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_3730_3217">
                                        <rect width="100" height="100" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        ) : (
                            <svg
                                width="100"
                                height="100"
                                viewBox="0 0 100 100"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                size={30}
                                className="mute"
                                onClick={() => {
                                    handleMute();
                                    toggleMuteIcon();
                                }}
                            >
                                <g clipPath="url(#clip0_3726_3204)">
                                    <rect width="100" height="100" fill="white" />
                                    <circle cx="50" cy="50" r="50" fill="#E4EDF9" />
                                    <g clipPath="url(#clip1_3726_3204)">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M54.04 26.0727C53.1966 26.2266 52.3765 26.627 51.7143 27.3039L51.8979 27.1362L35.04 40.4895H27.8755C24.6538 40.4895 22 43.2022 22 46.4955V54.5035C22 57.7968 24.6538 60.5094 27.8755 60.5094H35.04L51.8979 73.8627L51.7143 73.6951C53.0387 75.0489 54.9936 75.3067 56.4992 74.6685C58.0048 74.0304 59.2117 72.4351 59.2117 70.5194V30.4796C59.2117 28.5652 58.006 26.9686 56.4992 26.3304C55.7464 26.0114 54.8822 25.9188 54.04 26.0727ZM54.9997 30.0291C55.1882 30.1092 55.2947 30.178 55.2947 30.4783V70.5182C55.2947 70.8197 55.1882 70.8885 54.9997 70.9674C54.8112 71.0474 54.6912 71.075 54.4831 70.8623C54.4244 70.8022 54.3619 70.7459 54.2958 70.6946L36.9104 56.9234C36.5664 56.6519 36.1441 56.5042 35.7096 56.5055H27.8755C26.7702 56.5055 25.917 55.6334 25.917 54.5035V46.4955C25.917 45.3656 26.7702 44.4935 27.8755 44.4935H35.7096C36.1441 44.4935 36.5664 44.3471 36.9104 44.0756L54.2958 30.3044C54.3619 30.2518 54.4244 30.1968 54.4831 30.1367C54.6912 29.924 54.8112 29.9515 54.9997 30.0316V30.0291Z"
                                            fill="#2C3142"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M64.3534 45.0396C63.5113 45.5789 63.2555 46.7138 63.783 47.5746C64.3192 48.4517 64.6019 49.4653 64.6019 50.4988C64.6019 51.5323 64.3192 52.5458 63.783 53.4229C63.2555 54.2838 63.5088 55.4187 64.351 55.9592C65.1932 56.4985 66.3034 56.237 66.831 55.3761C66.831 55.3761 66.831 55.3749 66.8322 55.3736C67.7258 53.9122 68.1995 52.2217 68.1995 50.4988C68.1995 48.7758 67.7258 47.0854 66.8322 45.6239C66.3058 44.7618 65.1956 44.4991 64.3534 45.0371C64.3534 45.0371 64.3522 45.0371 64.351 45.0383L64.3534 45.0396Z"
                                            fill="#2C3142"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M70.2917 41.8825C69.8266 41.7736 69.3394 41.8574 68.9354 42.1164C68.0933 42.6545 67.8374 43.7881 68.3626 44.649C69.4361 46.4032 70.0041 48.4327 70.0041 50.501C70.0041 52.5693 69.4361 54.5989 68.3626 56.3531C67.8374 57.214 68.0933 58.3476 68.9354 58.8856C69.7776 59.4224 70.8866 59.1609 71.413 58.3C72.8439 55.9615 73.6028 53.2588 73.6028 50.501C73.6028 47.7433 72.8439 45.0406 71.413 42.702C71.1608 42.2879 70.7569 41.9926 70.2917 41.8825Z"
                                            fill="#2C3142"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M74.8696 38.9585C74.4045 38.8472 73.9149 38.9298 73.5097 39.1888C72.6675 39.7281 72.4117 40.8629 72.9393 41.7238C74.5477 44.3552 75.3997 47.3944 75.3997 50.4975C75.3997 53.6006 74.5477 56.6399 72.9393 59.2712C72.4117 60.1321 72.6651 61.267 73.5072 61.8075C74.3494 62.3468 75.4596 62.0853 75.9872 61.2244C75.9872 61.2244 75.9872 61.2232 75.9884 61.2219C77.9543 58.0062 78.9972 54.29 78.9972 50.4975C78.9972 46.705 77.9543 42.9888 75.9884 39.7731C75.735 39.3602 75.3323 39.0674 74.8672 38.9573L74.8696 38.9585Z"
                                            fill="#2C3142"
                                        />
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_3726_3204">
                                        <rect width="100" height="100" fill="white" />
                                    </clipPath>
                                    <clipPath id="clip1_3726_3204">
                                        <rect
                                            width="57"
                                            height="49"
                                            fill="white"
                                            transform="translate(22 26)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        )}
                    </div>
                )}

                <div className="icons">
                    <div className="svg-text-container">
                        <FavoriteIcon
                            fontSize="large"
                            className="like-svg-icon"
                            style={{
                                color: isLike ? 'red' : 'white',
                            }}
                            onClick={() => {
                                handleLike();
                            }}
                        ></FavoriteIcon>

                        <p className="paragraph-color">{likesCount}</p>
                    </div>

                    <div className="svg-text-container">
                        <svg
                            width="30"
                            height="31"
                            viewBox="0 0 30 31"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="comment-svg-icon"
                            onClick={() => handleOpenCommentPopup()}
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.1052 2.38462C8.36574 2.38462 2.90232 8.25657 2.90232 15.5C2.90232 18.6 3.25135 20.5865 4.71676 22.6834C4.93745 22.9991 4.99833 23.4118 4.87933 23.7856C4.53748 24.859 4.02839 26.4606 3.60469 27.7943L3.51421 28.0792L4.46982 27.8046C6.01314 27.3611 7.67541 26.883 8.15996 26.7425C8.44502 26.6598 8.74899 26.7026 9.00457 26.8616C10.7983 27.9768 12.8807 28.6154 15.1052 28.6154C21.8448 28.6154 27.3081 22.7433 27.3081 15.5C27.3081 8.25657 21.8448 2.38462 15.1052 2.38462ZM1.79296 29.8077C0.743053 29.4227 0.743068 29.4227 0.743068 29.4225L1.50502 27.0237C1.85782 25.9133 2.27001 24.6164 2.5956 23.5931C1.01774 21.0903 0.683606 18.6248 0.683606 15.5C0.683606 6.93958 7.14038 0 15.1052 0C23.0701 0 29.5268 6.93958 29.5268 15.5C29.5268 24.0605 23.0701 31 15.1052 31C12.6472 31 10.3301 30.3382 8.30271 29.1705C7.57476 29.3805 6.26953 29.7559 5.04291 30.1083L2.08033 30.9593L1.79296 29.8077ZM1.79296 29.8077L2.08033 30.9593C1.68469 31.073 1.26196 30.9432 0.982378 30.6216C0.702805 30.3002 0.610818 29.8392 0.743068 29.4225L1.79296 29.8077Z"
                                fill="white"
                            />
                        </svg>
                        {showCommentPopup && (
                            <Comments
                                id={id}
                                open={showCommentPopup}
                                handleClose={handleCloseCommentPopup}
                                commentsList={commentsList}
                                setCommentsList={setCommentsList}
                                getComments={getComments}
                            />
                        )}

                        <p className="paragraph-color">{commentsCount}</p>
                    </div>
                    <div className="svg-text-container">
                        <svg
                            width="34"
                            height="29"
                            viewBox="0 0 34 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="share-svg-icon"
                            onClick={() => {
                                handleOpenShare();
                            }}
                        >
                            <path
                                d="M32.1736 8.81735L24.8531 0.975172C24.2491 0.328102 23.6364 0 23.0322 0C22.2026 0 21.2338 0.675962 21.2338 2.58104V5.25092C15.9152 5.49925 10.9484 7.83588 7.16018 11.8939C3.14782 16.1918 0.937942 21.9065 0.9375 27.9849C0.937437 28.4216 1.19824 28.8095 1.58492 28.9477C1.68358 28.9829 1.7847 29 1.88482 29C2.17707 29 2.45998 28.8547 2.64245 28.5946C7.11546 22.2163 13.8499 18.4469 21.2338 18.1492V20.7779C21.2338 22.6829 22.2025 23.3589 23.0321 23.359H23.0322C23.6364 23.359 24.2491 23.0309 24.8531 22.3838L32.1735 14.5416C32.8851 13.7795 33.2769 12.7631 33.2769 11.6795C33.2769 10.5959 32.8851 9.57945 32.1736 8.81735ZM30.8337 13.1062L23.5132 20.9485C23.3728 21.099 23.2565 21.194 23.1708 21.2517C23.1482 21.1459 23.1287 20.9906 23.1287 20.778V17.1152C23.1287 16.5547 22.7045 16.1003 22.1812 16.1003C17.9716 16.1003 13.9491 17.1335 10.2256 19.1711C7.55254 20.634 5.10903 22.6031 3.03975 24.9482C4.41632 14.9545 12.476 7.25878 22.1812 7.25878C22.7045 7.25878 23.1287 6.80435 23.1287 6.24382V2.58104C23.1287 2.36837 23.1482 2.21308 23.1707 2.10739C23.2565 2.16511 23.3728 2.26011 23.5132 2.41053L30.8337 10.2528C31.1873 10.6315 31.382 11.1382 31.382 11.6795C31.382 12.2208 31.1873 12.7275 30.8337 13.1062Z"
                                fill="white"
                            />
                        </svg>
                        {openShare && (
                            <Share handleClose={handleCloseShare} open={openShare} url={url}/>
                        )}

                        <div>
                            <div
                                className="three-lines-reels"
                                // onClick={() => handleOpenSavePopup()}
                            >
                                {/* <svg aria-label="Save" className="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="30" role="img" viewBox="0 0 24 24" width="30"  fill={isFilled ? 'white' : 'currentColor'} onClick={handleIconClick}
                                    style={{ cursor: 'pointer' }}><title>Save</title><polygon fill="none" points="20 21 12 13.44 4 21 4 3 20 3 20 21" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></polygon></svg> */}
                                <svg
                                    aria-label="Save"
                                    className="x1lliihq x1n2onr6 x5n08af"
                                    // fill={isFilled ? 'white' : 'currentColor'}
                                    height="30"
                                    role="img"
                                    viewBox="0 0 24 24"
                                    width="30"
                                    onClick={handleIconClick}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <title>Save</title>
                                    <polygon
                                     
                                        points="20 21 12 13.44 4 21 4 3 20 3 20 21"
                                        fill={isFilled ? 'white' : 'none'}
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                    ></polygon>
                                </svg>
                            </div>

                            {/* {showSave && (
                                <ThreeDots
                                    open={showSave} 
                                    handleClose={handleCloseSavePopup}
                                />
                            )} */}
                        </div>
                    </div>

                    <button
                        className="order-btn"
                        onClick={() => handleOrderClick(restaurantName)}
                    >
            Order
                    </button>
                </div>
            </div>

            <div>
                {restaurantsDetails.map((restaurant, index) => {
                    console.log(restaurant);
                    return (
                        <Modal
                            key={index}
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box className="style">
                                <div className="hide-horizantal-line">
                                    <div className="horizantal-line-mobile"></div>
                                </div>

                                <div>
                                    
                                    <div className="restaurant-tittle-container">
                                        <h1 className="order-restaurant-name">
                                            {restaurant.Vendor_Name}
                                        </h1>

                                        <div className="res-star-rating">
                                            <div className="img-rating-restaurant">
                                                <img
                                                    className="star-img-order"
                                                    src={starPic}
                                                    alt="star"
                                                />
                                                <p className="rating-order">
                                                    {restaurant.Vendor_Rating}
                                                </p>
                                            </div>
                                            <p className="reviews-restaurant">
                                                {restaurant.Vendor_Rating}
                                                {t('reelsright.reviews-text')}
                                            </p>
                                        </div>

                                        <svg
                                            width="25"
                                            height="18"
                                            viewBox="0 0 25 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="cross-icon-order"
                                            onClick={handleClose}
                                        >
                                            <path
                                                d="M14.7904 12.5002L24.5249 2.76519C25.1584 2.13197 25.1584 1.10814 24.5249 0.474916C23.8917 -0.158305 22.8679 -0.158305 22.2346 0.474916L12.4999 10.2099L2.76535 0.474916C2.13185 -0.158305 1.10834 -0.158305 0.475128 0.474916C-0.158376 1.10814 -0.158376 2.13197 0.475128 2.76519L10.2096 12.5002L0.475128 22.2352C-0.158376 22.8684 -0.158376 23.8922 0.475128 24.5255C0.790694 24.8413 1.20562 25 1.62024 25C2.03487 25 2.44949 24.8413 2.76535 24.5255L12.4999 14.7905L22.2346 24.5255C22.5505 24.8413 22.9651 25 23.3798 25C23.7944 25 24.209 24.8413 24.5249 24.5255C25.1584 23.8922 25.1584 22.8684 24.5249 22.2352L14.7904 12.5002Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>

                                    <p className="rest-description">
                                        {restaurant.Vendor_Description}
                                    </p>

                                    <div className="filters-scroll">
                                        <div className="filters">
                                            {filters.map((filter) => (
                                                <p
                                                    key={filter.ID}
                                                    className={`bg-css ${
                                                        selectedFilter === filter.Status ? '' : ''
                                                    }`}
                                                    onClick={() => handleFilterClick(filter.Status)}
                                                >
                                                    {filter.Menu_name}
                                                </p>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="scrollable-container">
                                        {filteredItems?.map((data) => {
                                            const shortDescription = data.Menu_item_Description
                                                ? data.Menu_item_Description.slice(0, 60)
                                                : 'No description available'; // Truncate description

                                            return (
                                                <>
                                                    <div
                                                        className="item-container-order"
                                                        key={data.Menu_item_ID}
                                                    >
                                                        <div>
                                                            <img
                                                                className="item-img-order"
                                                                src={img1}
                                                                alt="item"
                                                            />
                                                        </div>
                                                        <div className="item-details-order">
                                                            <div className="item-row1">
                                                                <div className="reddot-iten-name">
                                                                    <img
                                                                        className="item-reddot-order"
                                                                        src={redDot}
                                                                        alt="reddot"
                                                                    />
                                                                    <h5 className="items-name-order">
                                                                        {data.Menu_item_Name}
                                                                    </h5>
                                                                </div>
                                                                <p className="item-cost-order">
                                  $ {data.Menu_item_Price}
                                                                </p>
                                                            </div>

                                                            <div className="item-row2">
                                                                <div className="star-rating-item">
                                                                    <img
                                                                        className="item-star-order"
                                                                        src={starPic}
                                                                        alt="star"
                                                                    />
                                                                    <p className="item-rating-order">4.5</p>
                                                                </div>

                                                                <p className="item-review-order">
                                  31 {''}
                                                                    {t('reelsright.reviews-txt')}
                                                                </p>
                                                            </div>

                                                            <div className="desc-add-item">
                                                                <p className="item-desc-order">
                                                                    {shortDescription}...
                                                                    <button
                                                                        onClick={() => handleMoreInfo(data)}
                                                                        className="more-btn"
                                                                    >
                                    More
                                                                    </button>
                                                                </p>

                                                                <button
                                                                    className="item-add-btn"
                                                                    onClick={() => {
                                                                        addToCart(data.Menu_item_ID, vendorID);
                                                                    }}
                                                                >
                                                                    {t('reelsright.add-button')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>

                                    {cartCount > 0 && (
                                        <div className="red-box-review-cart">
                                            <h5>
                                                {cartCount} {t('reelsright.item-added-title')}
                                            </h5>
                                            <Link className="review-cart-link" to="/cart">
                                                {t('reelsright.review-cart-title')}
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </Box>
                        </Modal>
                    );
                })}

                <ConfirmationModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    onConfirm={handleConfirmModal}
                    message="Your cart contains items from other restaurant. Would you like to reset your cart for adding items from this restaurant?"
                />
            </div>
        </div>
    );
}

export default ReelsRight;
