import React, {  useEffect, useState } from 'react';
import VendorSideMenuBar from './VendorLayouts/VendorSideMenuBar';
import VendorTopMenuBar from './VendorLayouts/VendorTopMenuBar';
import '../../VendorStyles/VendorDashboard.css';
import NewOrders from './NewOrders';
import Preparing from './Preparing';
import Delivered from './Delivered';
import Declined from './Declined';
import Menu from './Menu';
import Dashboard from './Dashboard';
import PushToPickUp from './PushToPickUp';
import NewOrdersRightbar from './NewOrdersRightbar';
import PreparingRightbar from './PreparingRightbar';
import DeliveredRightbar from './DeliveredRightbar';
import DeclinedRightbar from './DeclinedRightbar';
// import MenuRightbar from './MenuRightbar';
import DashboardRightbar from './DashboardRightbar';

import {useLocation} from 'react-router-dom';

function VendorDashboard() {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(0);

    

    useEffect(()=>{

        const queryParams = new URLSearchParams(location.search);
        const tab = queryParams.get('tab');
        if(tab){
            setActiveTab(parseInt(tab));
        }




    },[location]);





   


    

    const tabContents = [
        <NewOrders key="new-orders" />,
        <Preparing key="preparing" />,
        <PushToPickUp key="push-to-pickup"/>,
        <Delivered key="delivered" />,
        <Declined key="declined" />,
        <Menu key="menu" />,
        <Dashboard key="dashboard" />
    ];

    const sidebarContents = [
        <NewOrdersRightbar  key="new-orders1-rightbar"/>,
        <PreparingRightbar key="preparing1-rightbar" />,
        <DeliveredRightbar key="delivered1-rightbar" />,
        <DeclinedRightbar key="declined1-rightbar"/>,
        // <MenuRightbar key="menu1-rightbar"/>,
        <DashboardRightbar key="dashboard1-rightbar"/>
    ];

    return (
        <div>
            <VendorSideMenuBar />
            <VendorTopMenuBar />
            <div className="dashboard-container">
                
                <div className={`dashboard-left ${activeTab === 5 ? 'menu-tab-active' : ''}`}>
                    <div className="tabs-container">
                        <button style={{ borderRadius: '5px 0px 0px 5px' }} className={`tab-button ${activeTab === 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}><h5 className='tabs-text'>New Orders</h5></button>
                        <button className={`tab-button ${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}><h5 className='tabs-text'>Preparing</h5></button>
                        <button className={`tab-button ${activeTab === 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}><h5 className='tabs-text'>PickUp</h5></button>
                        <button className={`tab-button ${activeTab === 3 ? 'active' : ''}`} onClick={() => setActiveTab(3)}><h5 className='tabs-text'>Delivered</h5></button>
                        <button className={`tab-button ${activeTab === 4 ? 'active' : ''}`} onClick={() => setActiveTab(4)}><h5 className='tabs-text'>Declined</h5></button>
                        <button className={`tab-button ${activeTab === 5 ? 'active' : ''}`} onClick={() => setActiveTab(5)}><h5 className='tabs-text'>Menu</h5></button>
                        <button style= {{ borderRadius: '0px 5px 5px 0px' }} className={`tab-button ${activeTab === 6 ? 'active' : ''}`} onClick={() => setActiveTab(6)}><h5 className='tabs-text'>Dashboard</h5></button>
                    </div>
                    <div className="content">
                        {tabContents[activeTab]}
                    </div>
                </div>
                <div className="rightsidebar-container">
                    {sidebarContents[activeTab]}
                </div>
            </div>
        </div>
    );
}

export default VendorDashboard;
