


import '../../VendorStyles/Declined.css';
import React, { useState,useEffect } from 'react';
import axios from 'axios';
function Declined(){

    const vendor_Base_URL = process.env.REACT_APP_VENDOR_API_BASE_URL;
    
    const [orders, setOrders] = useState([]);
    useEffect(() => {
        // Fetch data from the API
        axios.get(`${vendor_Base_URL}/order/vendor/1`)
            .then(response => {
                const formattedOrders = response.data.allOrdersDetails
                    .filter(order => order.Status_id === 3) // Filter orders with Status_id === 3 or 4

                    .map(order => {
                        const createdAt = new Date(order.Created_at);
                        const time = createdAt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
                        const date = `${createdAt.getDate()} ${createdAt.toLocaleString('en-US', { month: 'short' })}`;
                        return {
                            id: order.ID,
                            time,
                            date
                        };
                    });
                setOrders(formattedOrders);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);
    return(
        <div className="declined-main">
            {orders.map(order => (
                <div key={order.ID} className="declined-single-order">
                    <div className='declined-orderid'>
                        <div><p className='declined-order-details1'>Order ID:<span className='declined-order-id'>{order.id}</span></p></div> 
                        <div><p className='declined-reason-text'>Reason:</p></div>
                    </div>

                    <div className='declined-date-and-time'><p className='declined-order-details2'>{order.time}</p>
                        <p className='declined-order-details3'>{order.date}</p></div>
                </div>
            ))}
    
        </div>
    );

}
export default Declined;