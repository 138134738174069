import React, {  useState } from 'react';
import { Card } from '@mui/material';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './Signup.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { GoogleLogin,GoogleOAuthProvider } from '@react-oauth/google';
import Loader from '../../Components/Loader/Loader';
import { jwtDecode } from 'jwt-decode';
const apiUrl = process.env.REACT_APP_CLIENT_ID;

const RegistrationForm = () => {
    const {state} = useLocation();
    const base_URL = process.env.REACT_APP_API_BASE_URL;
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);    

    const validateForm = (name, value, newErrors) => {
        let errors = { ...newErrors }; 
    
        switch (name) {
        case 'first_name': {
            const firstNameRegex = /^[A-Za-z][A-Za-z' -]{1,49}$/;
            if (!value.trim()) {
                errors.first_name = 'Please enter your first name.';
            } else if (!firstNameRegex.test(value)) {
                errors.first_name = 'Invalid first name.';
            } else {
                delete errors.first_name;
            }
            break;
        }
        case 'last_name': {
            const lastNameRegex = /^[A-Za-z][A-Za-z' -]{1,49}$/;
            if (!value.trim()) {
                errors.last_name = 'Please enter your last name.';
            } else if (!lastNameRegex.test(value)) {
                errors.last_name = 'Invalid last name.';
            } else {
                delete errors.last_name;
            }
            break;
        }
        case 'email': {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!value.trim()) {
                errors.email = 'Please enter your email.';
            } else if (!emailRegex.test(value)) {
                errors.email = 'Invalid email address.';
            } else {
                delete errors.email;
            }
            break;
        }
        case 'password': {
            const passwordRegex =
                    /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,20}$/;
            if (!value) {
                errors.password = 'Please enter your password.';
            } else if (value.length < 8 || value.length > 20) {
                errors.password = 'Password must be 8-20 characters long.';
            } else if (!passwordRegex.test(value)) {
                errors.password =
                        'Password must include uppercase, lowercase, number, and special character.';
            } else {
                delete errors.password;
            }
            break;
        }
        case 'confirmPassword': {
            if (!value) {
                errors.confirmPassword = 'Please enter your confirm password.';
            } else if (value !== formData.password) {
                errors.confirmPassword = 'Passwords do not match.';
            } else {
                delete errors.confirmPassword;
            }
            break;
        }
        default:
            break;
        }
    
        return errors; 
    };
    
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    
        const updatedErrors = validateForm(name, value, { ...errors }); 
        setErrors(updatedErrors);
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        
        let validationErrors = {}; 
        Object.keys(formData).forEach((key) => {
            validationErrors = validateForm(key, formData[key], validationErrors);
        });
        setErrors(validationErrors);
       
        if (Object.keys(validationErrors).length === 0) {
            handleSignup(); 
        } else {
            return;
        }
    };

    const handleSignup = async () => {
        const data={
            'first_name': formData.first_name,
            'last_name': formData.last_name,
            'email': formData.email,
            'password': formData.password,
            'role': state?.role ||'customer',                   
            'address': '123 New Street',      
            'city': 'New York',               
            'state': 'NY',                     
            'pincode': '10000',                 
            'latitude': 40.7560233,
            'longitude':-73.9845629,
            'login_mode':'MANUAL'
    
        };

        try {
            setIsLoading(true);
            const response = await axios.post(`${base_URL}/auth/signup`, data);
            setIsLoading(false);
            toast.success(response?.data?.message);
            localStorage.setItem('signupEmail', formData.email);
            setFormData({ first_name: '',
                last_name: '',
                email: '',
                password: '',
                confirmPassword: ''});

            try {
                setIsLoading(true);
                const res = await axios.post(`${base_URL}/auth/forgotpassword`, {email: formData.email});
                setIsLoading(false);
                toast.success(res?.data?.message);
                if(res?.statusText == 'OK'){
                    navigate('/verify-otp');
                } else {
                    return;
                }
            } catch (error) {
                setIsLoading(false);
                toast.error(error?.response?.data?.error);
                console.error('Error from Signup',error);
            }


        } catch (error) {
            setIsLoading(false);
            toast.error(error?.response?.data?.error);
            console.error('Error from Signup',error);
        }

        
        
    };

    const handleLogin = () =>{
        navigate('/login', {state: {role: state?.role}});
    };

    const handleSuccess = async (response) => {
        const data={
            token: response?.credential,
            login_mode: 'google',
            role:  state?.role ||'customer', 
        };

        try {
            setIsLoading(true);
            const res = await axios.post(`${base_URL}/auth/signin`,data);
            const token = res?.data?.token; 
            if (token) {
                localStorage.setItem('authToken', token); 
                const decodedToken = jwtDecode(token);
                setIsLoading(false);
                
                if(res?.statusText == 'OK'){
                    if(decodedToken?.role === 'vendor'){
                        navigate('/vendor');
                    }else if(decodedToken?.role === 'delivery_partner'){
                        navigate('/delivery-partner');
                    }else{
                        navigate('/reels');
                    }
                }
            } else {
                navigate('/login');
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(error?.response?.data?.error);
            console.error('Error from Signup with google',error);
        }
       
    };
    
    const handleFailure = (error) => {
        toast.error(error);
        
    };

    return (
        <div className="form-container">
            {isLoading && <Loader/>}
            <div className='back-drop'></div>
            <Card className="form-card">
                <div className="form-header">
                    <h2>Register</h2>
                </div>
                <form onSubmit={handleSubmit} >
                    <div className="name-fields">
                        <div className="form-group">
                            <label>First name</label>
                            <input
                                type="text"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleInputChange}
                                className={(errors.first_name || (!formData.first_name.trim() && submitted)) ? 'error' : ''} 
                                autoComplete="off"
                                placeholder="John"
                            />
                            {(errors.first_name || (!formData.first_name.trim() && submitted)) && <span className="error-message">{errors.first_name}</span>}
                        </div>
                        <div className="form-group">
                            <label>Last name</label>
                            <input
                                type="text"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleInputChange}
                                className={(errors.last_name || (!formData.last_name.trim() && submitted)) ? 'error' : ''}
                                autoComplete='off'
                                placeholder='Doe'
                            />
                            {(errors.last_name || (!formData.last_name.trim() && submitted)) && <span className="error-message">{errors.last_name}</span>}
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className={(errors.email || (!formData.email.trim() && submitted)) ? 'error' : ''}
                            autoComplete='off'
                            placeholder='Enter your email'
                        />
                        {(errors.email || (!formData.email.trim() && submitted)) && <span className="error-message">{errors.email}</span>}
                    </div>

                    <div className="form-group">
                        <label>Password</label>
                        <div className="password-input">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                className={(errors.password || (!formData.password.trim() && submitted)) ? 'error' : ''}
                                autoComplete='off'
                                placeholder='Enter your password'
                            />
                            <button 
                                type="button" 
                                className="password-toggle"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <FaEye /> : <FaEyeSlash />}
                            </button>
                        </div>
                        {(errors.password || (!formData.password.trim() && submitted)) && <span className="error-message">{errors.password}</span>}
                    </div>

                    <div className="form-group">
                        <label>Confirm password</label>
                        <div className="password-input">
                            <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                                className={(errors.confirmPassword || (!formData.confirmPassword.trim() && submitted)) ? 'error' : ''}
                                autoComplete='off'
                                placeholder='Re-enter password'
                            />
                            <button 
                                type="button" 
                                className="password-toggle"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                            </button>
                        </div>
                        {(errors.confirmPassword || (!formData.confirmPassword.trim() && submitted)) && <span className="error-message">{errors.confirmPassword}</span>}
                    </div>

                    <button type="submit" className="signup-button">
            Sign Up
                    </button>

                   
                    <button type="button" className="account-exists" onClick={handleLogin}>Already have an account</button>
                   

                    <div className="social-login">
                        <p>or login with</p>
                        <div className='social-buttons'>
                            <GoogleOAuthProvider clientId={apiUrl}>
                                <div className="App">
                                    <GoogleLogin
                                        onSuccess={handleSuccess}
                                        onError={handleFailure}
                                    />
                                </div>
                            </GoogleOAuthProvider>
                        </div>
                    </div>
                </form>
            </Card>
        </div>
    );
};

export default RegistrationForm;