import React,{useState} from 'react';
import ImageCarousel from './ImageCarousel';
import './../../../Styles/RestaurantPage/FoodDescription.css';
import starImg from './../../../assets/Star 3.png';
import SideMenuBar  from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';
import {useTranslation} from 'react-i18next';
import { useSelector } from 'react-redux';

function FoodDescription() {

    const selectedItemData= useSelector((state)=>{
        return state?.ItemReducer.selecteditemdata;
    });


    console.log(selectedItemData);

    const [activeTab, setActiveTab] = useState(1);
    const {t} = useTranslation();

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };
    return (
        <div>



           
           
            <div className="topmenubar-cart">
          
                <SideMenuBar></SideMenuBar>
          
                <div className='topbar-food-desc'>
                    <TopMenuBar></TopMenuBar>
                </div> 
   
            </div> 


            
           


            <div className='tabs-content-and-carousel-container'>

             


                <ImageCarousel />

              

                <div className='tabs-and-content-container'>
                    <div className="tabs">
                        <button className={activeTab === 1 ? 'tab-active1' : 'tab1'} onClick={() => handleTabClick(1)}>{t('restaurantpage.description')}</button>
                        <button className={activeTab === 2 ? 'tab-active2' : 'tab2'} onClick={() => handleTabClick(2)}>{t('restaurantpage.ingredients')}</button>
                    </div>
                    <div className="tab-content">
                        {activeTab === 1 && (
                            <div>
                                <div className='svg-and-title'>
                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.75" y="0.75" width="21.0689" height="21.0689" stroke="#E13535" strokeWidth="1.5"/>
                                        <rect x="4.51562" y="4.51562" width="13.5413" height="13.5413" rx="6.77066" fill="#E13535"/>
                                    </svg>
                                    <h4 className='food-name'>{selectedItemData.Menu_item_Name}</h4>

                            

                                </div>

                                <div className='star-rating-reviews-container'>

                                    <div className='star-rating-container'>
                                        <img className='star-img-food-desc' src={starImg} alt="starPic" />
                                        <p className='food-desc-rating-text'>{t('restaurantpage.rating')}</p>
                                    </div>

                                    <p className='food-desc-reviews'>{t('restaurantpage.reviews')}</p>
                                </div>
                                <p className='food-description'>{selectedItemData.Menu_item_Description}

                                   
                                </p>
                            </div>
                        )}
                        {activeTab === 2 && (
                            <div>
                                <p className='ingrediants-description'>{selectedItemData.Menu_item_Description}</p>
                                <p className='ingrediants-description'>
                                    <span className='ingridents-heading'>{t('restaurantpage.ingredients-title')}</span>
                                    <ul>
                                        <li> {selectedItemData.Menu_item_Ingridents}</li>
                                        {/* {t('restaurantpage.ingredient2')}
                                        <li>{t('restaurantpage.ingredient3')} </li>
                                        {t('restaurantpage.ingredient4')}
                                        {t('restaurantpage.ingredient5')}
                                        {t('restaurantpage.ingredient6')}
                                        {t('restaurantpage.ingredient7')}
                                        {t('restaurantpage.ingredient8')}
                                        {t('restaurantpage.ingredient9')}
                                        {t('restaurantpage.ingredient10')}
                                        {t('restaurantpage.ingredient11')}
                                        {t('restaurantpage.ingredient12')} */}
                                    </ul>

                                </p>
                           
                            </div>
                        )}
                    </div>
                </div>
            </div>


            <div className="right-side-bar">
                <RightSideBar></RightSideBar>
            </div>

        </div>
    );
}

export default FoodDescription;