import React, { useState } from 'react';
import './Login.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import {  NavLink, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GoogleLogin,GoogleOAuthProvider } from '@react-oauth/google';
import Loader from '../../Components/Loader/Loader';
import {useCart} from '../../CartProvider';
import { jwtDecode } from 'jwt-decode';
const apiUrl = process.env.REACT_APP_CLIENT_ID;

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [credentials, setCredentials] = useState({
        email:'',
        password:'',
    });
    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });
    const navigate = useNavigate();
    const {state} = useLocation();
    const { setRole } = useCart();

    const base_URL = process.env.REACT_APP_API_BASE_URL;

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const validateField = (name, value) => {
        const newErrors = { ...errors };
    
        switch (name) {
        case 'email': {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!value.trim()) {
                newErrors.email = 'Please enter your email.';
            } else if (!emailRegex.test(value)) {
                newErrors.email = 'Invalid email address.';
            } else {
                delete newErrors.email;
            }
            break;
        }
        case 'password': {
            const passwordRegex =
                    /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,20}$/;
            if (!value.trim()) {
                newErrors.password = 'Please enter your password.';
            } else if (value.length < 8 || value.length > 20) {
                newErrors.password = 'Password must be 8-20 characters long.';
            } else if (!passwordRegex.test(value)) {
                newErrors.password =
                        'Password must include uppercase, lowercase, number, and special character.';
            } else {
                delete newErrors.password;
            }
            break;
        }
        default:
            break;
        }
    
        setErrors(newErrors);
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
    
        setCredentials((prev) => ({
            ...prev,
            [name]: value,
        }));
    
        validateField(name, value);
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        const newErrors = {};

        if (!credentials.email.trim()) {
            newErrors.email = 'Please enter your email.';
            return;
        }

        if (!credentials.password.trim()) {
            newErrors.password = 'Please enter your password.';
            return;
        }
        setErrors(newErrors);

        const data = {
            'email' : credentials.email,
            'password' : credentials.password,
            'login_mode':'manual',
        };
        try{
            setIsLoading(true);
            const response = await axios.post(`${base_URL}/auth/signin`,data);
            const token = response?.data?.token; 
            if (token) {
                localStorage.setItem('activeRole',response?.data?.role);
                localStorage.setItem('authToken', token); 
                const decodedToken = jwtDecode(token);
                setRole(decodedToken?.role);
                setIsLoading(false);
                
                if(response?.statusText == 'OK'){
                    if(decodedToken?.role === 'vendor'){
                        navigate('/vendor');
                    }else if(decodedToken?.role === 'delivery_partner'){
                        navigate('/delivery-partner');
                    }else{
                        navigate('/reels');
                    }
                }
            } else {
                if (response?.data?.verified == false) {
                    toast.success(response?.data?.message);
                    navigate('/verify-otp');
                }
            }
        }catch(error){
            setIsLoading(false);
            toast.error(error?.response?.data?.error); 
            console.error('There was an error logging in!', error);     
        }
    };

    const handleSuccess = async (response) => {
        const data={
            token: response?.credential,
            login_mode: 'google',
            role: state?.role ||'customer', 
        };

        try {
            setIsLoading(true);
            const res = await axios.post(`${base_URL}/auth/signin`,data);
            const token = res?.data?.token; 
            if (token) {
                localStorage.setItem('authToken', token); 
                const decodedToken = jwtDecode(token);
                setIsLoading(false);
                
                if(res?.statusText == 'OK'){
                    if(decodedToken?.role === 'vendor'){
                        navigate('/vendor');
                    }else if(decodedToken?.role === 'delivery_partner'){
                        navigate('/delivery-partner');
                    }else{
                        navigate('/reels');
                    }
                }
            } else {
                navigate('/login');
            }
            console.log('res',res);
        } catch (error) {
            setIsLoading(false);
            toast.error(error?.response?.data?.error);
            console.error('Error from Signup with google',error);
        }
        console.log('Google Login Success:', response);
    };
    
    const handleFailure = (error) => {
        console.log('Google Login Failed:', error);
    };
    

    return (
        <div className='login-page'>
            <div className='background'></div>
            {isLoading && (
                <div>
                    <Loader/>
                </div>
            )}
            <div className='login-container'>
                <h2 className='heading'>Login</h2>
                <form>
                    <div className='form-group'>
                        <label className='label-text' htmlFor='email'>Email</label>
                        <input 
                            type='email' 
                            id='email' 
                            name='email'  
                            placeholder='Enter your email' 
                            onChange={(e) => handleOnChange(e)}
                            className={errors.email ? 'input-error' : ''} 
                        />
                        {errors.email && <span className="error-message">{errors.email}</span>}
                    </div>
                    <div className='form-group'>
                        <label className='label-text' htmlFor='password'>Password</label>
                        <div className='password-container'>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id='password'
                                name='password'  
                                placeholder='Enter your password'
                                onChange={(e) => handleOnChange(e)}
                                className={errors.password ? 'input-error' : ''}
                            />
                            <span
                                className='toggle-password'
                                onClick={togglePasswordVisibility}
                                style={{ cursor: 'pointer' }}
                            >
                                {showPassword ? <FaEye/> : <FaEyeSlash/>}
                            </span>
                        </div>
                        {errors.password && <span className="error-message">{errors.password}</span>}
                    </div>
                    <div className='form-options'>
                        <NavLink to={'/forget-password'} className='forgot-password'>
                            Forgot Password?
                        </NavLink>
                    </div>
                    <button type='submit' className='login-btn' onClick={(e) => handleLogin(e)}>
                        Login
                    </button>
                    <button type='button' className='signup-btn' onClick={()=>navigate('/signup')}>
                        Sign Up
                    </button>
                </form>
                <div className='or-login-with'>
                    <p>or login with</p>
                    <div className='social-buttons'>
                        <GoogleOAuthProvider clientId={apiUrl}>
                            <div className="App">
                                <GoogleLogin
                                    onSuccess={handleSuccess}
                                    onError={handleFailure}
                                />
                            </div>
                        </GoogleOAuthProvider>
                    </div>
                </div>
                <p className='terms'>
                    By continuing, you agree to our <a href='#'>Terms and Conditions</a>{' '}
                    and <a href='#'>Privacy Policy</a>.
                </p>
            </div> 
        </div>
    );
};

export default Login;
