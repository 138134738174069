import React, { useState,useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, Box, styled } from '@mui/material';
import Step1 from './PersonalInformationForm';
import Step2 from './IdentityDetailsForm';
import Step3 from './EnterOTP';

import { toast } from 'react-toastify';

import KYCUpdateMessage from './KYCUpdateMessage';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import KYCVerificationRejected from './KYCVerificationRejected';
import { jwtDecode } from 'jwt-decode';

const VendorRegistrationForm = () => {

    const vendor_Base_URL = process.env.REACT_APP_VENDOR_API_BASE_URL;

    const [userID,setUserID] = useState();

    const decodedToken=()=>{



        const token = localStorage.getItem('authToken');

        if (!token) {
            console.error('Token not found!');
            return null;
        }

        try {
            const decoded = jwtDecode(token); // Decode the token
            const { id, name, role, email } = decoded; // Extract details from the token payload
            setUserID(id);

            // Update the email field in formData
            setFormData((prevFormData) => ({
                ...prevFormData,
                email: email, // Assign decoded email to formData
            }));

            console.log('Vendor Details:', { id });
            return { id, name, role, email }; // Return the details
        } catch (error) {
            console.error('Invalid token!', error);
            return null;
        }

    };
    const [activeStep, setActiveStep] = useState(0);



    const [location, setLocation] = useState(null); // State to store the location data


    useEffect(() => {
        // Check if Geolocation is supported
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;

                    const locationData = `${latitude},${longitude}`;

                    setLocation(locationData);
                  

                  

                    decodedToken();
    
                   
                },
                (error) => {
                    console.error('Error fetching location:', error.message);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, []);


    

    
    const steps = ['Personal Information', 'Identity Details', 'KYC Update'];

    const [formData, setFormData] = useState({
        businessName: '',
        address: '',
        city:'',
        state:'',
        country:'',
        pincode:'',
        email: '',
        // phoneNumber: '',
        legalBusinessName: '',
        taxId: '',
        representativeName: '',
        phoneNumber2: '',
        dob: '',
        routingNumber: '',
        accountNumber: '',
        reEnterAccountNumber:''
    });

    console.log(formData);

    // const [formSubmitted, setFormSubmitted] = useState(false);

    const [errors, setErrors] = useState({});

    const [kycStatus, setKycStatus] = useState(null); // 'pending', 'approved', 'rejected'
    console.log(kycStatus);
    const navigate = useNavigate();

    const fetchKycStatus = async () => {
        try {
            const response = await axios.get(`${vendor_Base_URL}/vendors/account/${userID}`);
            const  status  = response.data.getVendorInfo[0].status;
            console.log(status);
            setKycStatus(status);
            // if (status === 'pending') {
            //     navigate('/KYCUpdateMessage');
            // }else if(status === 'approved') {
            //     navigate('/Home');
            // }else if(status === 'rejected') {
            //     navigate('/rejected');
            // }
        } catch (error) {
            console.error('Error submitting KYC:', error);
        }
    };

    useEffect(() => {
        // Simulate API response after submission
      
    
        fetchKycStatus();
    }, [userID]);

    const handleNext =  async() => {
        if (activeStep === 0) {
            if (!validateStep1()) {
                toast.error('please fill all fields');
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if (activeStep === 1) {
            if (!validateStep2()) {
                toast.error('please fill all fields');
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if (activeStep === 2) {
            if (validateStep3()) {


                try {

                    const vendorData = {
        
                        Name :formData.businessName,
                        Avatar_key :'hugiygyufutft',
                        Type:1,
                        Website_url:'hugyyftftfttfutf',
                        User_id:userID,
                        // Business_Name,
                        Location: location,
                        Address:formData.address,
                        city:formData.city,
                        state:formData.state,
                        country:formData.country,
                        pincode:formData.pincode,
                        Phone_number:formData.phoneNumber2,
                        Email:formData.email,
                        Legal_Business_Name:formData.legalBusinessName,
                        EIN_or_Tax_ID:formData.taxId,
                        Representative_Name:formData.representativeName,
                        Date_of_birth:formData.dob,
                        Routing_Number:formData.routingNumber,
                        Account_Number:formData.accountNumber,
                        status:'pending',
                        Created_at: '2024-03-03T00:39:27.000Z',
                        Created_by: 4,
                        Updated_at: '2024-03-03T00:39:27.000Z',
                        Updated_by: 4
        
                    };
        
                    const response = await axios.post(`${vendor_Base_URL}/vendors/account/${userID}`,vendorData);
        
                    console.log(response);
                    // setFormSubmitted(true);
                    toast.success('Data Submitted Successfully');
                    fetchKycStatus();
                } catch (error) {
        
                    toast.error(error.response.data.error);
        
                
                }

               

                // postVendorData();

                // setFormSubmitted(true);
                // toast.success('Data Submitted Successfully');
                // Handle form submission logic here
            } else {
                toast.error('Please fill all fields');
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let tempErrors = { ...errors };

        // Real-time validation for Personal Information
        if (name === 'businessName') {
            if (!/^[a-zA-Z\s]{3,}$/.test(value)) {
                tempErrors[name] = 'Enter atleast 3 characters';
            } else {
                delete tempErrors[name];
            }
        } else if (name === 'address') {
            if (!/^[a-zA-Z0-9\s,:-]{10,}$/.test(value)) {
                tempErrors[name] =
          'Enter valid address';
            } else {
                delete tempErrors[name];
            }
        } if (name === 'city') {

            if (!/^[a-zA-Z\s]{3,}$/.test(value)) {
                tempErrors[name] =
          'Enter valid city name';
            } else {
                delete tempErrors[name];
            }

        }else if (name === 'state') {
            if (!/^[a-zA-Z\s]{3,}$/.test(value)) {
                tempErrors[name] =
          'Enter Valid State name';
            } else {
                delete tempErrors[name];
            }
        }else if (name === 'country') {
            if (!/^[a-zA-Z\s]{3,}$/.test(value)) {
                tempErrors[name] =
          'Enter valid country name';
            } else {
                delete tempErrors[name];
            }
        }else if (name === 'pincode') {
            if (!/^\d{6}$/.test(value)) {
                tempErrors[name] =
          'Enter valid pincode';
            } else {
                delete tempErrors[name];
            }
        }else if (name === 'email') {
            if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                tempErrors[name] = ' Enter Valid email ';
            } else {
                delete tempErrors[name];
            }
        }

        // Real-time validation for Identity Details
        if (name === 'legalBusinessName') {
            if (!/^[a-zA-Z\s]{3,}$/.test(value)) {
                tempErrors[name] = 'Enter Valid Legal business name ';
            } else {
                delete tempErrors[name];
            }
        } else if (name === 'taxId') {
            if (!/^\d{9}$/.test(value)) {
                tempErrors[name] = 'Enter Valid EIN/Tax Id ';
            } else {
                delete tempErrors[name];
            }
        } else if (name === 'representativeName') {
            if (!/^[a-zA-Z\s]{3,}$/.test(value)) {
                tempErrors[name] = ' Enter Valid Representative name ';
            } else {
                delete tempErrors[name];
            }
        } else if (name === 'dob') {
            if (!value) {
                tempErrors[name] = 'Date of birth is required';
            } else {
                delete tempErrors[name];
            }
        }

        // Real-time validation for Account Information
        if (name === 'routingNumber') {
            if (!/^\d{9}$/.test(value)) {
                tempErrors[name] = 'Enter Valid Routing Number ';
            } else {
                delete tempErrors[name];
            }
        } else if (name === 'accountNumber') {
            if (!/^\d{10,12}$/.test(value)) {
                tempErrors[name] = 'Enter Valid Account Number ';
            } else {
                delete tempErrors[name];
            }
        } else  if (name === 'reEnterAccountNumber') {
            if (value !== formData.accountNumber) {
                tempErrors[name] = 'Account numbers do not match.';
            } else if (!/^\d{10,12}$/.test(value)) {
                tempErrors[name] = 'Re-enter account number is required.';
            } else {
                delete tempErrors[name];
            }
        }


        

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors(tempErrors);
    };

    // const handlePhoneChange = (value) => {
    //     let tempErrors = { ...errors };

    //     if (!/^\d+$/.test(value)) {
    //         tempErrors['phoneNumber2'] = 'Phone number is invalid';
    //     } else {
    //         delete tempErrors['phoneNumber2'];
    //     }
    //     setFormData({
    //         ...formData,
    //         phoneNumber: value,
    //     });
    //     setErrors(tempErrors);
    // };

    const handlePhoneChange2 = (value) => {
        let tempErrors = { ...errors };

        if (!/^\d{11,18}$/.test(value)) {
            tempErrors['phoneNumber2'] = 'Phone number is invalid';
        } else {
            delete tempErrors['phoneNumber2'];
        }


       

        setFormData({
            ...formData,
            phoneNumber2: value,
        });

        setErrors(tempErrors);
    };


   



   
    
    

    
    


   
    

    const validateStep1 = () => {
        let tempErrors = {};
        let isValid = true;

        if (!formData.businessName) {
            isValid = false;
            tempErrors['businessName'] = 'business name is required';
        } else if (!/^[a-zA-Z\s]{3,}$/.test(formData.businessName)) {
            isValid = false;
            tempErrors['businessName'] = 'businessName is invalid';
        }

        if (!formData.address) {
            isValid = false;
            tempErrors['address'] = 'Address is required';
        }else if(!/^[a-zA-Z0-9\s,:-]{10,}$/.test(formData.address)){

            isValid = false;
            tempErrors['address'] = 'address is invalid';

        }

        if (!formData.city) {
            isValid = false;
            tempErrors['city'] = 'city is required';
        } else if (!/^[a-zA-Z\s]{3,}$/.test(formData.city)) {
            isValid = false;
            tempErrors['city'] = 'city is invalid';
        }

        if (!formData.state) {
            isValid = false;
            tempErrors['state'] = 'state is required';
        } else if (!/^[a-zA-Z\s]{3,}$/.test(formData.state)) {
            isValid = false;
            tempErrors['state'] = 'state is invalid';
        }

        if (!formData.country) {
            isValid = false;
            tempErrors['country'] = 'country is required';
        } else if (!/^[a-zA-Z\s]{3,}$/.test(formData.country)) {
            isValid = false;
            tempErrors['country'] = 'country is invalid';
        }

        if (!formData.pincode) {
            isValid = false;
            tempErrors['pincode'] = 'pincode is required';
        } else if (!/^\d{6}$/.test(formData.pincode)) {
            isValid = false;
            tempErrors['pincode'] = 'pincode is invalid';
        }

        if (!formData.email) {
            isValid = false;
            tempErrors['email'] = 'Email is required';
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
            isValid = false;
            tempErrors['email'] = 'Email is invalid';
        }

        // if (!formData.phoneNumber) {
        //     isValid = false;
        //     tempErrors['phoneNumber'] = 'Phone number is required';
        // } else if (!/^\d+$/.test(formData.phoneNumber)) {
        //     isValid = false;
        //     tempErrors['phoneNumber'] = 'Phone number is invalid';
        // }

        setErrors(tempErrors);
        return isValid;
    };

    const validateStep2 = () => {
        let tempErrors = {};
        let isValid = true;

        if (!formData.legalBusinessName) {
            isValid = false;
            tempErrors['legalBusinessName'] = 'legal business name is required';
        } else if (!/^[a-zA-Z\s]{3,}$/.test(formData.legalBusinessName)) {
            isValid = false;
            tempErrors['legalBusinessName'] = 'legal business Name is invalid';
        }

        if (!formData.taxId) {
            isValid = false;
            tempErrors['taxId'] = 'EIN/Tax Id is required';
        } else if (!/^\d{9}$/.test(formData.taxId)) {
            isValid = false;
            tempErrors['taxId'] = 'EIN/Tax Id is invalid';
        }

        if (!formData.representativeName) {
            isValid = false;
            tempErrors['representativeName'] = 'representative name is required';
        } else if (!/^[a-zA-Z\s]{3,}$/.test(formData.representativeName)) {
            isValid = false;
            tempErrors['representativeName'] = 'representative Name is invalid';
        }

        if (!formData.phoneNumber2) {
            isValid = false;
            tempErrors['phoneNumber2'] = 'Phone number is required';
        } else if (!/^\d{11,18}$/.test(formData.phoneNumber2)) {
            isValid = false;
            tempErrors['phoneNumber2'] = 'Phone number is invalid';
        }

        if (!formData.dob) {
            isValid = false;
            tempErrors['dob'] = 'Date of birth is required';
        }

        setErrors(tempErrors);
        return isValid;
    };

    const validateStep3 = () => {
        let tempErrors = {};
        let isValid = true;

        if (!formData.routingNumber) {
            isValid = false;
            tempErrors['routingNumber'] = 'Routing number is required';
        } else if (!/^\d{9}$/.test(formData.routingNumber)) {
            isValid = false;
            tempErrors['routingNumber'] = 'Routing number is invalid';
        }

        // Account number validation (example: 10-12 digits)
        if (!formData.accountNumber) {
            isValid = false;
            tempErrors['accountNumber'] = 'Account number is required';
        } else if (!/^\d{10,12}$/.test(formData.accountNumber)) {
            isValid = false;
            tempErrors['accountNumber'] = 'Account number is invalid';
        }

        if (!formData.reEnterAccountNumber) {
            isValid = false;
            tempErrors['reEnterAccountNumber'] = 're-enter acount number is required';
        }


        if (formData.accountNumber !== formData.reEnterAccountNumber) {
            isValid = false;
            tempErrors['reEnterAccountNumber'] = 're-entered account number not matched!';
        } else if (!/^\d{10,12}$/.test(formData.accountNumber)) {
            isValid = false;
            tempErrors['reEnterAccountNumber'] = ' re-Enter Account number is invalid';
        }

        setErrors(tempErrors);
        return isValid;
    };

    const CustomButton = styled(Button)({
        backgroundColor: '#E13535',
        color: '#FFFFFF',
        marginTop: '30px',
        marginBottom: '30px',
        '&:hover': {
            backgroundColor: '#E13535',
            color: '#FFFFFF',
        },
    });

    const BackButton = styled(Button)({
        border: '1px solid var(--Neutral-n200, #E2E8F0)',
        marginTop: '30px',
        marginBottom: '30px',
    });

    const CustomStepIcon = (props) => {
        const { active, completed, icon } = props;

        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 20,
                    height: 20,
                    borderRadius: '50%',
                    backgroundColor: active || completed ? '#02A750' : '#F2F4F7',
                    color: active || completed ? '#FFFFFF' : '#2C3142',
                }}
            >
                {icon}
            </Box>
        );
    };


  

    return (
        <div className="vendor-registration-form">
            <Box className="stepper-container">

                {
                    kycStatus === 'pending' || kycStatus === 'rejected' ? (
                        <Stepper activeStep={2} alternativeLabel>
                            {steps.map((label, index) => (
                                <Step key={index} >
                                    <StepLabel
                                        StepIconComponent={(props) => (
                                            <CustomStepIcon {...props} icon={index + 1} />
                                        )}
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>


                    ): (


                
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label, index) => (
                                <Step key={index}>
                                    <StepLabel
                                        StepIconComponent={(props) => (
                                            <CustomStepIcon {...props} icon={index + 1} />
                                        )}
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    )
                }
                <Box sx={{ mt: 2, mb: 1 }}>
                    {kycStatus === 'pending' ? (
                        <KYCUpdateMessage /> 
                    ) :kycStatus === 'approved' ? (
                        navigate('/Home') // Show when KYC is approved
                    ) : kycStatus === 'rejected' ? (
                        <KYCVerificationRejected /> // Show when KYC is rejected
                    ) :

                    
                        (
                            <Box>
                                {activeStep === 0 && (
                                    <Step1
                                        formData={formData}
                                        handleChange={handleChange}
                                        // handlePhoneChange={handlePhoneChange}
                                        errors={errors}
                                        setErrors={setErrors}
                                    />
                                )}
                                {activeStep === 1 && (
                                    <Step2
                                        formData={formData}
                                        handleChange={handleChange}
                                        handlePhoneChange2={handlePhoneChange2}
                                        errors={errors}
                                       
                                     
                                    />
                                )}
                                {activeStep === 2 && (
                                    <Step3
                                        formData={formData}
                                        handleChange={handleChange}
                                        errors={errors}
                                    />
                                )}
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <BackButton
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                  Back
                                    </BackButton>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <CustomButton onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                                    </CustomButton>
                                </Box>
                            </Box>
                        )}
                </Box>
            </Box>
        </div>
    );
};

export default VendorRegistrationForm;
