import React from 'react';
import Box from '@mui/material/Box';


import Modal from '@mui/material/Modal';


import '../../Styles/Cart/AddressListModel.css';
import AddCouponForm from './AddCouponForm';

function CouponModal({handleClose,open,title,setTitle,validFrom,setValidFrom,validTo,setValidTo,offerPercentage,setOfferPercentage,couponCode,setCouponCode,autoGenerateCode,handleAddOrUpdateCoupon,handleAutoGenerateCodeChange,isEditing}) {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='styleAddressModel'>


                    <AddCouponForm 
                        handleClose={handleClose} 
                        handleAddOrUpdateCoupon={handleAddOrUpdateCoupon}
                        handleAutoGenerateCodeChange={handleAutoGenerateCodeChange}
                        title={title}
                        setTitle={setTitle}
                        validFrom={validFrom}
                        setValidFrom={setValidFrom}
                        validTo={validTo}
                        setValidTo={setValidTo}
                        offerPercentage={offerPercentage}
                        setOfferPercentage={setOfferPercentage}
                        couponCode={couponCode}
                        setCouponCode={setCouponCode}
                        autoGenerateCode={autoGenerateCode}
                        isEditing={isEditing}
                    />


                </Box>
            </Modal>
        </div>
    );
}

export default CouponModal;