import React, { useState } from 'react';
import '../../VendorStyles/EditPopup.css';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

function EditPopup({ onClose , onNext }) {
    const [selectedOption, setSelectedOption] = useState('');
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleNextClick = () => {
        onNext(selectedOption);
    };
    return (
        <div className="edit-popup-container">
            <svg
                width="26"
                height="26"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="menu-edit-popup-cross-icon"
                onClick={onClose}
            >
                <path
                    d="M21.2981 18.0003L35.3158 3.98187C36.2281 3.07003 36.2281 1.59572 35.3158 0.683879C34.404 -0.22796 32.9297 -0.22796 32.0179 0.683879L17.9998 14.7023L3.98211 0.683879C3.06986 -0.22796 1.596 -0.22796 0.684185 0.683879C-0.228062 1.59572 -0.228062 3.07003 0.684185 3.98187L14.7019 18.0003L0.684185 32.0187C-0.228062 32.9305 -0.228062 34.4048 0.684185 35.3167C1.1386 35.7715 1.73609 36 2.33315 36C2.93021 36 3.52727 35.7715 3.98211 35.3167L17.9998 21.2983L32.0179 35.3167C32.4727 35.7715 33.0698 36 33.6669 36C34.2639 36 34.861 35.7715 35.3158 35.3167C36.2281 34.4048 36.2281 32.9305 35.3158 32.0187L21.2981 18.0003Z"
                    fill="white"
                />
            </svg>
            <div className="edit-popup">
                <div className="edit-popup-edit-icon-background">
                    <svg
                        width="32"
                        height="32"
                        viewBox="0 0 42 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M28.3887 5.37573L36.6243 13.6115"
                            stroke="#CED1DC"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M5.79395 27.9688L14.0378 36.1981"
                            stroke="#CED1DC"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M37.3464 4.65361C36.2427 3.5546 34.7456 2.94224 33.1881 2.95277C31.6304 2.96332 30.1417 3.5959 29.053 4.70977L5.7925 27.9702L2.95312 39.047L14.0299 36.206L37.2904 12.9455C38.4045 11.8573 39.0373 10.3687 39.0479 8.8113C39.0584 7.25387 38.4458 5.75687 37.3464 4.65361Z"
                            stroke="#CED1DC"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <p className="edit-head1-text">You can update an Item/ add new offer</p>
                <FormControl>
                    <RadioGroup
                        aria-label="options"
                        name="options"
                        value={selectedOption}
                        onChange={handleOptionChange} 
                    >
                        <div className="edit-popup-fieldset">
                            <FormControlLabel
                                style={{ color: selectedOption === 'Add Offer' ? '#1E293B' : '#7E8693' }}
                                value="Add Offer"
                                control={<Radio />}
                                label="Add Offer"
                            />
                            <FormControlLabel
                                style={{ color: selectedOption === 'Update Item' ? '#1E293B' : '#7E8693' }}
                                value="Update Item"
                                control={<Radio />}
                                label="Update Item"
                            />
                        </div>
                    </RadioGroup>
                </FormControl>
                <button className="edit-popup-next-btn" onClick={handleNextClick}>Next</button>
            </div>
            <div className="blur-background"></div>
        </div>
    );
}

export default EditPopup;
