import React, { createContext, useContext, useState,useEffect } from 'react';

import axios from 'axios';

const CartContext = createContext();


export const CartProvider = ({ children }) => {


    const base_URL = process.env.REACT_APP_API_BASE_URL;

  
    
    const [ cartCount,setCartCount] = useState(0);
    const [role, setRole] = useState(null);

    const fetchCartCount = async()=>{

        // Fetch item data from the API using the itemID
        const itemResponse = await axios.get(`${base_URL}/cartdetails/cartsp/1`);
      

        setCartCount(itemResponse.data.cartDetails[0].Cart_Information?.length);
      
  
       

        

    };

    useEffect(()=>{

        fetchCartCount();

    },[]);
    
    return (
        <CartContext.Provider value={{ cartCount, fetchCartCount, role, setRole  }}>
            {children}
        </CartContext.Provider>
    );
};

// Custom hook to use the cart context
export const useCart = () => {
    return useContext(CartContext);
    
};


