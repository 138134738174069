import React, { useEffect, useState, useRef } from 'react';
import '../../../Styles/Reels/ReelsIndex.css';


import VideoCard from '../../../Components/VideoCard';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';

import axios from 'axios';

function Reels() {
  

    const base_URL = process.env.REACT_APP_API_BASE_URL;

  

    const [postData, setPostData] = useState([]);
    const [restaurant, setRestaurant] = useState([]);
    console.log('postData',postData);

    const [ismuted, setIsMuted] = useState(true); // State to manage mute/unmute for all reels
    const [hasMoreData, setHasMoreData] = useState(true); // Flag for more data availability

    const handleMute = () => {
        setIsMuted((prevMuted) => !prevMuted);
    };

    const [globalMuteIcon, setGlobalMuteIcon] = useState(true); // Global mute state
    const [coordinate,setCoordinate] = useState({
        lat: 0,
        lng: 0
    });

    const toggleMuteIcon = () => {
        setGlobalMuteIcon((prevMute) => !prevMute);
    };

    const [page, setPage] = useState(1);

    const videoRefs = useRef([]);

    const parseAvatarKey = (key) => {
        try {
            // Parse the JSON string safely
            return JSON.parse(key.replace(/\\/g, '')) || [];
        } catch (error) {
            // console.error('Failed to parse Avatar_key:', error);
            return [];
        }
    };

    const location = () =>{
        navigator.geolocation.getCurrentPosition((position) => {
            const coords = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
            setCoordinate(coords);
            console.log('coords',coords);
        });
    };


    const getPosts = async () => {
        const response = await axios.get(
            `${base_URL}/posts/location?pageNo=${page}&pageSize=10&location=${coordinate?.lat},${coordinate?.lng}`
        );

        const postInformation = response.data.posts[0].Post_Information;

      
        // Check if there is more data to load
        if (postInformation?.length > page) {
            setPostData((prevData) =>
                page === 1 ? [...postInformation] : [...prevData, ...postInformation]
            );
            setRestaurant((prevData) =>
                page === 1 ? [...postInformation] : [...prevData, ...postInformation]
            );
        } else {
            setHasMoreData(false); // No more data to load
        }
    
    };

    const handleInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight
            ) {
                if (hasMoreData) {
                    setPage((prev) => prev + 1);
                }

                
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
    
        location();
        getPosts();
    }, [page]);

    useEffect(() => {
        window.addEventListener('scroll', handleInfiniteScroll);

        return () => window.removeEventListener('scroll', handleInfiniteScroll);
    }, [hasMoreData]);

    

    // Intersection Observer API to observe videos
    useEffect(() => {
        if (!postData.length) return; // Only observe if postData exists

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const video = entry.target;
                    if (entry.isIntersecting) {
                        video.play();
                    } else {
                        video.pause();
                    }
                });
            },
            {
                threshold: 0.75, // Trigger the video when 75% of it is visible
            }
        );

        // Observe each video
        videoRefs.current.forEach((videoRef) => {
            if (videoRef) {
                observer.observe(videoRef);
            }
        });

        // Cleanup observer when component unmounts or postData changes
        return () => {
            observer.disconnect();
        };
    }, [postData]);

    const handleVideoRef = (index) => (ref) => {
        videoRefs.current[index] = ref;
    };

    return (
        <>
            <div className="reels-dashboard">
                <div className="topmenubar">
                    <SideMenuBar getPosts={getPosts}></SideMenuBar>

                    

                    <div className="top-bar-mobile">
                        <TopMenuBar />
                    </div>
                </div>

                <div className="main-container">
                    <div className="video-container">
                        {/* here we map over rhe videos array and imported videocard component */}
                        {postData?.map((post, index) => {
                            const attachmentKeys = post?.Attachment_keys || [];

                            // // Assuming post.Attachment_keys is an array of objects
                            // let  url = post.Attachment_keys?.Avatar_key || ''; // The URL you want to log

                            // // Console log to see the URL before passing it to VideoCard
                            // console.log('Attachment Key (URL):', url);

                            return attachmentKeys?.map((attachment) => {

                                // Parse the Avatar_key string into an array of URLs
                                const avatarUrls = parseAvatarKey(attachment.Avatar_key || '[]');

                                // Map over avatarUrls to generate a VideoCard for each URL
                                return avatarUrls.map((url) => (

                                    // Check if Avatar_key is an array and map over it to display multiple VideoCards
                                    // return Array.isArray(attachment.Avatar_key) ? (
                                    //     attachment.Avatar_key.map((url, idx) => (
                                 

                                
                               
                                    <VideoCard                               
                                        key={post.Post_ID}
                                        id={post.Post_ID}
                                        vendorID={post.Post_Vendor_id}
                                        AccountID={post.Post_Account_id}
                                        url={url}
                                        attachmentKeys={post.Attachment_keys || []}
                                        profilePic={post.Account_Avatar_key || ''}
                                        userName={post.Account_Display_name || ''}
                                        restaurantName={post?.Restaurant_Info?.[0]?.Vendor_Name || ''}
                                        starPic={post?.Restaurant_Info?.[0]?.Star_Image || ''}
                                        rating={post?.Restaurant_Info?.[0]?.Vendor_Rating || 'N/A'}
                                        distance={post.Distance_in_Miles}
                                        description={post.Post_Description}
                                        likes={post.Post_Likes_count}
                                        shares={post.Post_Shares_count}
                                        comments={post.Post_Comments_count}
                                        setLike={() => {}}
                                        handleMute={handleMute}
                                        ismuted={ismuted}
                                        globalMuteIcon={globalMuteIcon}
                                        toggleMuteIcon={toggleMuteIcon}
                                        setVideoRef={handleVideoRef(index)}
                                        autoplay={index === 0}
                                        restaurant={restaurant}
                                    />

                                ));

                                //     ))
                                // ) : null; // If Avatar_key is not an array, do nothing or handle the case
                                
                            });

                      

                    
                                
       
                        })}
                    </div>
                </div>

               

                <div className="right-side-bar">
                    <RightSideBar></RightSideBar>
                </div>
            </div>
        </>
    );
}

export default Reels;

