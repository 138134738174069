
import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InternetStatus = () => {
    useEffect(() => {
    // Variable to store the offline toast ID
        let offlineToastId = null;

        // Offline handler: Triggered when the internet goes offline
        const handleOffline = () => {
            // Display offline toast and save its ID
            offlineToastId = toast.error('You are offline. Check your internet connection!', {
                position: 'bottom-center',
                autoClose: false, // Do not auto-close the offline toast
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
        };

        // Online handler: Triggered when the internet comes back online
        const handleOnline = () => {
            // Dismiss the offline toast if it exists
            if (offlineToastId !== null) {
                toast.dismiss(offlineToastId);
                offlineToastId = null;
            }
            // Display online toast
            toast.success('Connection Established  Refresh the Page', {
                position: 'bottom-center',
                autoClose: 5000, // Auto-close after 5 seconds
                theme: 'dark',
            });
        };

        // Attach event listeners for online and offline
        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);

        // Cleanup: Remove event listeners when the component unmounts
        return () => {
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('online', handleOnline);
        };
    }, []); // Empty dependency array ensures this runs only once

    // Return ToastContainer to render toasts
    return (
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
    );
};

export default InternetStatus;