
import  keyCloak from 'keycloak-js';


const kcConfig = new keyCloak({
    url: 'https://keycloak.omyfood.io/auth',
    realm:'newrealm',
    clientId:'omf-web'
    // onLoad:'login-required'

  

   

});

console.log(kcConfig);


export default kcConfig;