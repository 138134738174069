import React, { useState } from 'react';
import './VerifyOTP.css'; 
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import Loader from '../../Components/Loader/Loader';

const VerifyOTP = ({isReset}) => {
    const registeredEmail = localStorage.getItem('signupEmail');
    const email = localStorage.getItem('forgetPasswordEmail');
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const base_URL = process.env.REACT_APP_API_BASE_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!code.trim()) {
            setError('Please enter your OTP');
            toast.error('Please enter your OTP');
            return; 
        }
    
        if (code.length < 6) {
            setError('OTP must be exactly 6 digits.');
            toast.error('OTP must be exactly 6 digits.');
            return; 
        }
        
        
        if (isReset === 'reset') {
            const data = {
                email:  email,
                otp: code
            };

            try {
                setIsLoading(true);
                const res = await axios.post(`${base_URL}/auth/verify-otp`, data);
                setIsLoading(false);
                toast.success(res?.data?.message);
                if(res?.statusText == 'OK'){
                    navigate('/reset-password');
                } else {
                    return;
                }
            } catch (error) {
                setIsLoading(false);
                toast.error(error?.response?.data?.error);
                console.error('Error from Reset password',error);
            }
        } else {
            const data = {
                email: registeredEmail,
                otp: code
            };

            try {
                setIsLoading(true);
                const res = await axios.post(`${base_URL}/auth/verify-otp`, data);
                setIsLoading(false);
                toast.success(res?.data?.message);
                if(res?.statusText == 'OK'){
                    localStorage.removeItem('signupEmail');
                    navigate('/login');
                } else {
                    return;
                }
            } catch (error) {
                setIsLoading(false);
                toast.error(error?.response?.data?.error);
                console.error('Error from Signup',error);
            }
            
        }
    };

    const handleChange = (e) => {
        const input = e.target.value;
        if (/^\d{0,6}$/.test(input)) {
            setCode(input);
            if (input.length < 6) {
                setError('OTP must be exactly 6 digits.');
            }else if(!input.trim()){
                setError('Please enter your OTP');
            } else {
                setError('');
            }
        }
    };

    return (
        <>
            <div className="verify-container">
                {isLoading && <Loader/>}
                <div className='back-drop'></div>
                <div className="verify-card">
                    <h2>Verify Account</h2>
                    <p>Sign up to food delivery right to your doorstep with just a few taps on your phone, saving you time and effort.</p>
                    <p>Code has been sent to <strong>{registeredEmail || email}</strong>. Enter the code to verify your account.</p>

                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder="6 Digit Code"
                            value={code}
                            onChange={handleChange}
                        />
                        {error && <span className="error-message">{error}</span>}
                        <button
                            type="submit" 
                            className="submit-button" 
                        >
                                    Submit
                        </button>
                    </form>
                    
                </div>
            </div>  
        </>
    );
};

export default VerifyOTP;
