import React, { useState } from 'react';
import './userRoleSelection.css';
import { useNavigate } from 'react-router-dom';
const UserRoleSelection = () => {
    const [selectedRole, setSelectedRole] = useState('vendor');
    const navigate = useNavigate();

    const handleRoleChange = (e) => {
        setSelectedRole(e.target.value);
    };

    const passRole = ()=>{
        navigate('/signup', {state: {role: selectedRole}});
    };


    return (
        <div className='login-page'>
            <div className='background'></div>
            <div className='login-container'>
                <h1 className='heading'>Tell us more about you!</h1>
                <div className='form-group radio-button'>
                    <label className='radio-label'>
                        <input
                            type='radio'
                            name='role'
                            value='delivery_partner'
                            checked={selectedRole === 'delivery_partner'}
                            onChange={handleRoleChange}
                            className='radio-input'
                        />
                        <span className='label-text'>Delivery partner</span>
                    </label>
                    <div className='line'></div>
                    <label className='radio-label'>
                        <input
                            type='radio'
                            name='role'
                            value='vendor'
                            checked={selectedRole === 'vendor'}
                            onChange={handleRoleChange}
                            className='radio-input'
                        />
                        <span className='label-text'>Restaurant Owner</span>
                    </label>
                    {/* <div className='line'></div>
                    <label className='radio-label'>
                        <input
                            type='radio'
                            name='role'
                            value='Guest / Food blogger'
                            checked={selectedRole === 'Guest / Food blogger'}
                            onChange={() => handleRoleChange('Guest / Food blogger')}
                            className='radio-input'
                        />
                        <span className='label-text'>Guest / Food blogger</span>
                    </label> */}
                </div>
                <button className='login-btn' onClick={passRole}>
                      Confirm
                </button>
            </div>
        </div>
    );
};

export default UserRoleSelection;
