import React from 'react';
import './../../../VendorStyles/VendorRegistrationBG.css';
// import { NavLink } from 'react-router-dom';

function KYCUpdateMessage() {
    return (
        <div className='kyc-update-message-main-container'>

            <div className='success-svg-and-msg'>
                <svg width="210" height="210" viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg" className='success-logo-kyc'>
                    <circle cx="105" cy="105" r="105" fill="#02A750" fillOpacity="0.09"/>
                    <path d="M105 17.5C56.7875 17.5 17.5 56.7875 17.5 105C17.5 153.213 56.7875 192.5 105 192.5C153.213 192.5 192.5 153.213 192.5 105C192.5 56.7875 153.213 17.5 105 17.5ZM146.825 84.875L97.2125 134.488C95.9875 135.713 94.325 136.413 92.575 136.413C90.825 136.413 89.1625 135.713 87.9375 134.488L63.175 109.725C60.6375 107.188 60.6375 102.987 63.175 100.45C65.7125 97.9125 69.9125 97.9125 72.45 100.45L92.575 120.575L137.55 75.6C140.088 73.0625 144.288 73.0625 146.825 75.6C149.363 78.1375 149.363 82.25 146.825 84.875Z" fill="#02A750"/>
                </svg>



                <div className='kyc-success-msg'>

                    <h3 className='kyc-submit-msg'>Your KYC Details are successfully </h3>
                    <h3 className='kyc-submit-msg-2'>submitted, Thank You! </h3>
                </div>


                <h4 className='kyc-verify-msg'>We will update the status once the KYC details are verified</h4>


                {/* <NavLink to='/Home'><button className='view-reels-btn-kyc' >Go to main page</button></NavLink> */}
                
            </div>

            

        </div>
    );
}

export default KYCUpdateMessage;
