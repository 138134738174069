import React, { useEffect, useRef, useState, memo } from 'react';
import ReelsLeftMemo from './ReelsLeft';
import ReelsRight from './ReelsRight';
import '../Styles/Reels/VideoCard.css';
import { BsHeartFill, BsPause } from 'react-icons/bs';
import axios from 'axios';

function VideoCard(props) {
    const {
        url,
        id,
        vendorID,
        AccountID,
        video,
        profilePic,
        userName,
        restaurantName,
        starPic,
        rating,
        distance,
        description,

        order,
        likes,
        comments,
        shares,

        setVideoRef,
        ismuted, // Receive ismuted as a prop
        handleMute, // Receive handleMute as a prop
        globalMuteIcon,
        toggleMuteIcon,
        restaurant,
    } = props;

    console.log(url);

    const base_URL = process.env.REACT_APP_API_BASE_URL;

    const vendor_Base_URL = process.env.REACT_APP_VENDOR_API_BASE_URL;

    const [like, setLike] = useState(false);

    const [isPaused, setIsPaused] = useState(true);
    const [isHeartActive, setIsHeartActive] = useState(false);

    const videoRef = useRef(null);

    const [likesCount, setLikesCount] = useState(likes);
    const [isLike, setIsLike] = useState(false);
    const [commentsCount, setCommentsCount] = useState(comments);
    const [commentsList, setCommentsList] = useState([]);

    const getLikes = async () => {
        const response = await axios.get(`${base_URL}/customers/likes/${id}`);

        // console.log(response.data.likeCount);
        setLikesCount(response.data.likeCount);

        const likedByUser = response.data.list_of_likes.some(
            (like) => like.Account_id === 3
        );
        setIsLike(likedByUser);

        localStorage.setItem('AccountID', AccountID);
    };

    useEffect(() => {
        getLikes();
    }, [id, base_URL, likesCount]);

    const getComments = async () => {
        await axios
            .get(`${vendor_Base_URL}/comments/post/${id}`)
            .then((response) => {
                setCommentsList(response.data.comments);
                setCommentsCount(response.data.commentCount);
            })
            .catch((error) => {
                console.error('There was an error fetching the comments!', error);
            });
    };

    // Fetch comments from the API
    useEffect(() => {
        getComments();
    }, [id, commentsCount]);

    // Helper function to detect if the file is a video
    const isVideoFile = (url) => {
        const videoExtensions = ['mp4', 'mov', 'webm', 'avi'];
        const extension = url.split('.').pop().toLowerCase();
        return videoExtensions.includes(extension);
    };

    const handleDoubleClick = () => {
        setLike((prevLike) => !prevLike);
        setIsHeartActive(true);
        setTimeout(() => {
            setIsHeartActive(false);
        }, 1000);
    };

    const handlePlayPause = () => {
        const video = videoRef.current;
        if (video) {
            setIsPaused(video.paused);
        }
    };

    useEffect(() => {
        const video = videoRef.current;

        const handleTimeUpdate = () => {
            // handle time updates if needed
        };

        if (video) {
            video.addEventListener('timeupdate', handleTimeUpdate);
            video.addEventListener('play', handlePlayPause);
            video.addEventListener('pause', handlePlayPause);

            return () => {
                video.removeEventListener('timeupdate', handleTimeUpdate);
                video.removeEventListener('play', handlePlayPause);
                video.removeEventListener('pause', handlePlayPause);
            };
        }

        localStorage.setItem('accountID', AccountID);
    }, []);

    const handlePause = () => {
        const video = videoRef.current;
        if (video && video.readyState >= HTMLMediaElement.HAVE_METADATA) {
            if (video.paused) {
                video.play();
                setIsPaused(false);
            } else {
                video.pause();
                setIsPaused(true);
            }
        }
    };

    return (
        <div className="video">
            {/* Check if the file is an image */}
            {!isVideoFile(url) ? (
                <>
                    <img
                        className="player"
                        src={url}
                        alt="feedimg"
                        onDoubleClick={handleDoubleClick}
                    />
                    <div className="heart-container">
                        <BsHeartFill
                            className={`heart ${isHeartActive ? 'heart-active' : ''}`}
                        />
                    </div>
                </>
            ) : (
                <>
                    {/* Render video if the file is a video */}
                    <video
                        className="player"
                        onClick={handlePause}
                        src={url}
                        ref={(ref) => {
                            videoRef.current = ref;
                            setVideoRef(ref);
                        }}
                        muted={ismuted}
                        loop
                        type="video/mp4"
                    ></video>

                    {/* Show pause overlay when the video is paused */}
                    {isPaused && (
                        <div className="pause-overlay" onClick={handlePause}>
                            <BsPause className="pause-icon" />
                        </div>
                    )}
                </>
            )}

            <div className="bottom-controls">
                <div className="reel-left">
                    <ReelsLeftMemo
                        profilePic={profilePic}
                        userName={userName}
                        restaurantName={restaurantName}
                        AccountID={AccountID}
                        starPic={starPic}
                        rating={rating}
                        distance={distance}
                        description={description}
                        restaurant={restaurant}
                        vendorID={vendorID}
                    />
                </div>

                <div className="reel-right">
                    <ReelsRight
                        id={id}
                        vendorID={vendorID}
                        restaurantName={restaurantName}
                        like={like}
                        setLike={setLike}
                        handleMute={handleMute}
                        shares={shares}
                        comments={comments}
                        isLike={isLike}
                        setIsLike={setIsLike}
                        getLikes={getLikes}
                        likesCount={likesCount}
                        commentsCount={commentsCount}
                        setCommentsCount={setCommentsCount}
                        commentsList={commentsList}
                        setCommentsList={setCommentsList}
                        getComments={getComments}
                        order={order}
                        video={video}
                        url={url}
                        isVideoFile={isVideoFile}
                        globalMuteIcon={globalMuteIcon}
                        toggleMuteIcon={toggleMuteIcon}
                        restaurant={restaurant}
                    />
                </div>
            </div>

            <div className="pause-overlay-all"></div>
        </div>
    );
}

export default memo(VideoCard);
