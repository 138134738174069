import React, { useRef, useState, useEffect } from 'react';
import img1 from '../../../assets/offersforyou1.png';

import star from '../../../assets/Star.png';
import './OffersSearchHome.css';
import '../Homepage/FollowToWatchReels.css';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export default function OffersForYou() {
    const base_URL = process.env.REACT_APP_API_BASE_URL;

    const { t } = useTranslation();
    const containerRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);

    const [offerData, setOfferData] = useState([]);
    console.log(offerData);

    // Dummy latitude and longitude values, replace these with actual location data
    const latitude = '17.4072147';
    const longitude = '78.492518';
    const pageNo = 1;
    const pageSize = 10;
    useEffect(() => {
        const getOffers = async () => {
            const response = await axios.get(
                `${base_URL}/search?searchText=&location=${latitude},${longitude}&pageNo=${pageNo}&pageSize=${pageSize}&type=offer`
            );
            setOfferData(response.data.data[0].Offer_Information);
        };
        getOffers();
        const handleScroll = () => {
            // Check if there's content to scroll to the left
            setShowLeftArrow(containerRef.current.scrollLeft > 0);
            // Check if there's content to scroll to the right
            setShowRightArrow(
                containerRef.current.scrollWidth >
          containerRef.current.clientWidth + containerRef.current.scrollLeft
            );
        };

        // Attach scroll event listener
        containerRef.current.addEventListener('scroll', handleScroll);

        // Call handleScroll once to set initial arrow visibility
        handleScroll();

    
    }, []);

    const handleScrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft -= 90;
        }
    };

    const handleScrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += 90;
        }
    };

    return (
        <div className="Offers-Main-container">
            <div className="title-and-arrows">
                <h5 className="offers-for-you-title-homepage">
                    {t('homescreen.offers-for-you-title')}
                </h5>
                <div className="arrows">
                    {showLeftArrow && (
                        <svg
                            width="100"
                            height="100"
                            viewBox="0 0 100 100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            alt="left arrow"
                            className="left-arrow1"
                            onClick={handleScrollLeft}
                        >
                            <g clipPath="url(#clip0_5807_2713)">
                                <rect width="100" height="100" fill="white" />
                                <circle
                                    cx="50"
                                    cy="50"
                                    r="50"
                                    fill="#E4EDF9"
                                    fillOpacity="0.5"
                                />
                                <path
                                    d="M60.9942 26L38 49.5L61 73"
                                    stroke="#464B59"
                                    strokeWidth="5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_5807_2713">
                                    <rect width="100" height="100" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    )}

                    {showRightArrow && (
                        <svg
                            width="100"
                            height="100"
                            viewBox="0 0 100 100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            alt="right arrow"
                            className="right-arrow1"
                            onClick={handleScrollRight}
                        >
                            <g clipPath="url(#clip0_5807_2715)">
                                <rect width="100" height="100" fill="white" />
                                <circle
                                    cx="50"
                                    cy="50"
                                    r="50"
                                    fill="#E4EDF9"
                                    fillOpacity="0.5"
                                />
                                <path
                                    d="M39.0058 73L62 49.5L39 26"
                                    stroke="#464B59"
                                    strokeWidth="5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_5807_2715">
                                    <rect width="100" height="100" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    )}
                </div>
            </div>
            <div className="restaurant-card-container" ref={containerRef}>
                {offerData?.map((restaurant, index) => (
                    <div key={index} className="restaurant-card-1">
                        <div className="Offers-image-container">
                            <img className="rest-image" src={img1} alt="restaurant img"></img>
                            <div className="offer-bg">
                                <p className="offer-per">{restaurant.Offer_percent}% OFF</p>
                            </div>
                        </div>
                        <h6 className="restaurant-name">
                            <p className="restaurant-text">{restaurant.Restaurant_name}</p>
                        </h6>
                        <div className="image-footer-review">
                            <img className="star-icon-image" src={star} alt="star icon"></img>
                            <span className="reviews">
                                {restaurant.Restaurant_ratings} reviews
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
