import React, { useState, useRef, useEffect } from 'react';
import '../../Styles/TopMenuBar.css';
import { useTranslation } from 'react-i18next';

import backIcon from './../../assets/icons8.png';

import qrCode from './../../assets/qrcode.png';
import { useNavigate } from 'react-router-dom';

import Notification from '../../Views/User/Notification/Notification';
import {
    LoadScript,
    StandaloneSearchBox,
    GoogleMap,
    Marker,
} from '@react-google-maps/api';

import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

function TopMenuBar({ tabName, searchQuery, setSearchQuery }) {
    const base_URL = process.env.REACT_APP_API_BASE_URL;

    const navigate = useNavigate();

    const { t } = useTranslation();

    const inputRef = useRef();

    const modalRef = useRef();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState(null);

    const [profileImagePreview, setProfileImagePreview] = useState();
    const token = localStorage.getItem('authToken'); 
    const decodedToken = jwtDecode(token);

    const updatingProfilePic = () => {
    // Fetch the user data from the API
        axios
            .get(`${base_URL}/customers/account/${decodedToken?.id}`)
            .then((response) => {
                const data = response.data.profileDetails[0];

                setProfileImagePreview(data.profile_picture);
            })
            .catch((error) => console.error('Error fetching user data:', error));
    };

    useEffect(() => {
        updatingProfilePic();
    }, []);

    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();

        setAddress(place.formatted_address);

        if (place) {
            const address = place.formatted_address;
            const location = place.geometry.location;
            setSelectedPlace({
                address,
                lat: location.lat(),
                lng: location.lng(),
            });
        }
    };

    const [NotificationOverlay, setNotificationOverlay] = useState(false);
    const overlayRef = useRef(null);

    const closenotificationoverlay = () => {
        setNotificationOverlay(false);
    };

    const [address, setAddress] = useState('');

    const getCoordinates = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const coords = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                setSelectedPlace({
                    // address: 'Current Location',
                    lat: coords.lat,
                    lng: coords.lng,
                });

                const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
                axios
                    .get(
                        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.lat},${coords.lng}&key=${apiKey}`
                    )
                    .then((response) => {
                        if (response.data.results.length > 0) {
                            const addressComponents =
                response.data.results[0].address_components;

                            // Extract different parts of the address
                            const houseNumber =
                addressComponents.find((component) =>
                    component.types.includes('street_number')
                )?.long_name || '';
                            const street =
                addressComponents.find((component) =>
                    component.types.includes('route')
                )?.long_name || '';
                            const city =
                addressComponents.find((component) =>
                    component.types.includes('locality')
                )?.long_name || '';
                            const village =
                addressComponents.find((component) =>
                    component.types.includes('sublocality')
                )?.long_name || '';
                            const state =
                addressComponents.find((component) =>
                    component.types.includes('administrative_area_level_1')
                )?.long_name || '';
                            const country =
                addressComponents.find((component) =>
                    component.types.includes('country')
                )?.long_name || '';
                            const postalCode =
                addressComponents.find((component) =>
                    component.types.includes('postal_code')
                )?.long_name || '';

                            // Create a formatted address string
                            const fullAddress = `${houseNumber} ${street}, ${
                                village || city
                            }, ${state} ${postalCode},${country}`;

                            // Set the address in the state
                            setAddress(fullAddress);
                        } else {
                            setAddress('Turn on location');
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching address:', error);
                        setAddress('Error fetching address');
                    });
            });
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    };

    useEffect(() => {
        getCoordinates();
    }, []);

    const [isListening, setIsListening] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    let recognition;

    useEffect(() => {
        if (tabName) {
            setIsClicked(true);
        }
    }, [tabName]);

    // Check for browser compatibility and set up SpeechRecognition
    const getSpeechRecognition = () => {
        const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            alert('Sorry, your browser does not support Speech Recognition.');
            return null;
        }
        return new SpeechRecognition();
    };

    const handleSearchClick = () => {
        setIsClicked(true);
        navigate('/search');
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        const prefix = tabName ? `${tabName} | ` : '';

        if (value.startsWith(prefix)) {
            setSearchQuery(value.slice(prefix.length));
        }
    };

    const handleMicClick = () => {
        recognition = getSpeechRecognition();
        if (!recognition) return;

        if (!isListening) {
            startVoiceRecognition();
            // playSound(micOn); // Play mic on sound
        } else {
            // playSoundOff(micOff); // Play mic off sound
            stopVoiceRecognition();
        }
    };

    const startVoiceRecognition = () => {
        if (!recognition) return;

        recognition.continuous = false;
        recognition.interimResults = false;
        recognition.lang = 'en-US';

        recognition.onstart = () => {
            setIsListening(true);
        };

        recognition.onresult = (event) => {
            const voiceQuery = event.results[0][0].transcript;
            setSearchQuery((prevQuery) => `${prevQuery}${voiceQuery}`);
            setIsListening(false);
        };

        recognition.onerror = (event) => {
            console.error('Voice recognition error:', event.error);
            setIsListening(false);
        };

        recognition.onend = () => {
            setIsListening(false);
        };

        recognition.start();
    };

    const stopVoiceRecognition = () => {
        if (recognition) {
            recognition.stop();
        }
        setIsListening(false);
    };

    const handleOpenModal = () => {
    // Check location permissions
        navigator.geolocation.getCurrentPosition(
            (position) => {
                console.log('User location allowed:', position);
                setIsModalOpen(true); // Open modal if location is allowed
            },
            (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                    setIsNotificationOpen(true); // Show notification popup
                    // Auto-close notification after 3 seconds
                    setTimeout(() => setIsNotificationOpen(false), 3000);
                }
            }
        );
    };

    const handleOutsideClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setIsModalOpen(false);
        }
    };

    return (
        <>
            <div className="topbar-container">
                {/* location container  */}

                <div className="location-container" onClick={handleOpenModal}>
                    <img className="back-icon" src={backIcon} alt="backIcon" />
                    <svg
                        width="25"
                        height="30"
                        viewBox="0 0 25 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="location-svg-icon"
                    >
                        <path
                            d="M3.23596 18.9151L9.96486 27.8783C10.476 28.5592 11.2904 28.9996 12.2077 28.9996C13.1249 28.9996 13.9393 28.5592 14.4504 27.8783L21.1793 18.9151C22.5837 17.0448 23.4158 14.7204 23.4158 12.2023C23.4158 6.01754 18.3981 1.00391 12.2082 1.00391C6.01831 1.00391 1 6.01754 1 12.2023C1 14.7204 1.83218 17.0448 3.23649 18.9151H3.23596Z"
                            stroke="#2C3142"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M15.0098 12.1979C15.0098 13.7438 13.7552 14.9974 12.208 14.9974C10.6608 14.9974 9.40625 13.7438 9.40625 12.1979C9.40625 10.652 10.6608 9.39844 12.208 9.39844C13.7552 9.39844 15.0098 10.652 15.0098 12.1979Z"
                            stroke="#2C3142"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>

                    <div className="turn-on-location-container">
                        <p className="turn-on-location" title={address}>
                            {/* {t('topmenubar.location-title')} */}
                            {address ? address : 'select Location'}
                        </p>
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        className="location-down-arrow"
                    >
                        <path d="M12 2a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8z" />
                        <path d="M12 12.586 8.707 9.293l-1.414 1.414L12 15.414l4.707-4.707-1.414-1.414L12 12.586z" />
                    </svg>
                </div>

                {isNotificationOpen && (
                    <div
                        style={{
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: '#ffffff',
                            padding: '20px',
                            borderRadius: '8px',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                            zIndex: 2000,
                            textAlign: 'center',
                        }}
                    >
                        <p style={{ color: '#d8000c', margin: 0 }}>
              Please allow location access to use.
                        </p>
                    </div>
                )}

                {isModalOpen && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1000,
                        }}
                        onClick={handleOutsideClick}
                    >
                        <div
                            ref={modalRef}
                            style={{
                                backgroundColor: 'white',
                                padding: '20px',
                                borderRadius: '8px',
                                width: '500px',
                                height: '450px',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <div className="title-and-cross-container">
                                <h1 className="select-location-heading">Select Location</h1>
                                <svg
                                    width="25"
                                    height="18"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="cross-icon-location"
                                    onClick={() => setIsModalOpen(false)}
                                >
                                    <path
                                        d="M14.7904 12.5002L24.5249 2.76519C25.1584 2.13197 25.1584 1.10814 24.5249 0.474916C23.8917 -0.158305 22.8679 -0.158305 22.2346 0.474916L12.4999 10.2099L2.76535 0.474916C2.13185 -0.158305 1.10834 -0.158305 0.475128 0.474916C-0.158376 1.10814 -0.158376 2.13197 0.475128 2.76519L10.2096 12.5002L0.475128 22.2352C-0.158376 22.8684 -0.158376 23.8922 0.475128 24.5255C0.790694 24.8413 1.20562 25 1.62024 25C2.03487 25 2.44949 24.8413 2.76535 24.5255L12.4999 14.7905L22.2346 24.5255C22.5505 24.8413 22.9651 25 23.3798 25C23.7944 25 24.209 24.8413 24.5249 24.5255C25.1584 23.8922 25.1584 22.8684 24.5249 22.2352L14.7904 12.5002Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>

                            <div
                                className="location-gps-container"
                                onClick={() => getCoordinates()}
                            >
                                <svg
                                    width="25"
                                    height="30"
                                    viewBox="0 0 25 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="location-svg-icon-location-modal"
                                >
                                    <path
                                        d="M3.23596 18.9151L9.96486 27.8783C10.476 28.5592 11.2904 28.9996 12.2077 28.9996C13.1249 28.9996 13.9393 28.5592 14.4504 27.8783L21.1793 18.9151C22.5837 17.0448 23.4158 14.7204 23.4158 12.2023C23.4158 6.01754 18.3981 1.00391 12.2082 1.00391C6.01831 1.00391 1 6.01754 1 12.2023C1 14.7204 1.83218 17.0448 3.23649 18.9151H3.23596Z"
                                        stroke="#2C3142"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M15.0098 12.1979C15.0098 13.7438 13.7552 14.9974 12.208 14.9974C10.6608 14.9974 9.40625 13.7438 9.40625 12.1979C9.40625 10.652 10.6608 9.39844 12.208 9.39844C13.7552 9.39844 15.0098 10.652 15.0098 12.1979Z"
                                        stroke="#2C3142"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>

                                <div className="get-location-text-container">
                                    <h4 className="location-text">Get Current Location</h4>
                                    <p className="gps-text">Using GPS</p>
                                </div>
                            </div>

                            <LoadScript
                                googleMapsApiKey="AIzaSyC59za_py8WPH2WdqAoiepkGsVPyrSCYuI"
                                libraries={['places']}
                            >
                                <StandaloneSearchBox
                                    onLoad={(ref) => (inputRef.current = ref)}
                                    onPlacesChanged={handlePlaceChanged}
                                >
                                    <input
                                        type="text"
                                        placeholder="Start typing your address"
                                        style={{
                                            boxSizing: 'border-box',
                                            border: '1px solid transparent',
                                            width: '300px',
                                            height: '50px',
                                            padding: '0 12px',
                                            borderRadius: '3px',
                                            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                            fontSize: '14px',
                                            outline: 'none',
                                            textOverflow: 'ellipses',
                                            marginTop: '30px',
                                        }}
                                    />
                                </StandaloneSearchBox>

                                {/* Show Map and Marker when an address is selected */}
                                {selectedPlace && (
                                    <div
                                        style={{
                                            marginTop: '20px',
                                            height: '200px',
                                            width: '100%',
                                        }}
                                    >
                                        <GoogleMap
                                            mapContainerStyle={{
                                                height: '100%',
                                                width: '100%',
                                            }}
                                            center={{
                                                lat: selectedPlace.lat,
                                                lng: selectedPlace.lng,
                                            }}
                                            zoom={15}
                                        >
                                            <Marker
                                                position={{
                                                    lat: selectedPlace.lat,
                                                    lng: selectedPlace.lng,
                                                }}
                                            />
                                        </GoogleMap>
                                    </div>
                                )}
                            </LoadScript>

                            {/* Close button */}
                        </div>
                    </div>
                )}

                {/* search container  */}

                <div className="search-container">
                    <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="search-icon"
                        // onClick={handleSearchClick}
                    >
                        <path
                            d="M21 21L16.2779 16.2695M18.8947 9.94737C18.8947 12.3204 17.9521 14.5962 16.2741 16.2741C14.5962 17.9521 12.3204 18.8947 9.94737 18.8947C7.57438 18.8947 5.29858 17.9521 3.62062 16.2741C1.94267 14.5962 1 12.3204 1 9.94737C1 7.57438 1.94267 5.29858 3.62062 3.62062C5.29858 1.94267 7.57438 1 9.94737 1C12.3204 1 14.5962 1.94267 16.2741 3.62062C17.9521 5.29858 18.8947 7.57438 18.8947 9.94737Z"
                            stroke="#464B59"
                            strokeWidth="2"
                            strokeLinecap="round"
                        />
                    </svg>

                    <input
                        type="text"
                        className="input-field"
                        value={
                            isClicked
                                ? `${tabName ? tabName : ''}${
                                    tabName ? ' | ' : ''
                                }${searchQuery}`
                                : ''
                        }
                        onChange={handleInputChange}
                        onClick={handleSearchClick}
                        placeholder={t('topmenubar.input-placeholder')}

                        // onChange={(e)=>setInputValue(e.target.value)}
                    />

                    <span className="verticle-line">|</span>

                    <div className={`mic-container ${isListening ? 'listening' : ''}`}>
                        {isListening && (
                            <div className="wave-container">
                                <div className="wave"></div>
                                <div className="wave"></div>
                                <div className="wave"></div>
                            </div>
                        )}

                        <svg
                            width="19"
                            height="30"
                            viewBox="0 0 19 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mic-icon"
                            style={{
                                cursor: 'pointer',
                                color: isListening ? 'red' : 'black',
                            }}
                            onClick={handleMicClick}
                        >
                            <path
                                d="M9.5 20.0938C6.29307 20.0903 3.69466 17.391 3.69071 14.0602V6.01492C3.70087 2.68237 6.31 -0.0105225 9.51863 3.09136e-05C12.7126 0.0105844 15.2997 2.69703 15.3099 6.01492V14.0608C15.3065 17.3915 12.7075 20.0909 9.50056 20.0944L9.5 20.0938ZM13.5029 6.01492C13.4944 3.71895 11.6959 1.86448 9.48476 1.87327C7.28602 1.88206 5.50502 3.73127 5.49712 6.01492V14.0608C5.50559 16.3567 7.30409 18.2112 9.51524 18.2024C11.714 18.1936 13.495 16.3444 13.5029 14.0608V6.01492ZM8.5968 23.757V28.1238H4.80278C4.30376 28.1238 3.89958 28.5436 3.89958 29.0619C3.89958 29.5802 4.30376 30 4.80278 30H14.1972C14.6962 30 15.1004 29.5802 15.1004 29.0619C15.1004 28.5436 14.6962 28.1238 14.1972 28.1238H10.4032V23.757C15.2709 23.2593 18.9859 19.0156 19 13.9353C19 13.417 18.5958 12.9972 18.0968 12.9972C17.5978 12.9972 17.1936 13.417 17.1936 13.9353C17.1936 18.3414 13.7422 21.926 9.5 21.926C5.25777 21.926 1.80641 18.3414 1.80641 13.9353C1.80641 13.417 1.40222 12.9972 0.903204 12.9972C0.404184 12.9972 0 13.417 0 13.9353C0.0141125 19.0156 3.7291 23.2593 8.5968 23.757Z"
                                fill="#464B59"
                            />
                        </svg>
                    </div>
                </div>

                {/* notification icon */}

                <div>
                    <img
                        className="qr-code"
                        src={qrCode}
                        alt="qrcode"
                        onClick={() => navigate('/QRCodeScanner')}
                    />

                    <svg
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="notification-icon"
                        onClick={() => setNotificationOverlay(!NotificationOverlay)}
                    >
                        <path
                            d="M15.8309 37.9978C12.7754 37.9978 10.2891 35.5123 10.2891 32.456C10.2891 32.0188 10.6438 31.6641 11.081 31.6641C11.5181 31.6641 11.8729 32.0188 11.8729 32.456C11.8729 34.638 13.6497 36.414 15.8309 36.414C18.0122 36.414 19.789 34.6372 19.789 32.456C19.789 32.0188 20.1438 31.6641 20.5809 31.6641C21.018 31.6641 21.3728 32.0188 21.3728 32.456C21.3728 35.5115 18.8872 37.9978 15.8309 37.9978Z"
                            fill="#2C3142"
                        />
                        <path
                            d="M29.2917 33.2528H2.37498C1.06577 33.2528 0 32.1871 0 30.8779C0 30.1824 0.302068 29.5256 0.831244 29.0729C0.863158 29.0462 0.89804 29.0209 0.932923 29.0001C3.35986 26.8931 4.74996 23.8561 4.74996 20.6498V15.8368C4.74996 10.6245 8.45048 6.063 13.55 4.99278C13.9708 4.90594 14.3968 5.1761 14.4874 5.60582C14.5779 6.03332 14.3041 6.45265 13.8744 6.5432C9.50438 7.45979 6.33304 11.3689 6.33304 15.8375V20.6506C6.33304 24.3585 4.7084 27.8691 1.87475 30.2789C1.851 30.2997 1.82725 30.3168 1.80202 30.3346C1.661 30.4815 1.58382 30.6767 1.58382 30.8793C1.58382 31.3165 1.93858 31.6713 2.37572 31.6713H29.2924C29.7296 31.6713 30.0843 31.3165 30.0843 30.8793C30.0843 30.6752 30.0049 30.4801 29.8661 30.3331C29.8424 30.3175 29.8186 30.2997 29.7964 30.2811C28.3209 29.038 27.1668 27.4816 26.3668 25.6544C26.1909 25.2536 26.3734 24.7868 26.7735 24.6109C27.1772 24.4379 27.6411 24.619 27.817 25.0176C28.5057 26.5866 29.4876 27.9262 30.7382 29.0009C30.7731 29.0217 30.8065 29.0454 30.8362 29.0721C31.3654 29.5263 31.6674 30.1839 31.6674 30.8786C31.6674 32.1878 30.6016 33.2536 29.2924 33.2536L29.2917 33.2528Z"
                            fill="#2C3142"
                        />
                        <path
                            d="M27.7059 20.5829C22.0312 20.5829 17.4141 15.9658 17.4141 10.2911C17.4141 4.61637 22.0312 0 27.7059 0C33.3806 0 37.9977 4.61711 37.9977 10.2918C37.9977 15.9666 33.3806 20.5837 27.7059 20.5837V20.5829Z"
                            fill="#E13535"
                        />
                        <path
                            d="M30.0809 15.0397H25.331C24.8938 15.0397 24.5391 14.685 24.5391 14.2478V13.0603C24.5391 11.0958 26.137 9.49786 28.1015 9.49786C28.7569 9.49786 29.289 8.96572 29.289 8.31037C29.289 7.65502 28.7569 7.12288 28.1015 7.12288H25.331C24.8938 7.12288 24.5391 6.76812 24.5391 6.33097C24.5391 5.89383 24.8938 5.53906 25.331 5.53906H28.1015C29.6297 5.53906 30.8721 6.78222 30.8721 8.30963C30.8721 9.83704 29.6289 11.0802 28.1015 11.0802C27.0105 11.0802 26.1221 11.9686 26.1221 13.0596V13.4552H30.0802C30.5173 13.4552 30.8721 13.8099 30.8721 14.2471C30.8721 14.6842 30.5173 15.039 30.0802 15.039L30.0809 15.0397Z"
                            fill="white"
                        />
                    </svg>
                    {NotificationOverlay && (
                        <>
                            <div className="arrow-to-point-notification"></div>
                            <div className="notification-overlay" ref={overlayRef}>
                                <Notification closeOverlay={closenotificationoverlay} />
                            </div>
                        </>
                    )}

                    <img
                        className="profile-icon"
                        src={profileImagePreview}
                        alt="profile"
                        onClick={() => navigate('/profilescreen')}
                    />
                </div>
            </div>
        </>
    );
}

export default TopMenuBar;
