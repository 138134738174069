import VendorSideMenuBar from '../Vendor/VendorLayouts/VendorSideMenuBar';
import VendorTopMenuBar from '../Vendor/VendorLayouts/VendorTopMenuBar';
import VendorSettingsMain from './VendorSettingsMain';
import '../../VendorStyles/Vendorsettings.css';

function Vendorsettings(){  
    return(
        <div className="Vendor-settings-main">
            <VendorSideMenuBar></VendorSideMenuBar>
            <VendorTopMenuBar></VendorTopMenuBar>
            <div className='vendorsettings-cont'>
                <VendorSettingsMain></VendorSettingsMain>
            </div>
        </div>
    );
}
export default Vendorsettings;