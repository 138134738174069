import React, { useEffect, useState } from 'react';
import MenuHeader from './MenuHeader';
import MenuItem from './MenuItem';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Loader from '../../../Components/Loader/Loader';

const ViewMenu = () => {
    const vendor_Base_URL = process.env.REACT_APP_VENDOR_API_BASE_URL;
    const [restaurantData,setRestaurantData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    // const [menuData,setMenuData] = useState();
    const {id} = useParams();

    useEffect(() => {
        fetchMenuTypes();
    }, []);

    const fetchMenuTypes = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${vendor_Base_URL}/vendors/vendor/${id}`);
            setIsLoading(false);
            const apiResponse = response.data;
            console.log('apiResponse',apiResponse);
            setRestaurantData(apiResponse);
        } catch (error) {
            setIsLoading(false);
            toast.error(error?.response?.data?.message);
            console.error('Error fetching menu types:', error);
        }
    };

    return (
        <>
            {isLoading && <Loader/>}
            <MenuHeader restaurantData={restaurantData}/>
            <MenuItem restaurantData={restaurantData}/>
        </>
    );
};

export default ViewMenu;
