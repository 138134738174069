import React from 'react';
import '../../VendorStyles/AddCouponForm.css';

function AddCouponForm({handleClose,title,setTitle,validFrom,setValidFrom,validTo,setValidTo,offerPercentage,setOfferPercentage,couponCode,setCouponCode,autoGenerateCode,handleAddOrUpdateCoupon,handleAutoGenerateCodeChange,isEditing}) {
    return (
        <div>
            <span className="close" onClick={handleClose}>&times;</span>
            <h2>{isEditing ? 'Edit Coupon' : 'Add Coupon'}</h2>
            <div className="coupon-form-group">
                <label>
                Title:
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </label>
            </div>
            <div className="coupon-form-group">
                <label>
                Validity Date From:
                    <input
                        type="date"
                        value={validFrom}
                        onChange={(e) => setValidFrom(e.target.value)}
                    />
                </label>
            </div>
            <div className="coupon-form-group">
                <label>
                Validity Date To:
                    <input
                        type="date"
                        value={validTo}
                        onChange={(e) => setValidTo(e.target.value)}
                    />
                </label>
            </div>
            <div className="coupon-form-group">
                <label>
                Offer Percentage:
                    <input
                        type="text"
                        value={offerPercentage}
                        onChange={(e) => setOfferPercentage(e.target.value)}
                    />
                </label>
            </div>
            <div className="coupon-form-group">
                <label>
                Coupon Code:
                    <input
                        type="text"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                    />
                </label>
            </div>
            <div className="generate-coupon-code">
                <label>
                Auto-generate Coupon Code:
                </label>
                <input
                    className='checkbox-auto-generate'
                    type="checkbox"
                    checked={autoGenerateCode}
                    onChange={handleAutoGenerateCodeChange}
                />
                
            </div>
            <button onClick={handleAddOrUpdateCoupon} className="add-button">
                {isEditing ? 'Update' : 'Add'}
            </button>
        </div>
    );
}

export default AddCouponForm;