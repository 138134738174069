
// import React from 'react';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
// import '../../../VendorStyles/vendorregistrationvalidation.css';

// function PersonalInformationForm({  formData, handleChange, handlePhoneChange, errors}) {
   


    
   
//     return (
//         <div className='personal-information-main-container'>
//             <div className='name-container-vendor'>
//                 <label className='label-text-required' htmlFor='businessName'>Business Name</label>
//                 <input
//                     className='full-name-input'
//                     placeholder='Enter your business name'
//                     id='businessName'
//                     name='businessName'
//                     value={formData.businessName}
//                     onChange={handleChange}
//                     maxLength={50} // Ensure max length of 50
//                     required
//                 />
//                 {errors.businessName && <p className='error-text'>{errors.businessName}</p>}
//             </div>

//             <div className='name-container-vendor'>
//                 <label className='label-text-required' htmlFor='address'>Address</label>
//                 <input
//                     className='full-name-input'
//                     type='text'
//                     id='address'
//                     name='address'
//                     placeholder='Enter Your Address'
//                     value={formData.address}
//                     onChange={handleChange}
//                     required
//                 />
//                 {errors.address && <p className='error-text'>{errors.address}</p>}
//             </div>

//             <div className='name-container-vendor'>
//                 <label className='label-text-required' htmlFor='email'>Email</label>
//                 <input
//                     className='full-name-input'
//                     type='email'
//                     id='email'
//                     name='email'
//                     placeholder='Enter Your Email'
//                     value={formData.email}
//                     onChange={handleChange}
//                     required
//                 />
//                 {errors.email && <p className='error-text'>{errors.email}</p>}
//             </div>

//             <div className='name-container-vendor'>
//                 <label className='label-text-required'>Contact Phone Number</label>
//                 <PhoneInput
//                     disableCountryCode={true}
//                     disableDropdown={true}
//                     country='us'
//                     onlyCountries={['us']}
//                     placeholder='Enter Your Phone Number'
//                     value={formData.phoneNumber}
//                     onChange={handlePhoneChange}
//                     inputStyle={{
//                         // border: `1.5px solid ${error ? '#E13535' : 'var(--Neutral-n200, #E2E8F0)'}`,
//                     }}
                     
//                 />
//                 {errors.phoneNumber && <p className='error-text'>{errors.phoneNumber}</p>}
//             </div>
//         </div>
//     );
// }

// export default PersonalInformationForm;



// import React from 'react';
// // import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
// import '../../../VendorStyles/vendorregistrationvalidation.css';
// // import axios from 'axios';

// function PersonalInformationForm({ formData, handleChange, errors }) {

//     // const APIKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
//     // const [loadingAddress, setLoadingAddress] = useState(false); // Loading state for fetching address
//     // const [addressError, setAddressError] = useState(''); // Address fetch error state

//     // const fetchAddress = () => {
//     //     if (!navigator.geolocation) {
//     //         setAddressError('Geolocation is not supported by your browser.');
//     //         return;
//     //     }

//     //     setLoadingAddress(true);
//     //     setAddressError('');

//     //     navigator.geolocation.getCurrentPosition(
//     //         async (position) => {
//     //             const { latitude, longitude } = position.coords;
//     //             try {
//     //                 // Replace with your Geocoding API key and endpoint
//     //                 const response = await axios.get(
//     //                     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${APIKey}`
//     //                 );

//     //                 const result = response.data.results[0];
//     //                 if (!result) {
//     //                     //setError('Address not found.');
//     //                     return;
//     //                 }
//     //                 const fetchedAddress =result.address_components;

//     //                 console.log(fetchedAddress);
//     //                 handleChange({ target: { name: 'address', value: fetchedAddress[0].long_name } });  //fetchedAddress[1].long_name }
//     //                 handleChange({ target: { name: 'city', value: fetchedAddress[4].long_name } }); 
//     //                 handleChange({ target: { name: 'state', value: fetchedAddress[5].long_name } });  
//     //                 handleChange({ target: { name: 'country', value: fetchedAddress[6].long_name } }); 
//     //                 handleChange({ target: { name: 'pincode', value: fetchedAddress[7].long_name } }); 
//     //             } catch (err) {
//     //                 setAddressError('Failed to fetch address. Please try again.');
//     //             } finally {
//     //                 setLoadingAddress(false);
//     //             }
//     //         },
//     //         () => {
//     //             setAddressError('Unable to retrieve your location.');
//     //             setLoadingAddress(false);
//     //         }
//     //     );
//     // };


//     // const fetchAddress = () => {
//     //     if (!navigator.geolocation) {
//     //         setAddressError('Geolocation is not supported by your browser.');
//     //         return;
//     //     }
    
//     //     setLoadingAddress(true);
//     //     setAddressError('');
    
//     //     navigator.geolocation.getCurrentPosition(
//     //         async (position) => {
//     //             const { latitude, longitude } = position.coords;
//     //             try {
//     //                 const response = await axios.get(
//     //                     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${APIKey}`
//     //                 );
    
//     //                 const result = response.data.results[0];
//     //                 if (!result) {
//     //                     setAddressError('Address not found.');
//     //                     return;
//     //                 }
    
//     //                 const fetchedAddress = result.address_components;
    
//     //                 // Extract address components
//     //                 const address = fetchedAddress.find((comp) =>
//     //                     comp.types.includes('route')
//     //                 )?.long_name || '';
//     //                 const city = fetchedAddress.find((comp) =>
//     //                     comp.types.includes('locality')
//     //                 )?.long_name || '';
//     //                 const state = fetchedAddress.find((comp) =>
//     //                     comp.types.includes('administrative_area_level_1')
//     //                 )?.long_name || '';
//     //                 const country = fetchedAddress.find((comp) =>
//     //                     comp.types.includes('country')
//     //                 )?.long_name || '';
//     //                 const pincode = fetchedAddress.find((comp) =>
//     //                     comp.types.includes('postal_code')
//     //                 )?.long_name || '';
    
//     //                 // Update form data
//     //                 handleChange({ target: { name: 'address', value: address } });
//     //                 handleChange({ target: { name: 'city', value: city } });
//     //                 handleChange({ target: { name: 'state', value: state } });
//     //                 handleChange({ target: { name: 'country', value: country } });
//     //                 handleChange({ target: { name: 'pincode', value: pincode } });
//     //             } catch (err) {
//     //                 console.error('Error fetching address:', err);
//     //                 setAddressError('Failed to fetch address. Please try again.');
//     //             } finally {
//     //                 setLoadingAddress(false);
//     //             }
//     //         },
//     //         () => {
//     //             setAddressError('Unable to retrieve your location.');
//     //             setLoadingAddress(false);
//     //         }
//     //     );
//     // };
    

//     return (
//         <div className='personal-information-main-container'>
//             <div className='name-container-vendor'>
//                 <label className='label-text-required' htmlFor='businessName'>Business Name</label>
//                 <input
//                     className='full-name-input'
//                     placeholder='Enter your business name'
//                     id='businessName'
//                     name='businessName'
//                     value={formData.businessName}
//                     onChange={handleChange}
//                     maxLength={50}
//                     required
//                 />
//                 {errors.businessName && <p className='error-text'>{errors.businessName}</p>}
//             </div>

//             <div className='name-container-vendor'>
//                 <label className='label-text-required' htmlFor='address'>Address</label>
//                 <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
//                     <input
//                         className='full-name-input'
//                         type='text'
//                         id='address'
//                         name='address'
//                         placeholder='Address ( House no ,Area and Street )'
//                         value={formData.address}
//                         onChange={handleChange}
//                         required
//                     />
//                     {/* <button
//                         type='button'
//                         onClick={fetchAddress}
//                         disabled={loadingAddress}
//                         className='fetch-address-button'
//                     >
//                         {loadingAddress ? 'Fetching...' : 'Fetch Address'}
//                     </button> */}
//                 </div>
//                 {errors.address && <p className='error-text'>{errors.address}</p>}
//                 {/* {addressError && <p className='error-text'>{addressError}</p>} */}
//             </div>

//             <div className='name-container-vendor'>
//                 <label className='label-text-required' htmlFor='city'>City</label>
//                 <input
//                     className='full-name-input'
//                     type='text'
//                     id='city'
//                     name='city'
//                     placeholder='Enter Your city'
//                     value={formData.city}
//                     onChange={handleChange}
//                     required
//                 />
//                 {errors.city && <p className='error-text'>{errors.city}</p>}
//             </div>
//             <div className='name-container-vendor'>
//                 <label className='label-text-required' htmlFor='state'>State</label>
//                 <input
//                     className='full-name-input'
//                     type='text'
//                     id='state'
//                     name='state'
//                     placeholder='Enter Your state'
//                     value={formData.state}
//                     onChange={handleChange}
//                     required
//                 />
//                 {errors.state && <p className='error-text'>{errors.state}</p>}
//             </div>

//             <div className='name-container-vendor'>
//                 <label className='label-text-required' htmlFor='country'>Country</label>
//                 <input
//                     className='full-name-input'
//                     type='text'
//                     id='country'
//                     name='country'
//                     placeholder='Enter Your Country'
//                     value={formData.country}
//                     onChange={handleChange}
//                     required
//                 />
//                 {errors.country && <p className='error-text'>{errors.country}</p>}
//             </div>

//             <div className='name-container-vendor'>
//                 <label className='label-text-required' htmlFor='pincode'>Pincode</label>
//                 <input
//                     className='full-name-input'
//                     type='text'
//                     id='pincode'
//                     name='pincode'
//                     placeholder='Enter Your pincode'
//                     value={formData.pincode}
//                     onChange={handleChange}
//                     maxLength={7}
//                     required
//                 />
//                 {errors.pincode && <p className='error-text'>{errors.pincode}</p>}
//             </div>

//             <div className='name-container-vendor'>
//                 <label className='label-text-required' htmlFor='email'>Email</label>
//                 <input
//                     className='full-name-input'
//                     type='email'
//                     id='email'
//                     name='email'
//                     placeholder='Enter Your Email'
//                     value={formData.email}
//                     onChange={handleChange}
//                     required
//                 />
//                 {errors.email && <p className='error-text'>{errors.email}</p>}
//             </div>

//             {/* <div className='name-container-vendor'>
//                 <label className='label-text-required'>Contact Phone Number</label>
//                 <PhoneInput
//                     disableCountryCode={true}
//                     disableDropdown={true}
//                     country='us'
//                     onlyCountries={['us']}
//                     placeholder='Enter Your Phone Number'
//                     value={formData.phoneNumber}
//                     onChange={handlePhoneChange}
//                     inputStyle={{}}
//                 />
//                 {errors.phoneNumber && <p className='error-text'>{errors.phoneNumber}</p>}
//             </div> */}
//         </div>
//     );
// }

// export default PersonalInformationForm;




import React from 'react';
// import axios from 'axios';
import 'react-phone-input-2/lib/style.css';
import '../../../VendorStyles/vendorregistrationvalidation.css';

function PersonalInformationForm({ formData, handleChange, errors }) {
    // const [loadingAddress, setLoadingAddress] = useState(false);

    // const fetchAddress = async () => {
    //     setLoadingAddress(true);
    //     const API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;// Replace with your actual API key
    //     const latitude = '37.7749'; // Replace with actual latitude
    //     const longitude = '-122.4194'; // Replace with actual longitude

    //     try {
    //         const response = await axios.get(
    //             `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`
    //         );

    //         if (response.data.status === 'OK') {
    //             const addressComponents = response.data.results[0].address_components;

    //             // Helper function to extract components by type
    //             const getAddressComponent = (type) => {
    //                 const component = addressComponents.find((comp) => comp.types.includes(type));
    //                 return component ? component.long_name : '';
    //             };

    //             const address = getAddressComponent('street_address') || getAddressComponent('route');
    //             const city = getAddressComponent('locality');
    //             const state = getAddressComponent('administrative_area_level_1');
    //             const country = getAddressComponent('country');
    //             const pincode = getAddressComponent('postal_code');

    //             // Update the form data
    //             handleChange({ target: { name: 'address', value: address } });
    //             handleChange({ target: { name: 'city', value: city } });
    //             handleChange({ target: { name: 'state', value: state } });
    //             handleChange({ target: { name: 'country', value: country } });
    //             handleChange({ target: { name: 'pincode', value: pincode } });
    //         } else {
    //             console.error('Error fetching address:', response.data.status);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching address:', error);
    //     } finally {
    //         setLoadingAddress(false);
    //     }
    // };

    return (
        <div className='personal-information-main-container'>
            <div className='name-container-vendor'>
                <label className='label-text-required' htmlFor='businessName'>Business Name</label>
                <input
                    className='full-name-input'
                    placeholder='Enter your business name'
                    id='businessName'
                    name='businessName'
                    value={formData.businessName}
                    onChange={handleChange}
                    maxLength={50}
                    required
                />
                {errors.businessName && <p className='error-text'>{errors.businessName}</p>}
            </div>

            <div className='name-container-vendor'>
                <label className='label-text-required' htmlFor='address'>Address</label>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <input
                        className='full-name-input'
                        type='text'
                        id='address'
                        name='address'
                        placeholder='Address (House no, Area and Street)'
                        value={formData.address}
                        onChange={handleChange}
                        required
                    />
                    {/* <button
                        type='button'
                        onClick={fetchAddress}
                        disabled={loadingAddress}
                        className='fetch-address-button'
                    >
                        {loadingAddress ? 'Fetching...' : 'Fetch Address'}
                    </button> */}
                </div>
                {errors.address && <p className='error-text'>{errors.address}</p>}
            </div>

            <div className='name-container-vendor'>
                <label className='label-text-required' htmlFor='city'>City</label>
                <input
                    className='full-name-input'
                    type='text'
                    id='city'
                    name='city'
                    placeholder='Enter Your city'
                    value={formData.city}
                    onChange={handleChange}
                    required
                />
                {errors.city && <p className='error-text'>{errors.city}</p>}
            </div>

            <div className='name-container-vendor'>
                <label className='label-text-required' htmlFor='state'>State</label>
                <input
                    className='full-name-input'
                    type='text'
                    id='state'
                    name='state'
                    placeholder='Enter Your state'
                    value={formData.state}
                    onChange={handleChange}
                    required
                />
                {errors.state && <p className='error-text'>{errors.state}</p>}
            </div>

            <div className='name-container-vendor'>
                <label className='label-text-required' htmlFor='country'>Country</label>
                <input
                    className='full-name-input'
                    type='text'
                    id='country'
                    name='country'
                    placeholder='Enter Your Country'
                    value={formData.country}
                    onChange={handleChange}
                    required
                />
                {errors.country && <p className='error-text'>{errors.country}</p>}
            </div>

            <div className='name-container-vendor'>
                <label className='label-text-required' htmlFor='pincode'>Pincode</label>
                <input
                    className='full-name-input'
                    type='text'
                    id='pincode'
                    name='pincode'
                    placeholder='Enter Your pincode'
                    value={formData.pincode}
                    onChange={handleChange}
                    maxLength={6}
                    required
                />
                {errors.pincode && <p className='error-text'>{errors.pincode}</p>}
            </div>

            <div className='name-container-vendor'>
                <label className='label-text-required' htmlFor='email'>Email</label>
                <input
                    className='full-name-input'
                    type='email'
                    id='email'
                    name='email'
                    placeholder='Enter Your Email'
                    value={formData.email}
                    onChange={handleChange}
                    readOnly
                    required
                />
                {errors.email && <p className='error-text'>{errors.email}</p>}
            </div>
        </div>
    );
}

export default PersonalInformationForm;



