import React, { Suspense} from 'react';
import './App.css';
import MainNavigation from './MainNavigation';


import KeycloakTokenManager from './KeycloakTokenManager';

import InternetStatus from './Components/InternetStatus';

function App() {




    



   

    
    
    return (
   

        <div className="App">
            <InternetStatus/>
            <Suspense
                fallback={
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                }
            >
                <KeycloakTokenManager />
                <MainNavigation></MainNavigation>
            </Suspense>
        </div>
    );
}

export default App;
