import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import store from './Redux/Store';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 
import {ReactKeycloakProvider} from '@react-keycloak/web';
import kcConfig from './keyCloakAuth';
import { CartProvider } from './CartProvider';


const eventLogger = (event, error) => {
    console.log('onKeycloakEvent', event, error);
};
  
const tokenLogger = (tokens) => {
    console.log('onKeycloakTokens', tokens);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(




    <>
        {/* <React.StrictMode> */}

        <ReactKeycloakProvider
            authClient={kcConfig}
            // initOptions={{onLoad:'login-required',checkLoginIframe:false}}
            onEvent={eventLogger}
            onTokens={tokenLogger}
    
        > 
  
            <Provider store={store}>
            
                <CartProvider>
                    <App />
                </CartProvider>
                <ToastContainer />
           
 
            </Provider>
   
       
        </ReactKeycloakProvider>  
        {/* </React.StrictMode>  */}

    </> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
