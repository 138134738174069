import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../VendorStyles/Coupons.css';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CouponModal from './CouponModal';
 
function Coupons({ onAddCoupon, onUpdateCoupon, onDeleteCoupon }) {

    const vendor_Base_URL = process.env.REACT_APP_VENDOR_API_BASE_URL;


    const [coupons, setCoupons] = useState([]);
    console.log(coupons);
    const [couponID, setCouponID] = useState('');
    const [title, setTitle] = useState('');
    const [validFrom, setValidFrom] = useState('');
    const [validTo, setValidTo] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    // const [showModal, setShowModal] = useState(false);
    const [offerPercentage, setOfferPercentage] = useState('');
    const [autoGenerateCode, setAutoGenerateCode] = useState(false);

    const [couponModal, setCouponModal] = useState(false);
    const handleOpenCouponModal = () => setCouponModal(true);
    const handleCloseCouponModal = () => setCouponModal(false);


    const notifyDelete = () => toast.success('Coupon deleted successfully');
    const notifyAdd = () => toast.success('Coupon added successfully');
    const notifyUpdate = () => toast.success('Coupon updated successfully');
    const notifyFillDetails = () => toast.error('Please fill in all fields');
    useEffect(() => {
        axios.get(`${vendor_Base_URL}/vendor/coupon/vendor/1`)
            .then(response => setCoupons(response.data))
            .catch(error => console.error('Error fetching coupons:', error));
    }, []);

    const handleAddOrUpdateCoupon = () => {
        if (title && validFrom && validTo && (couponCode || autoGenerateCode)) {
            const newCouponCode = autoGenerateCode ? couponCode : couponCode;
            const newCoupon = { 
                Offer_percent: offerPercentage, 
                Coupon_code: newCouponCode, 
                Title: title, 
                Valid_from: validFrom, 
                Valid_through: validTo, 
                Description:'' // Example description, adjust as needed
            };

            if (isEditing) {
                const url = `${vendor_Base_URL}/vendor/coupons/${couponID}`;
                axios.put(url, newCoupon)
                    .then(response => {
                        onUpdateCoupon(response.data, editIndex);
                        setIsEditing(false);
                        setEditIndex(null);
                        setCoupons(prevCoupons => {
                            const updatedCoupons = [...prevCoupons];
                            updatedCoupons[editIndex] = response.data;
                            return updatedCoupons;
                        });
                        resetForm();
                        // setShowModal(false);
                        setCouponModal(false);
                        notifyUpdate();
                    })
                    .catch(error => {
                        console.error('Error updating coupon:', error);
                        toast.error('Error updating coupon');
                    });
            } else {
                const url = `${vendor_Base_URL}/vendor/coupon/vendor/1`;
                axios.post(url, newCoupon)
                    .then(response => {
                        onAddCoupon(response.data);
                        setCoupons(prevCoupons => [...prevCoupons, response.data]);
                        resetForm();
                        // setShowModal(false);
                        setCouponModal(false);
                        notifyAdd();
                    })
                    .catch(error => {
                        console.error('Error adding coupon:', error);
                        toast.error('Error adding coupon');
                    });
            }
        } else {
            notifyFillDetails();
        }
    };

    const handleEditCoupon = (index) => {
        const coupon = coupons[index];
        setCouponID(coupon.ID);
        setTitle(coupon.Title);
        setValidFrom(coupon.Valid_from.split('T')[0]);
        setValidTo(coupon.Valid_through.split('T')[0]);
        setCouponCode(coupon.Coupon_code);
        setOfferPercentage(coupon.Offer_percent);
        setIsEditing(true);
        setEditIndex(index);
        // setShowModal(true);
        setCouponModal(true);
    };

    const handleDeleteCoupon = (id) => {
        console.log(id);
        // const coupon = coupons[index];
        const url = `${vendor_Base_URL}/vendor/coupons/${id}`;
        axios.delete(url)
            .then(() => {
                onDeleteCoupon(id);
                setCoupons(prevCoupons => prevCoupons.filter((_, i) => i !== id));
                notifyDelete();
            })
            .catch(error => {
                console.error('Error deleting coupon:', error);
                toast.error('Error deleting coupon');
            });

    };

    
    const resetForm = () => {
        setCouponID('');
        setOfferPercentage('');
        setTitle('');
        setValidFrom('');
        setValidTo('');
        setCouponCode('');
        setAutoGenerateCode(false);
    };

    const isExpired = (validTo) => {
        if (!validTo) return false;
        const currentDate = new Date().toISOString().split('T')[0];
        return validTo < currentDate;
    };

    const generateCouponCode = () => {
        return Math.random().toString(36).substring(2, 10).toUpperCase();
    };

    const handleAutoGenerateCodeChange = (e) => {
        const checked = e.target.checked;
        setAutoGenerateCode(checked);
        if (checked) {
            const generatedCode = generateCouponCode();
            setCouponCode(generatedCode);
        } else {
            setCouponCode('');
        }
    };

    return (
        <div className="coupon-container">
            <h2>Coupons</h2>
            <div className="table-container">
                <table className="coupon-table">
                    <thead>
                        <tr>
                            <th>Coupon ID</th>
                            <th>Title</th>
                            <th>Valid From</th>
                            <th>Valid To</th>
                            <th>Status</th>
                            <th>Coupon Code</th>
                            <th>Offer</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {coupons.map((coupon,index) => (
                            <tr key={index} className={isExpired(coupon.Valid_through ? coupon.Valid_through.split('T')[0] : '') ? 'expired' : ''}>
                                <td>{coupon.ID}</td>
                                <td>{coupon.Title}</td>
                                <td>{coupon.Valid_from ? coupon.Valid_from.split('T')[0] : 'N/A'}</td>
                                <td>{coupon.Valid_through ? coupon.Valid_through.split('T')[0] : 'N/A'}</td>
                                <td>{isExpired(coupon.Valid_through ? coupon.Valid_through.split('T')[0] : '') ? 'Expired' : 'Active'}</td>
                                <td>{coupon.Coupon_code}</td>
                                <td>{coupon.Offer_percent}</td>
                                <td>
                                    <button onClick={() => handleEditCoupon(index)} className="coupon-edit-button">Edit</button>
                                    <button onClick={() => handleDeleteCoupon(coupon.ID)} className="coupon-delete-button">Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <button onClick={handleOpenCouponModal} className="add-button">Add Coupon</button>

           


            {couponModal && (
                <CouponModal 
                    open={couponModal}
                    handleClose={handleCloseCouponModal}
                    handleAddOrUpdateCoupon={handleAddOrUpdateCoupon}
                    handleAutoGenerateCodeChange={handleAutoGenerateCodeChange}
                    title={title}
                    setTitle={setTitle}
                    validFrom={validFrom}
                    setValidFrom={setValidFrom}
                    validTo={validTo}
                    setValidTo={setValidTo}
                    offerPercentage={offerPercentage}
                    setOfferPercentage={setOfferPercentage}
                    couponCode={couponCode}
                    setCouponCode={setCouponCode}
                    autoGenerateCode={autoGenerateCode}
                    isEditing={isEditing}
                />
            )}
            
        </div>
    );
}

export default Coupons;

