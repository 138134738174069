import React, { useRef, useState, useEffect } from 'react';
import './FollowToWatchReels.css';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

function FollowToWatchReels() {
    const base_URL = process.env.REACT_APP_API_BASE_URL;
    const { t } = useTranslation();
    const containerRef = useRef(null);

    const [followers, setFollowers] = useState([]);
    const [followingStatus, setFollowingStatus] = useState({});

    useEffect(() => {
    // Fetch the list of profiles and follow status from the backend
        const fetchFollowToWatch = async () => {
            try {
                const response = await axios.get(`${base_URL}/follow/watch-reels`);
                setFollowers(response.data.followInfo.result[0].Follow_Information);

                // Fetch the follow status for each profile
                const followStatus = {};
                for (const profile of response.data.followInfo.result[0]
                    .Follow_Information) {
                    const followResponse = await axios.get(
                        `${base_URL}/follow/account/check/${profile.Account_id}?Follow_by_id=6`
                    );
                    followStatus[profile.Account_id] = followResponse.data.isFollowing;
                }
                setFollowingStatus(followStatus);
            } catch (error) {
                console.error('Error fetching follow data', error);
            }
        };

        fetchFollowToWatch();
    }, [base_URL]);

    const handleFollow = async (accountID) => {
        try {
            if (!followingStatus[accountID]) {
                // Follow logic
                await axios.post(`${base_URL}/follow/account/${accountID}`, {
                    Follow_by_id: 6,
                    Created_by: Date.now(),
                });
                setFollowingStatus((prevStatus) => ({
                    ...prevStatus,
                    [accountID]: true,
                }));
            } else {
                // Unfollow logic
                await axios.delete(`${base_URL}/follow/account/${accountID}/6`);
                setFollowingStatus((prevStatus) => ({
                    ...prevStatus,
                    [accountID]: false,
                }));
            }
        } catch (error) {
            console.error('Error in follow/unfollow', error);
        }
    };

    return (
        <div className="Follow_To_Watch_Reels_Container">
            <div className="title-and-arrows">
                <h5 className="follow_To_Watch_Reels">
                    {t('homescreen.Follow-to-watch-reels-title')}
                </h5>
            </div>

            <div className="person-cards-container" ref={containerRef}>
                {followers.map((profile) => (
                    <div key={profile.AccountID} className="person-card">
                        <img
                            className="person_image"
                            src={profile.Avatar_key}
                            alt="person img"
                        />
                        <h6 className="channel-name">{profile.Display_name}</h6>
                        <p className="followers-count">{profile.Follower_count}</p>
                        <div className="botton-text-container">
                            <button
                                className="Follow_Button"
                                onClick={() => handleFollow(profile.Account_id)}
                            >
                                {followingStatus[profile.Account_id] ? 'Following' : 'Follow'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FollowToWatchReels;
